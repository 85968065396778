import React, { useState } from "react";


import { MdArrowDropDown } from "@react-icons/all-files/md/MdArrowDropDown";
import { MdArrowDropUp } from "@react-icons/all-files/md/MdArrowDropUp";

interface Props {
  etext: string;
  maxnooverlfow: number;
}


export const CitmTextExpandoBS: React.FC<Props> = (props) => {


  const [locExpand, setLocExpand] = useState(false);
  
  function doExpand() {
    
    setLocExpand(!locExpand)
    
  }


  return (

    <>
      { props.etext.length > props.maxnooverlfow ?        
        <>
          { locExpand ?
            <>
              <div className="">{props.etext}</div>
              <div className="vr ms-auto" />
              <MdArrowDropUp onClick={doExpand  } className="ad-inline-icon col-1" />
            </>
          :
            <>
              <div className="text-truncate">{props.etext}</div>
              <div className="vr ms-auto" />
              <MdArrowDropDown onClick={doExpand } className="ad-inline-icon col-1" />
            </>
          }
        </>
      :
        <div className="">{props.etext}</div>
      }

    </>

  );

};
