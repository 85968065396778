import React, { useState } from "react";


import { MdArrowDropDown } from "@react-icons/all-files/md/MdArrowDropDown";
import { MdArrowDropUp } from "@react-icons/all-files/md/MdArrowDropUp";

interface Props {
  etext: string;
  showarrow: boolean;
  arrowactive: boolean;
  doaction: any;
}


export const AccountExpando: React.FC<Props> = (props) => {


  
  
  function doExpand(e:any) {
    
    

    props.doaction(e)
    
  }


  return (

    <>
      { props.showarrow ?        
        <div className="xtext-nowrap d-flex flex-nowrap ad-accountexpando align-items-center"
              onClick={(e)=>doExpand(e) }>
          {}
          { props.arrowactive ?
            <>
              <div className="ms-2 me-2">{props.etext}</div>
              <div className="vr ms-auto" />
              <MdArrowDropUp onClick={(e)=>doExpand(e) } className="ad-accountexpando-icon xcol-1" />
            </>
          :
            <>
              <div className="ms-2 me-2 xtext-truncate">{props.etext}</div>
              <div className="vr ms-auto" />
              <MdArrowDropDown onClick={(e)=>doExpand(e) } className="ad-accountexpando-icon xcol-1" />
            </>
          }
        </div>
      :
        <div className="">{props.etext}</div>
      }

    </>

  );

};
