import React from "react";

import { BaseItm, StDt, AnStatus, EpochDetail, NftPricelist} from './CSInterface';

import { BigNumber, ethers } from 'ethers';


import {Button, Container, Row, Col, Spinner, Badge} from 'react-bootstrap';
import { debug } from "console";
import { CitmTextExpandoBS } from "./CitmTextExpandoBS";
import { fFactor, fToken, truncateEthAddress } from "./CSCommon";












interface Props {
  itm: BaseItm;
  
  nfth: any;

  gqlactive: boolean;

  filtershowattacks: boolean;
  

  totalitems: number;
  lookat: any;

  bstat: any;

  epoch: EpochDetail;

  
  

  
}


export const CitmBaseListBSql: React.FC<Props> = (props) => {

  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  

  

  
 
  

  
    
  
  
  
    

  
  
        
  
          
  
  

  
          
  
  
  
  

  

  

  
  
  

  
  
  

  

      
  

  
  

  
  function cApr (calc: string) {
    

    
    
    
    

    
    

    
    

    
    
    if(props.epoch.nftpricecst
        && props.epoch.nftbasepricecst 
        && props.epoch.epochsecs
        && props.epoch.secsleft) {
    
      try {
        

        
        

        
        

        
        
        const cost = props.itm.stacklength.mul(props.epoch.nftpricecst)
        
        if(cost.gt(0)) {
        
          let income = BigNumber.from(0)

          
          
          if(calc == "totbaseapr") {

            
            let totepochs = props.epoch.epochidx ? props.epoch.epochidx.sub(props.itm.mintepidx) : BigNumber.from(0)
            if(totepochs.eq(0)) {
              totepochs = BigNumber.from(1)
            }

            
            const tempf = 10000;

            
            const totsecs = props.epoch.epochsecs.mul(totepochs)

            
            income = props.itm.base_stackflation_total.add(props.itm.base_stackposalltimereward)

            income = income.mul(tempf).div(totsecs).mul(365*24*60*60)

            const p1 = income.div(cost)
            
            
            const p2 = p1.mul(100);
            const p3 = p2.toNumber() / tempf;
            const p4 = p3.toFixed(2).toString();

            

            

            return p4;

          
          
          } else if(calc == "sfbaseapr") {

            
            const tempf = 10000;

            
            const totsecs = props.epoch.epochsecs

            
            income = props.itm.base_stackflation_lastepoch

            income = income.mul(tempf).div(totsecs).mul(365*24*60*60)

            const p1 = income.div(cost)
            
            
            const p2 = p1.mul(100);
            const p3 = p2.toNumber() / tempf;
            const p4 = p3.toFixed(2).toString();

            

            return p4;


          
          
          } else if(calc == "mintroi") {

            
            let totepochs = props.epoch.epochidx ? props.epoch.epochidx.sub(props.itm.mintepidx) : BigNumber.from(0)
            if(totepochs.eq(0)) {
              totepochs = BigNumber.from(1)
            }

            
            const tempf = 10000;

            
            const totsecs = props.epoch.epochsecs.mul(totepochs)

            
            income = props.itm.base_stackposalltimereward

            income = income.mul(tempf).div(totsecs).mul(365*24*60*60)

            const p1 = income.div(cost)
            
            
            const p2 = p1.mul(100);
            const p3 = p2.toNumber() / tempf;
            const p4 = p3.toFixed(2).toString();

            

            return p4;

          }


        } else {
          
        }

      } catch (e) {
        
      }

      

      
    }

    return "na";
  }





  
  
  
  
  
  
  
  
  
  



  
  
  
  
  

  
  
  
  

  


  

  
  
  

  
  
  
  
  
  
  
  
  
  
  

      
      
      
      
      
      
      
      
      

  
  
  return (
    
      <Col className="my-2 mx-1 p-1 col-10 col-md-5 ad-baseitm xd-flex" key={props.itm.ikey}>

        {}

        <Row className="mt-2 mx-1">

          
          {}

          <Col className="xcol-11 flex-nowrap xmx-2 py-1 pe-0 ad-header-grey d-flex">
            <CitmTextExpandoBS etext={props.itm.desc ? props.itm.desc : ""} maxnooverlfow={25} />
          </Col>
          
          

        </Row>

        {}

        <Row className="mt-1 mb-2 mx-1 xmb-auto">

          <Col className="xd-flex">

            <Row>


              { 1 == 1 &&
                <>

                  {}
                  { props.filtershowattacks && (props.gqlactive || process.env.REACT_APP_BASETESTDATA) ? 

                    <>
                      <Row >
                        <Col className="col-7">stackid:</Col><Col className="xcol-4">{props.itm.nftid}</Col>
                      </Row>
                      <Row >
                        <Col className="col-7">questions:</Col><Col className="xcol-4">{fFactor(props.itm.stacklength)}</Col>
                      </Row>

                      <Row >
                        <Col className="col-7">stack APR:</Col><Col className="xcol-4">{cApr("totbaseapr")}</Col>
                      </Row>

                      {}

                      <div className="ad-attacklist-base">
                      
                        <Row >
                          {}
                          {}
                          <Col className="col-7">attacks p/m:</Col><Col className="xcol-4">{fToken(props.itm.base_attackplus,1)} / {fToken(props.itm.base_attackmin,1)}</Col>
                        </Row>

                        {props.itm.attacks.map((itm,iidx) => 
                          <Row className="mx-2" key={itm.ikey}>
                            {}

                            <Col className="col-8">{truncateEthAddress(itm.attackadr)}</Col>
                            
                            {}

                            <Col><Badge bg={itm.ismin ? "danger" : "success"} className={itm.ismin ? "ad-attack-ismin text-end" : "ad-attack-isplus text-end"}>{fToken(itm.attackval,1)} </Badge></Col>

                          </Row>
                        )}

                      </div>

                    </> 

                  :   
                  
                    <>
                      <Row >
                        <Col className="col-7">stackid:</Col><Col className="xcol-4">{props.itm.nftid}</Col>
                      </Row>
                      <Row >
                        <Col className="col-7">questions:</Col><Col className="xcol-4">{fFactor(props.itm.stacklength)}</Col>
                      </Row>

                      
                      <Row >
                        <Col className="col-7">stack APR:</Col><Col className="xcol-4">{cApr("totbaseapr")}</Col>
                      </Row>
                      
                      <Row >
                        <Col className="col-7">attacks p/m:</Col><Col className="xcol-4">{fToken(props.itm.base_attackplus,1)} / {fToken(props.itm.base_attackmin,1)}</Col>
                      </Row>
                      

                    </>

                  }
                   
                  
                </>
              }

              {props.lookat.devinfo &&

                <Row>

                  <Col className="ad-devinfo">

                    <Row >
                      <Col className="col-7">baseid:</Col><Col className="xcol-4">{props.itm.nftid}</Col>
                    </Row>
                    
                    <Row >
                      <Col className="col-7">base ep sf:</Col><Col className="xcol-4">{fToken(props.itm.base_stackflation_lastepoch,2)}</Col>
                    </Row>
                    <Row >
                      <Col className="col-7">base tot sf:</Col><Col className="xcol-4">{fToken(props.itm.base_stackflation_total,2)}</Col>
                    </Row>
                    
                    <Row >
                      <Col className="col-7">sf base APR:</Col><Col className="xcol-4">{cApr("sfbaseapr")}</Col>
                    </Row>

                    <Row >
                      <Col className="col-7">base tot mint:</Col><Col className="xcol-4">{fToken(props.itm.base_stackposalltimereward,2)}</Col>
                    </Row>
                    <Row >
                      <Col className="col-7">mint base APR:</Col><Col className="xcol-4">{cApr("mintroi")}</Col>
                    </Row>

                  </Col>

                </Row>

              }

            </Row>

          </Col>

        </Row>

        <div className="flex-grow-1"></div>

        <Row className="xmt-auto justify-content-center">
          <Col xs={10} className="d-grid align-items-stretch" >
            <Button variant="primary" size="sm" onClick={(e)=>props.nfth(e,props.itm.nftid,props.itm.desc,"viewstack")}>Select Stack</Button>
          </Col>
        </Row>

        {}

      </Col>

  );

};
