
import React from "react";

import { ethers } from 'ethers';

import { AccBal, PointsDetail} from './CSInterface';

import {Button, Container, Row, Col, ProgressBar, Spinner, Navbar, Nav} from 'react-bootstrap';


import { FaArrowLeft } from "@react-icons/all-files/fa/FaArrowLeft";
import { FaBuffer } from "@react-icons/all-files/fa/FaBuffer";
import { MdPlaylistAdd } from "@react-icons/all-files/md/MdPlaylistAdd";
import { MdAddBox } from "@react-icons/all-files/md/MdAddBox";

import { MdLiveHelp } from "@react-icons/all-files/md/MdLiveHelp";

import { MdRefresh, MdAccountBalanceWallet, MdAccountBox } from 'react-icons/md'



import { CitmTextExpandoBS } from "./CitmTextExpandoBS";
import { AccountExpando } from "./AccountExpando";


interface Props {
  abal?: AccBal;
  
  points: PointsDetail;
  errordisp?: string;
  accounth: any;
  lookat: any;
  bstat: any;
  handlenav: any;
  handletabnav: any;
  arrowactive: boolean;
}


export const AppBar: React.FC<Props> = (props) => {

  function calcStyle() {

    
    let perc = 0;
    if(props.points.hundredgauge != undefined) {
      perc = props.points.hundredgauge;
    }


    
    
    if (perc > 100 ) {perc = 100;};

    
    return perc;

  }






















  function helpIsRegistered(){

    if( (props.abal && props.abal.nftbncount && ethers.BigNumber.from(props.abal.nftbncount).gt(0) )
        || props.abal && props.abal.cnftcnt && props.abal.cnftcnt.gt(0) ) {
          return true
    } else {
      return false
    }
  }


  function createAccountBut(mev: React.MouseEventHandler<HTMLButtonElement>, btext: string) {

    
    if(props.bstat.points) {

      return <Button
              variant="info" size="sm"
              
              onClick={mev}
              disabled
             >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Loading...</span>
                {btext}
            </Button>

    
    } else {

      return <Button
                variant="info" size="sm"
                
                onClick={mev}
              >
              {btext}
            </Button>
    }

  }


  return (
    <Navbar collapseOnSelect expand={true} bg="adappbar" variant="dark" sticky="top" onSelect={props.handletabnav}>
          
          
          <Container className="d-flex">

            <div className="d-flex flex-grow-1 col-6 align-items-center xg-0">

              {props.lookat.view != "baselist" &&
                  
                  
                  

                  <div className="xcol-1">
                    <Button variant="secondary" size="sm" onClick={(e)=>props.handlenav(e,"base")}><FaArrowLeft/></Button>
                  </div>
              }

              {props.lookat.view == "baselist" && props.abal &&
                  <Navbar.Text as="div" className="flex-grow-1 mx-2 col-11">
                      Select a stack to view questions
                  </Navbar.Text>
              }
              {}
              {props.lookat.view == "baselist" && !props.abal &&
                  <Navbar.Text as="div" className="flex-grow-1 mx-2 col-11">
                      Metamask not connected
                  </Navbar.Text>
              }
              {props.lookat.view == "stacklist" &&
                  <Navbar.Text as="div" className="d-flex xflex-grow-1 mx-2 xtext-truncate col-11">
                      {}
                      {}
                      <CitmTextExpandoBS etext={props.lookat.desc} maxnooverlfow={35} />
                  </Navbar.Text>
                  
              }
              {props.lookat.view == "nftlist" &&
                  <Navbar.Text as="div" className="flex-grow-1 mx-2 col-11">
                      My NFTs
                  </Navbar.Text>
              }

              {}

              {}

              {}
              {}
            
            </div>

            {}
            {}

            {}
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            
            {}
            <Navbar.Collapse className="col-4" id="responsive-navbar-nav">

              {}
              {}
              
              <Nav className="xxcol-5 justify-content-end flex-grow-1 align-items-center">

                {}

                {helpIsRegistered() ?
                
                  <>
                    {}
                    {props.lookat.view == "stacklist" && props.points.claimable > 0 &&
                        <Nav.Link as="div" className="xcol-6 xflex-grow-1 xmt-1" href="#deets" eventKey={"xpoints"}>
                            {}
                            {createAccountBut( (e) => props.accounth(e,"claimpoints"),"claim " + props.points.claimable + " [ " + props.points.allow + " max ]") }
                            
                            {}
                        </Nav.Link>
                    }
                    
                    {}
                    {props.lookat.view == "stacklist" && props.points.claimable == 0 && 

                      <>
                        <Nav.Link as="div" className="xcol-5 xp-2">
                          <Button variant="info" size="sm" className="px-3"
                            onClick={(e)=>{props.handlenav(e,"addnft")}}>Add</Button>
                        </Nav.Link>
                        
                        {}
                      </>
                    }

                    {}
                    {}
                  </>
                
                : <>
                    {props.lookat.view == "stacklist" && 
                    <Nav.Link as="div" className="col-6 xflex-grow-1 mt-1" href="#deets" eventKey={"xbuydft"}>
                        <Button
                            variant="info" size="sm"
                            onClick={(e)=>props.handlenav(e,"buydftmodal")}
                        >
                          Mint cNFT
                        </Button>
                    </Nav.Link>
                    }

                    {}
                
                  </>
                }

              </Nav>

            </Navbar.Collapse>

            {}
            {}

          

            {}


            {}

            { helpIsRegistered() && props.points.claimable == 0 &&
              <Navbar.Text as="div" className="ms-2 xflex-grow-1 text-nowrap">
                  <AccountExpando
                    etext={props.abal && props.abal.tokenvals ? (props.abal.tokenvals + " SKK") : ""}
                    showarrow={true}
                    doaction={(e: any)=>props.handlenav(e,"showaccount")}
                    arrowactive={props.arrowactive}
                  />
              </Navbar.Text>
            }
          
                  


          </Container>
          
          
        </Navbar>
  );
};
