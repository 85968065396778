import React, { useState } from "react";

import { Alert, Button, Col, Container, Row, Spinner } from "react-bootstrap";

interface Props {
  keystring: string;
  hideh: any;
  helptext: string;
}


export const CHelpBox: React.FC<Props> = (props) => {


  return (

    <Container key={props.keystring}>
        <Alert variant="primary" className="ad-helpbox-alert" dismissible onClose={props.hideh}>
            <Row className="ad-stacktopitm cbuyfirst p-4">
              <Col>{props.helptext}</Col>
            </Row>
        </Alert>
    </Container>

    

  );

};
