
import { BigNumber, ethers } from 'ethers';
import { BusyByN } from './CSInterface';


  
  
  
  
  
  
  
  
  
  
  
  
  
  

  
  
  const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;

  
  export function truncateEthAddress(address: string) {
    const match = address.match(truncateRegex);
    if (!match) return address;
    return `${match[1]}…${match[2]}`;
  }



























      







function truncate(str: string, maxDecimalDigits: number) {
  if (str.includes('.')) {
      const parts = str.split('.');
      return parts[0] + '.' + parts[1].slice(0, maxDecimalDigits);
  }
  return str;
}

export function fToken (v?:ethers.BigNumber,d?:number)  {
  
  if(v) {
    
    const accufactor = 100000

    if(d == undefined) {
      
      

      return truncate(ethers.utils.formatUnits(v,'ether'),4)

    } else {
      
      
      

      return truncate(ethers.utils.formatUnits(v,'ether'),d)
      
    }
  } else {
    return "0";
  }
}


export function fFactor(v?: ethers.BigNumber, d?: number) {
  
  

  if(v == undefined) {
    return ""

  } else {

    if (d == undefined) {
      return v.toString();
    } else {
      return v.toString().substring(0, d);

    }

  }
}

export function xFactor (v?:ethers.BigNumber,d?:number)  {
  if(v != undefined) {
      return v.toString();
  } else {
    return 0;
  }
}

export function checkOrSetBusyByN (pAr: BusyByN [], pNftId: string, pYeOrNe?: boolean)  {
    
  const res = pAr.findIndex((i)=>{
    if(i.nftid == pNftId) return true
  })
  if(res > -1) {
    if(pYeOrNe !== undefined) {
      pAr[res].busy = pYeOrNe
      return true
    } else {
      return pAr[res].busy
    }
    
  } else {
    if(pYeOrNe !== undefined) {
      pAr.push({nftid: pNftId, busy: pYeOrNe})
      return true
    } else {
      pAr.push({nftid: pNftId, busy: false})
      return false
    }
    
  }

}
