







import React, { useContext, useEffect, useState, useRef } from "react";

import { ethers } from 'ethers';

import buynftback from '../assets/buynftback.png'












import { useQuery, gql, TypedDocumentNode, WatchQueryFetchPolicy
    , ApolloQueryResult, selectHttpOptionsAndBody, InMemoryCache, FetchMoreOptions } from '@apollo/client'

import { BaseQ_Filter, InputMaybe, NewboyQuery, NewboyQueryVariables, NewsearchQuery
    , NewsearchQueryVariables, SeemonsterQuery, SeemonsterQueryVariables, UserBaseAnswerCount_Filter, ChilQ_Filter, QueryBaseQsArgs } from "../agen/graphql";





import {useApolloClient} from '@apollo/client'




import { BalanceBox } from './BalanceBox';

import { CrypSysInfo } from './CrypSysInfo';

import { CitmStackListBS } from './CitmStackListBS';
import { CitmBaseListBSql } from './CitmBaseListBSql';
import { CBaseFilterBS } from './CBaseFilterBS'
import { CBaseFilterOptionsBS } from './CBaseFilterOptionsBS'




import { CModal } from './CModal';
import { PmModal } from "./PmModal";
import { SaleModal } from "./SaleModal";



import {TestAcc, FilterItm, BaseItm, AttackItm, StDt, StDtItm, AccBal
  , QmDt, NftIds, AnStatus, EpochDetail, PointsDetail
  , AdAppType, DefItm, PMeditpacket
  , BusyStat, 
  ModalControlFields,
  NftPricelist,
  BusyByN,
  accmodalstate} from './CSInterface';





































import skkTokenAbi from '../hardhat/deployments/fantommain/SKKtoken.json';
import qNftAbi from '../hardhat/deployments/fantommain/Qnft.json';
import diamondAbi from '../hardhat/deployments/fantommain/Diamond.json';
import mintFacetAbi from '../hardhat/deployments/fantommain/MintFacet.json';
import stackflationFacetAbi from '../hardhat/deployments/fantommain/StackflationFacet.json';
import plusminFacetAbi from '../hardhat/deployments/fantommain/PlusMinFacet.json';
import parasetFacetAbi from '../hardhat/deployments/fantommain/ParaSetFacet.json';
import saleFacetAbi from '../hardhat/deployments/fantommain/SaleFacet.json';
import readFacetAbi from '../hardhat/deployments/fantommain/ReadFacet.json';
import couponFacetAbi from '../hardhat/deployments/fantommain/CouponFacet.json';
import epochadminFacetAbi from '../hardhat/deployments/fantommain/EpochAdminFacet.json';
import ownershipFacetAbi from '../hardhat/deployments/fantommain/OwnershipFacet.json';
import cNftAbi from '../hardhat/deployments/fantommain/Cnft.json';


import { SKKtoken } from '../hardhat/typechain/contracts/SKKtoken';
import { Qnft } from '../hardhat/typechain/contracts/Qnft';
import { Diamond } from '../hardhat/typechain/contracts/Diamond';
import { MintFacet } from '../hardhat/typechain/contracts/facets/MintFacet';
import { StackflationFacet } from '../hardhat/typechain/contracts/facets/StackflationFacet';
import { PlusMinFacet } from '../hardhat/typechain/contracts/facets/PlusMinFacet';
import { ParaSetFacet } from '../hardhat/typechain/contracts/facets/ParaSetFacet';
import { SaleFacet } from '../hardhat/typechain/contracts/facets/SaleFacet';
import { ReadFacet } from '../hardhat/typechain/contracts/facets/ReadFacet';
import { CouponFacet } from '../hardhat/typechain/contracts/facets/CouponFacet';
import { Cnft, NftItemStructOutput } from '../hardhat/typechain/contracts/Cnft';


import { Offcanvas, Button, Container, Row, Col, Spinner, Alert, Navbar, NavbarBrand
        , TabContainer, Tab, Nav, NavDropdown, ProgressBar, Form } from 'react-bootstrap';


import { AppBar } from "./AppBarBS";
import { Logger } from "ethers/lib/utils";
import { wait } from "@testing-library/user-event/dist/utils";
import { BuyDftModal } from "./BuyDftModal";
import { BurnModal } from "./BurnModal";
import { TypeInfo } from "graphql";
import { checkOrSetBusyByN, fToken } from "./CSCommon";
import { CHelpBox } from "./CHelpBox";
import { AccountModal } from "./AccountModal";


interface Props {
  myapptype: AdAppType
}





const testDefAcc: TestAcc[] = [
  { a: "0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266", n: "deployer", sidx: 0, simulate: false },
  { a: "0x70997970C51812dc3A010C7d01b50e0d17dc79C8", n: "fee collector", sidx: 1 , simulate: false },
  { a: "0x3C44CdDdB6a900fa2b585dd299e03d12FA4293BC", n: "another guy", sidx: 2 , simulate: false },
  { a: "0x90F79bf6EB2c4f870365E785982E1f101E93b906", n: "reward fault", sidx: 3 , simulate: false },
  { a: "0x90F79bf6EB2c4f870365E785982E1f101E93b906", n: "reward admin", sidx: 4 , simulate: false },
  { a: "0x9965507D1a55bcC2695C58ba16FB37d819B0A4dc", n: "newGuy", sidx: 5 , simulate: false },
  { a: "0x976EA74026E726554dB657fA54763abd0C3a0aa9", n: "ziggie", sidx: 6 , simulate: false },
  { a: "0x14dC79964da2C08b23698B3D3cc7Ca32193d9955", n: "marley", sidx: 7 , simulate: false },
  
];



declare var window: any


let initran = false;


export const CrypStackD: React.FC<Props> = (appprops) => {

  
  
  
  
  

  const [currentaddress, setCurrentaddress] = useState("");

  
  
  
  
  
  
  
  
  

  
  

  
  


  
  const skktoken = useRef<SKKtoken>(new ethers.Contract(process.env.REACT_APP_ADR_TOKEN || skkTokenAbi.address, skkTokenAbi.abi) as SKKtoken);
  const qnft = useRef<Qnft>(new ethers.Contract(process.env.REACT_APP_ADR_NFT || qNftAbi.address, qNftAbi.abi) as Qnft);
  const fdiamond = useRef<Diamond>(new ethers.Contract(process.env.REACT_APP_ADR_DIAMOND || diamondAbi.address, diamondAbi.abi) as Diamond);
  const fmint = useRef<MintFacet>(new ethers.Contract(process.env.REACT_APP_ADR_DIAMOND || diamondAbi.address, mintFacetAbi.abi) as MintFacet);
  const fstackflation = useRef<StackflationFacet>(new ethers.Contract(process.env.REACT_APP_ADR_DIAMOND || diamondAbi.address, stackflationFacetAbi.abi) as StackflationFacet);
  const fplusmin = useRef<PlusMinFacet>(new ethers.Contract(process.env.REACT_APP_ADR_DIAMOND || diamondAbi.address, plusminFacetAbi.abi) as PlusMinFacet);
  const fparaset = useRef<ParaSetFacet>(new ethers.Contract(process.env.REACT_APP_ADR_DIAMOND || diamondAbi.address, parasetFacetAbi.abi) as ParaSetFacet);
  const fsale = useRef<SaleFacet>(new ethers.Contract(process.env.REACT_APP_ADR_DIAMOND || diamondAbi.address, saleFacetAbi.abi) as SaleFacet);
  const fread = useRef<ReadFacet>(new ethers.Contract(process.env.REACT_APP_ADR_DIAMOND || diamondAbi.address, readFacetAbi.abi) as ReadFacet);
  const fcoup = useRef<CouponFacet>(new ethers.Contract(process.env.REACT_APP_ADR_DIAMOND || diamondAbi.address, couponFacetAbi.abi) as CouponFacet);
  const cnft = useRef<Cnft>(new ethers.Contract(process.env.REACT_APP_ADR_DFT || cNftAbi.address, cNftAbi.abi) as Cnft);

  
  
  
  
  
  

  const ethprovider = useRef<ethers.providers.Web3Provider>();
  const hhprovider = useRef<ethers.providers.JsonRpcProvider>();


  
  const [pmedit,setPmedit] = useState<PMeditpacket>({question:"", nftid:"", secsleft: 0, tokenweight:0,tokenavail:0,splusminreducefactor:0
                                        ,decayratepersec:0,tokenspend:0,cursplus:0,projsplus:0
                                        ,cursmin:0,projsmin:0, approvemax: false})

  

  const [saleitm,setSaleitm] = useState<StDt>();

  
  

  const editFieldDefaultValues: StDtItm = {question: "question", rep1: "reply1", rep2: "reply2",rep3: "reply3", a:"1"}

  
  const [qmeta, setQmeta] = useState<QmDt>({ isediting: false, nftid: "00", editdata: { question: "", rep1: "", rep2: "", rep3: "", a: "3" } });

  
  const [stackdat, setStackdat] = useState<StDt[]>([]);
  


  const [accmodalstate, _setAccmodalstate] = useState<accmodalstate>({tranapproved: false, 
    traninitiated: false, tranminted: false, showme: false, buywhat:"",errormsg:""})
  const accmodalstateRef = useRef(accmodalstate)
  const setAccmodalstate = (ur: any) => { accmodalstateRef.current = ur; _setAccmodalstate(ur); }



  const [qlbaselist,setQlbaselist] = useState<BaseItm[]>([]);

  
  const [filterbar,_setFilterbar] = useState<FilterItm>({
      search:"", affiliationavailable:false, affiliated:false, claimsavail: false, showattacks:true, usegraphql:true, isgraph:true});
  const filterbarRef = useRef(filterbar);
  const setFilterbar = (la: any) => { 
    filterbarRef.current = la
    _setFilterbar(la)
  }

  
  
  
  
  
  
  const [bboxaccounts, setBboxaccounts] = useState<AccBal[]>([]);

  const [couponlist,setCouponlist] = useState<NftItemStructOutput[]>([])
  


  const [adminInp, _setAdminInp] = useState({ 
                      tval: "4", minemodeauto: true, autoepoch: false
                       , lastloginprovider: "na"
                       , adminavail: process.env.REACT_APP_ADMIN == "1"
                       , bboxes: process.env.REACT_APP_BBOXES == "1"
                       , dispdebugAacc: false
                       , startwithJRPCprovider: process.env.REACT_APP_START_JRPC == "1"
                       , testAccStartIdx: 0
                       , defaultTestId: process.env.REACT_APP_DEFAULT_TESTID_JRPC ? parseInt(process.env.REACT_APP_DEFAULT_TESTID_JRPC) : 0 
          });
  const adminInpRef = useRef(adminInp)
  const setAdminInp = (la: any) => { adminInpRef.current = la; _setAdminInp(la)}


  
  
  
  

  const [busystat, _setBusyStat] = useState<BusyStat>({claimmint:[],claimsf:[],claimcredits:[],claimbonus:[]});
  const busystatRef = useRef(busystat)
  const setBusyStat = (la: any) => { busystatRef.current = la; _setBusyStat(la)}


  const freshIdRef = useRef(0)

  
  const [lookat, _setLookat] = useState({
    view: "baselist", id: "0", devinfo: false, vmode: "basic", showhelp: false,
    desc: "na", curadr: "", cancelnextevload: false, isvotemode: false, voteid: "0", ansperc: false,
    enableEpCheck: false, mynftlastidx: 0, mynftallrecs: false, baselastidx: 0, baseallrecs: false
  });

  
  
  
  const lookatRef = useRef(lookat);
  
  const setLookat = (la: any) => {
    
    
    lookatRef.current = la;
    _setLookat(la);
  }




  
  

  
  
  const [accbal, setAccbal] = useState<AccBal>();

  
  

  


  const [epochdisp, setEpochdisp] = useState<EpochDetail>({_maxstacklength: ethers.BigNumber.from("10")});
  

  
  
  const [points, _setPoints] = useState<PointsDetail>({ claimable: 0, allow: 0, hundredgauge: 0, allanswered: false
                                                        , lowestspnotanswer: 0, ownedquestions: 0, timetofull: 0
                                                        , answeredquestions: 0 , pointsmaxpertime: 0
                                                        , fullrechargetokens: ethers.BigNumber.from(0)
                                                        , maxallowedrecharge: ethers.BigNumber.from(0)
                                                        ,  userhaveclaimables: false });
  const pointsRef = useRef(points);
  const setPoints = (sp: any) => {
    pointsRef.current = sp;
    _setPoints(sp);
  }


  
  
  
  
  
  
  
  


  const [dmodat, _setDmodat] = useState<ModalControlFields>({ show: false, nftid: "0", action: "new", errormsg: "" });
  const dmodatRef = useRef(dmodat)
  const setDmodat = (mf: ModalControlFields) => {
    dmodatRef.current = mf
    _setDmodat(mf)
  }


  
  const apolloc = useApolloClient();


  
  
  
  
  

  const BMAN77: TypedDocumentNode<NewboyQuery,NewboyQueryVariables> = gql(`
      query newboy {
        basei: baseQs {
          id
          attacklist {
            attackval
            attacker
            baseid
            ismin
            isplus
          }
          childcount
          receiver
          desc
          stackflationEp
          stackflationTot
          attackminTot
          attackplusTot
        }
      }
    `);

  const BMAN_SEARCH: TypedDocumentNode<NewsearchQuery,NewsearchQueryVariables> = gql(`
  query newsearch(
    $qfirst: Int, $qskip: Int,
      $text: String = ""
    ) {
    basei: baseSearch (first: $qfirst, skip: $qskip, text: $text) {
      id
      attacklist {
        attackval
        attacker
        baseid
        ismin
        isplus
        blockTimestamp
      }
      baseanswercnt {
        anscount
      } 
      childcount
      receiver
      desc
      stackflationEp
      stackflationTot
      attackminTot
      attackplusTot
      mintepidx
      mintrewardTot
    }
  }
  `);




const BMAN_CFGSEARCH: TypedDocumentNode<SeemonsterQuery,SeemonsterQueryVariables> = gql(`
  query seemonster(
      $qfirst: Int, $qskip: Int,
      $where: BaseQ_filter = {childcount_lte: 10}, 
      $baseanswerwhere: UserBaseAnswerCount_filter = { user: ""},
      $childwhere: ChilQ_filter = { receiver: ""}  
    ) {
      basei: baseQs(first: $qfirst, skip: $qskip, where: $where) {
      id
      attacklist {
        attackval
        attacker
        baseid
        ismin
        isplus
        blockTimestamp
      }
      baseanswercnt (where: $baseanswerwhere) {
        anscount
      }
      childlist (where: $childwhere) {
        receiver
      }
      childcount
      receiver
      desc
      stackflationEp
      stackflationTot
      attackminTot
      attackplusTot
      mintepidx
      mintrewardTot
    }}
`);


  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  


  const doLoadBaseQL = (withqskip:number, refresh:boolean) => {

    

    
    
    
    
    

    
    if((!refresh)) {

      let olook = Object.assign({}, lookatRef.current);
      olook.view = "baselist";
      setLookat(olook);

      

      setBusyStat({ ...busystatRef.current, loading: false})


    } else {


      setBusyStat({ ...busystatRef.current, loading: true})
      
      const maxload = process.env.REACT_APP_BASEMAXLOAD ? parseInt(process.env.REACT_APP_BASEMAXLOAD) : 2

      let fp: WatchQueryFetchPolicy =  "cache-first"
      if(refresh) {
        fp = "network-only"
      }

      

      if(filterbarRef.current.search != "") {
        

        let searchTXT = filterbarRef.current.search

        
        
        
        
        const intbase = parseInt(filterbarRef.current.search)
        if(isNaN(intbase)) {
          
        } else {
          
          
          searchTXT = ethers.utils.hexValue(intbase)
        }

        

        let tqvar: NewsearchQueryVariables = {
          text: searchTXT, 
          qfirst: maxload,
          qskip: withqskip
        }

        
        
        
        
        apolloc.query({query: BMAN_SEARCH, variables:tqvar, fetchPolicy: fp})
            .then((res)=>{
              
              
              
              
              

              let baseStackQL: BaseItm [] = []
              
              if (withqskip && withqskip > 0) {
                baseStackQL = [...qlbaselist]
              }

              res.data.basei.forEach((e,i)=>{

                


                let at: AttackItm[] = []
                e.attacklist.forEach((m,mm)=>{at.push({
                
                
                  ikey: mm,
                  attackadr: m.attacker,
                  attackts: new Date().toString(),
                  
                  attacktsb: ethers.BigNumber.from(m.blockTimestamp).toNumber(),
                  attackval: ethers.BigNumber.from(m.attackval),
                  ismin: m.ismin,
                  isplus: m.isplus
                })})

                at.sort((a ,b) => {return a.attacktsb - b.attacktsb})

                at = at.slice(-3);

                baseStackQL.push({
                  ikey: i,
                  owneradr: e.receiver,
                  nftid: ethers.BigNumber.from(e.id).toString(),
                  desc: e.desc,

                  
                  stacklength: ethers.BigNumber.from(e.childcount).add(1),
                  attacks: at,
                  attackvalsum: e.attacklist.reduce((r,i)=>r.add(ethers.BigNumber.from(i.attackval)),ethers.BigNumber.from(0)),
                  attacktot: e.attacklist.length,

                  
                  
                  
                  
                  
                  

                  base_attackmin: ethers.BigNumber.from(e.attackminTot),
                  base_attackplus: ethers.BigNumber.from(e.attackplusTot),
                  base_stackflation_lastepoch: ethers.BigNumber.from(e.stackflationEp),
                  base_stackflation_total: ethers.BigNumber.from(e.stackflationTot),
                  base_stackposalltimereward: ethers.BigNumber.from(e.mintrewardTot),
                  mintepidx: ethers.BigNumber.from(e.mintepidx)

                  
                  
                  
                  
                  
                  ,baseanswercount: e.baseanswercnt.length > 0 ? e.baseanswercnt[0].anscount : 0
                })

              })


              let olook = Object.assign({}, lookatRef.current);
              olook.view = "baselist";
              
              if(res.data.basei.length < maxload) {
                olook.baseallrecs = true
              } else {
                olook.baseallrecs = false
                
                if (withqskip && withqskip > 0) {
                  olook.baselastidx = olook.baselastidx + res.data.basei.length
                } else {
                  olook.baselastidx = res.data.basei.length
                }
              }

              setLookat(olook);

              
              baseStackQL.forEach((i,idx)=>{i.ikey = idx})

              setQlbaselist(baseStackQL);

              setBusyStat({ ...busystatRef.current, loading: false})

            })
            .catch((e) => {

              

              let xsearch = Object.assign({}, filterbarRef.current);
              
              xsearch.isgraph = false;

              
              
              
              
              setFilterbar(xsearch);

            })

          

          

      } else {

        
        
        let tbqf: InputMaybe<BaseQ_Filter> = {}
        
        
        if(filterbarRef.current.affiliationavailable) {
          tbqf.childcount_lt = 9
        }

        
        if(filterbarRef.current.affiliated) {

          
          tbqf.childlist_ = {receiver: currentaddress}

          
          
          
          
          
          
          
        }

        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        

        
        
        
        
        

        
        
        
        
        
        

        
        
        
        
        
        
        
        
        
        
        

        let tbqUBAC: InputMaybe<UserBaseAnswerCount_Filter> = {user: currentaddress}
        

        

        let tqvar: SeemonsterQueryVariables = {
                      where: tbqf, 
                      baseanswerwhere: tbqUBAC, 
                      
                      qfirst: maxload,
                      qskip: withqskip
          }

          

        
        

        
        

        

        apolloc.query({query: BMAN_CFGSEARCH, variables:tqvar, fetchPolicy: fp })
            .then((res)=>{
              
              
              let baseStackQL: BaseItm [] = []
              
              if (withqskip && withqskip > 0) {
                baseStackQL = [...qlbaselist]
              }

              res.data.basei.forEach((e,i)=>{
              
                let at: AttackItm[] = []
                
                
                e.attacklist.forEach((m,mm)=>{at.push({
                  ikey: mm,
                  attackadr: m.attacker,
                  
                  attackts: new Date().toString(),
                  attacktsb: ethers.BigNumber.from(m.blockTimestamp).toNumber(),
                  attackval: ethers.BigNumber.from(m.attackval),
                  ismin: m.ismin,
                  isplus: m.isplus
                })})

                at.sort((a ,b) => {return a.attacktsb - b.attacktsb})

                at = at.slice(-3);

                
                
                
                const useranswercnt = e.baseanswercnt.length > 0 ? e.baseanswercnt[0].anscount : 0
                const childcnt = e.childcount ? e.childcount : 0

                
                
                

                
                
                
                
                
                
                if(!filterbarRef.current.claimsavail 
                    || (filterbarRef.current.claimsavail && ( (useranswercnt ) < (childcnt + 1) ) ) ) {

                  baseStackQL.push({
                    ikey: i,
                    owneradr: e.receiver,
                    nftid: ethers.BigNumber.from(e.id).toString(),
                    desc: e.desc,
                    
                    stacklength: ethers.BigNumber.from(e.childcount).add(1),
                    attacks: at,
                    attackvalsum: e.attacklist.reduce((r,i)=>r.add(ethers.BigNumber.from(i.attackval)),ethers.BigNumber.from(0)),
                    attacktot: e.attacklist.length,

                    
                    
                    
                    
                    
                    

                    base_attackmin: ethers.BigNumber.from(e.attackminTot),
                    base_attackplus: ethers.BigNumber.from(e.attackplusTot),
                    base_stackflation_lastepoch: ethers.BigNumber.from(e.stackflationEp),
                    base_stackflation_total: ethers.BigNumber.from(e.stackflationTot),
                    base_stackposalltimereward: ethers.BigNumber.from(e.mintrewardTot),
                    mintepidx: ethers.BigNumber.from(e.mintepidx)

                    
                    
                    
                    

                    ,baseanswercount: e.baseanswercnt.length > 0 ? e.baseanswercnt[0].anscount : 0
                  })

                }

              })

              
              
              

              
              let olook = Object.assign({}, lookatRef.current);
              olook.view = "baselist";

              if(res.data.basei.length < maxload) {
                olook.baseallrecs = true
              } else {
                olook.baseallrecs = false
                if (withqskip && withqskip > 0) {
                  olook.baselastidx = olook.baselastidx + res.data.basei.length
                } else {
                  olook.baselastidx = res.data.basei.length
                }
              }
              
              setLookat(olook);

              
              baseStackQL.forEach((i,idx)=>{i.ikey = idx})

              setQlbaselist(baseStackQL);

              setBusyStat({ ...busystatRef.current, loading: false})

              
              
              

              
              
              

            })
            .catch((e) => {

              
              

              let xsearch = Object.assign({}, filterbarRef.current);
              
              xsearch.isgraph = false;

              
              
              

              setFilterbar(xsearch);

              doLoadBaseFromChain(0)

            })

      }

    }

    

  }
  


  const AccName = (adr: string) => {
    
    
    const res = testDefAcc.find((e) => { if (ethers.utils.getAddress(e.a) == ethers.utils.getAddress(adr)) return true; });
    return res != undefined ? res.n : "na";
  };
  const AccSidx = (adr: string) => {
    const res = testDefAcc.find((e) => { if (ethers.utils.getAddress(e.a) == ethers.utils.getAddress(adr)) return true; });
    return res != undefined ? res.sidx : 99;
  };


  
  const GetAccountDetailFor = async (adr: string, sidx: number) => {

    

    try {

    
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      



      const coupsum = await cnft.current.getShortSummary(adr)
      
      

      const usersum = await fread.current.getUserSummary(adr)
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      

      const retval: AccBal = {
        tokenname: "SKK",
        tokenvals: fToken(usersum.skkbal, 2),
        tokenval: usersum.skkbal,
        nft: "qNFT",
        nftcount: usersum.qnftcnt.toString(),
        nftbncount: usersum.qnftcnt,
        nftbnprice: usersum.nftprice,
        etherval: usersum.ftmbal,
        currentadr: adr,
        signeridx: sidx,
        ownername: AccName(adr),
        ethername: "FTM",
        cnftbasecnt: coupsum.basecnt,
        cnftregularcnt: coupsum.regcnt,
        cnftcnt: coupsum.regcnt.add(coupsum.basecnt)
      }

      

      return retval;

    } catch(err) {
       
      setDmodat({ show: true, nftid: "0", action: "error", errormsg: "GetAccountDetailFor: err: " + err });
       
    }

  }

  
  


  
  
  
  
  
  const ethLogin = async (initialep?: ethers.providers.Web3Provider, promptaccounts?: boolean) => {

    
    
    
    let ep = initialep || ethprovider.current;

    if (!ep) {
      
      setDmodat({ show: true, nftid: "0", action: "error", errormsg: "please install MetaMask A" });
      return
    }

    
    const echain = await ep.send("eth_chainId", [])
    

    
    const hexchaincomp = process.env.REACT_APP_CHAIN_ID ? ethers.utils.hexValue(parseInt(process.env.REACT_APP_CHAIN_ID)) : ethers.utils.hexValue(1337)
    
    
    
    
    

    
    if( echain != hexchaincomp) {
      
      setDmodat({ show: true, nftid: "0", action: "error", errormsg: "wrong network. please connect to fantom." });
      return
    }

    let ainp = Object.assign({}, adminInp);
    ainp.lastloginprovider = "eth"
    setAdminInp(ainp);

    
    let mmrequest = "eth_accounts"
    if(promptaccounts) {
      mmrequest = "eth_requestAccounts"
    }
    
    
    
    
    
    
    const eacc = await ep.send(mmrequest, [])
      .catch((err) => {

        

        if (err.code === 4001) {
          
          
          
          setDmodat({ show: true, nftid: "0", action: "error", errormsg: "please connect to MetaMask AA" });
        } else {
          console.error(err);
        }

      })


    
    handleAccountsChanged(eacc);



  } 


  const handleAccountsChanged = async (eacc: any) => {

    

    let ep = ethprovider.current;

    if(ep == undefined) {
      
      setAccbal(undefined)
      setLookat({...lookatRef.current,curadr: "", view: "baselist", showhelp: false, enableEpCheck: false})
      return;
    }

    let ainp = Object.assign({}, adminInp);
    ainp.lastloginprovider = "eth"
    setAdminInp(ainp);

    if (eacc) {

      

      if (eacc.length == 0) {

        
        
        setDmodat({ show: true, nftid: "0", action: "error", errormsg: "please connect to metamask" });
        
        
        setAccbal(undefined)
        setLookat({...lookatRef.current,curadr: "", view: "baselist", showhelp: false, enableEpCheck: false})
        return

      } else if (eacc[0] != currentaddress) {
        

       


      } else {
        

      }

    } else {
      
      setAccbal(undefined)
      setLookat({...lookatRef.current,curadr: "", view: "baselist", showhelp: false, enableEpCheck: false})
      return
    }

    
    

    
    setLookat({ ...lookatRef.current, showhelp: true})

    
    setDmodat({ show: false, nftid: "0", action: "error", errormsg: "na" });

    
    
    const curadr = eacc[0]

    
    

    setCurrentaddress(curadr);

    
    
    
    
    
    setLookat({...lookatRef.current, curadr: curadr, enableEpCheck: true})
    


    
    const tsigner = ep.getSigner(curadr)
    

    const signercuradr = await tsigner.getAddress();
    

    

    
    
    
    
    skktoken.current.removeAllListeners("Transfer");
    fmint.current.removeAllListeners("NftMint");


    fdiamond.current = (fdiamond.current.connect(tsigner));

    fmint.current = (fmint.current.connect(tsigner));


    
    
    
    
    

    
    

    fstackflation.current = (fstackflation.current.connect(tsigner));
    fplusmin.current = (fplusmin.current.connect(tsigner));
    fparaset.current = (fparaset.current.connect(tsigner));
    fsale.current = (fsale.current.connect(tsigner));
    
    fread.current = (fread.current.connect(tsigner));
    
    
    fcoup.current = (fcoup.current.connect(tsigner));
    qnft.current = (qnft.current.connect(tsigner));
    skktoken.current = (skktoken.current.connect(tsigner));
    cnft.current = (cnft.current.connect(tsigner));

    
    
    
    
    

    refreshAccountComp(curadr);

    
    
    
    
    
    
    
    

    skktoken.current.on("Transfer",(from: string,to: string,val: ethers.BigNumber) => {
      
      

      if(ethers.utils.getAddress(from) == ethers.utils.getAddress(lookatRef.current.curadr) 
          || ethers.utils.getAddress(to) == ethers.utils.getAddress(lookatRef.current.curadr)) {
        
        refreshAccountComp(lookatRef.current.curadr);
      }
      
    });



    
    if (adminInpRef.current.bboxes && bboxaccounts.length == 0) {

      

      
      
      const tjrpc = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_JRPC_URL);
      

      
      let startidx = adminInpRef.current.testAccStartIdx
      for (const el of testDefAcc) {
        
        if(el.sidx != 99) {
          el.a = await tjrpc.getSigner(el.sidx).getAddress();
        }
        el.sidx = startidx
        startidx++
      }

      
      
      
      testDefAcc.push(
        { a: fdiamond.current.address, n: "front contract", sidx: 99, simulate: false }
      );

      

      
      
      
      
      
      refreshBalanceBoxes();

    }

    
    setDmodat({ ...dmodatRef.current, show: false, errormsg: ""})

    doLoadBase(true);
    

  }


  
  

  
  useEffect(() => {

    

    

    if (!window.ethereum) {
      
      setDmodat({ show: true, nftid: "0", action: "error", errormsg: "please install MetaMask B" });

    } else {
      

      const ep = new ethers.providers.Web3Provider(window.ethereum);

      
      
      ethprovider.current = ep

      if (ep && !initran) {

        initran = true

        
        window.ethereum.on('accountsChanged', handleAccountsChanged);

        window.ethereum.on('chainChanged', () => {
          
          window.location.reload();
        });

        
        
        
        
        if(adminInpRef.current.startwithJRPCprovider) {
          

          jsonRPClogin(adminInpRef.current.defaultTestId);

        } else {

          ethLogin(ep)

        }

        

      }

    }

    


    
    let timer1 = setInterval(MyFkIntFunc, 12000);

    
    
    
    return () => {

      clearInterval(timer1);

      
      
      
      skktoken.current.removeAllListeners("Transfer");
      fmint.current.removeAllListeners("NftMint");
    
    };


  }, []);







  
  
  const MyFkIntFunc = function () {

    if(lookatRef.current.enableEpCheck) {
      doEpochUpdate();
    }
    

  }





  const refreshBalanceBoxes = async () => {

    
    let bboxar = [];
    for (const el of testDefAcc) {
      let testAcc = await GetAccountDetailFor(el.a, el.sidx);
      if (testAcc != undefined) bboxar.push(testAcc);
    }
    
    setBboxaccounts(bboxar);

  }
  const refreshAccountComp = async (adr: string) => {

    
    
    

    let loggedinAccount = await GetAccountDetailFor(adr, AccSidx(adr));
    if (loggedinAccount != undefined) {
      setAccbal(loggedinAccount);
    } else {
      
    }
  }




  
  
  
  
  
  const handleBboxBut = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    adr: string,
    sidx: number
  ) => {
    e.preventDefault();

    

    jsonRPClogin(sidx);


  };

  
  const jsonRPClogin = async (
    sidx: number
  ) => {
    
    

    
    let ainp = Object.assign({}, adminInp);
    ainp.dispdebugAacc = false;
    ainp.lastloginprovider = "jrpc"
    setAdminInp(ainp);

    
    
    
    
    
    const jrpc = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_JRPC_URL);

    
    hhprovider.current = jrpc

    
    
    
    
    
    
    
    
    

    
    
    
    
    skktoken.current.removeAllListeners("Transfer");
    fmint.current.removeAllListeners("NftMint");

    fmint.current = (fmint.current.connect(jrpc.getSigner(sidx)));
    fstackflation.current = (fstackflation.current.connect(jrpc.getSigner(sidx)))
    fplusmin.current = (fplusmin.current.connect(jrpc.getSigner(sidx)))
    fparaset.current = (fparaset.current.connect(jrpc.getSigner(sidx)))
    fsale.current = (fsale.current.connect(jrpc.getSigner(sidx)))
    fread.current = (fread.current.connect(jrpc.getSigner(sidx)))
    fcoup.current = (fcoup.current.connect(jrpc.getSigner(sidx)))
    qnft.current = (qnft.current.connect(jrpc.getSigner(sidx)))
    skktoken.current = (skktoken.current.connect(jrpc.getSigner(sidx)))
    cnft.current = (cnft.current.connect(jrpc.getSigner(sidx)))

    const newadr = await jrpc.getSigner(sidx).getAddress();
    

    
    
    
    

    
    skktoken.current.on("Transfer",(from: string,to: string,val: ethers.BigNumber) => {
      

      if(ethers.utils.getAddress(from) == ethers.utils.getAddress(lookatRef.current.curadr) 
          || ethers.utils.getAddress(to) == ethers.utils.getAddress(lookatRef.current.curadr)) {
        
        refreshAccountComp(lookatRef.current.curadr);
      }
      
    });


    
    if (adminInpRef.current.bboxes && bboxaccounts.length == 0) {

      

      
      
      const tjrpc = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_JRPC_URL);
      
      
      let startidx = adminInpRef.current.testAccStartIdx
      for (const el of testDefAcc) {
        el.a = await tjrpc.getSigner(el.sidx).getAddress();
        el.sidx = startidx
        startidx++
      }

      
      
      
      testDefAcc.push(
        { a: fdiamond.current.address, n: "front contract", sidx: 99, simulate: false }
      );

      

      
      refreshBalanceBoxes();

    }

    let loggedinAccount = await GetAccountDetailFor(newadr, sidx);
    if (loggedinAccount != undefined) {
      setAccbal(loggedinAccount);
      setCurrentaddress(newadr);

      
      
      

      
      
      
      
      let olook = Object.assign({}, lookatRef.current);
      olook.curadr = newadr;
      olook.enableEpCheck = true;
      setLookat(olook);

      
      
      

      

      
      setDmodat({ ...dmodatRef.current, show: false, errormsg: ""})

      doLoadBase(true);
      

    }


  };


  const claimPoints = async () => {

      
      

      
      
      
      
      
      
      
      

      
      
      
      

      

      
      setDmodat({ ...dmodatRef.current, show: false, errormsg: ""})


      let r1ar: ethers.BigNumber[] = [];
      let r2ar: ethers.BigNumber[] = [];
      let r3ar: ethers.BigNumber[] = [];
      
      const ustack = [...stackdat];
      ustack.forEach(el => {

        

        if(el.answeractual == "1") {
          r1ar.push(ethers.BigNumber.from(el.nftid))

        } else if(el.answeractual == "2") {
          r2ar.push(ethers.BigNumber.from(el.nftid))

        } else if(el.answeractual == "3") {
          r3ar.push(ethers.BigNumber.from(el.nftid))

        }

      });

      


      let bstat = Object.assign({}, busystat);

      freshIdRef.current ++
      let fId = freshIdRef.current

      try {

        if (!bstat.points) {

          bstat.points = true;
          setBusyStat(bstat);

          

          let availp = "0";
          if (points.claimable != undefined) {
            availp = points.claimable.toString();
          }

          
          

          
          let claimtx = await fstackflation.current.pointsClaim(r1ar, r2ar, r3ar);

          
          const aw = await claimtx.wait();
          
          
          bstat.points = false;
          setBusyStat(bstat);

          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          

          updateUIpointsallowance(true);

          
          

          
          

          ustack.forEach(el => {
            if (el.answerst != AnStatus.AnswerNone) {
              el.answersaved = true;
            }
            
            
            
          });

          
          setStackdat(ustack);

          
          
          

          bstat.points = false;
          setBusyStat(bstat);

        } else {
          
           
        
        }

        if(freshIdRef.current == fId) {
          doChildrenLoadForBase(lookatRef.current.id, lookatRef.current.desc, "stacklist", lookatRef.current.curadr);
        }

      } catch (err) {

        
        
        
        
        
        
        
        
        
        
        
        
        
        
        


        
        
        
        
        

        
        
        

        

        
        
        

        
        
        bstat.points = false;
        setBusyStat(bstat);

        

        

        extractAndShowErr(err as string, "Error claiming points");
        

      }

      
    } 
  

  
  
  
  
  

  
  
  
  
  
  
  
  
  



  
  
  

  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  


  const handleAccountBox = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    cmd: string
  ) => {
    e.preventDefault();




    if (cmd == 'testcon') {

      
      
      
      
      

      

      refreshAccountComp(currentaddress);

      
    } else if (cmd == "login") {

      

      ethLogin(undefined,true);


    } else if (cmd == "test") {

      

      
      

      
      



    } else if (cmd == "logout") {

      
      
      

    } 



    
    

    let bstat = Object.assign({}, busystat);

    if (cmd == "mint") {

      

      
      
      
      

      
      
      
      
      

      
      
      
      
      

      


      

      
      
      

      

      
      

      

      

      

    } 


    else if (cmd == "updateallowance") {

      
      updateUIpointsallowance();

      refreshAccountComp(currentaddress);

    } 


    else if (cmd == "claimpoints") {

      claimPoints();

    } 

    else if (cmd == "refreshstack") {

      freshIdRef.current ++
      doChildrenLoadForBase(lookatRef.current.id, lookatRef.current.desc, "stacklist", lookatRef.current.curadr);


    } else if (cmd == "vmode") {

      
      
      
      
      
      
      
      let olook = Object.assign({}, lookat);
      olook.vmode = olook.vmode == "detail" ? "basic" : "detail"
      setLookat(olook);


    }



  };


  const doLoadBase = async (refresh: boolean) => {

    setBusyStat({ ...busystatRef.current, loading: true})

    
    
    
    
    

    
    if(refresh) {
      let olook = Object.assign({}, lookatRef.current);
      olook.baselastidx = 0
      olook.baseallrecs = false
      setLookat(olook);
    }

    if(filterbarRef.current.isgraph) {
      doLoadBaseQL(0,refresh);
    } else {  
      doLoadBaseFromChain(0);
    }

    updateUIpointsallowance(true);

    
    
    
    

  }

  


  const doLoadBaseFromChain = async (frompos: number) => {

    

    let bstat = Object.assign({}, busystat);
    bstat.loading = true;
    setBusyStat(bstat);

    try {

      
      
      let olook = Object.assign({}, lookatRef.current);
      olook.view = "baselist";
      setLookat(olook);

      
      
      let baseStackQL: BaseItm [] = []

      
      if (frompos > 0) {
        baseStackQL = [...qlbaselist]
      }
  
      
  
      const maxload = process.env.REACT_APP_BASEMAXLOAD ? parseInt(process.env.REACT_APP_BASEMAXLOAD) : 10

      
      const baseList = await fread.current.getBaseAr(frompos, maxload);

      

      baseList.forEach((i, idx) => {

        let at: AttackItm[] = []

        
        
        

        
        
        
        
        
        
        
        
        

        
        
        
        
        
        
        
        
        

        
        
        
        
        
        
        
        
        

        
        
        
        
        
        
        
        
        
        
        


        baseStackQL.push({
          ikey: idx,
          owneradr: i.owneradr,
          nftid: i.nftid.toString(),
          desc: i.q ,
          stacklength: i.stacklength,
          attacks: at,
          attackvalsum: ethers.BigNumber.from(0),
          attacktot: 0,

          base_attackmin: i.base_attackmin,
          base_attackplus: i.base_attackplus,
          base_stackflation_lastepoch: i.base_stackflation_lastepoch,
          base_stackflation_total: i.base_stackflation_total,

          base_stackposalltimereward: i.base_stackposalltimereward,

          mintepidx: i.mintepidx

          ,baseanswercount: 0

        })

      });

      
      
      
      

      

      
      if(baseList.length < maxload) {
        olook.baseallrecs = true
      } else {
        olook.baseallrecs = false
        olook.baselastidx = olook.baselastidx + baseList.length
      }

      
      setLookat(olook);

      

      
      
      baseStackQL.forEach((i,idx)=>{i.ikey = idx});

      setQlbaselist(baseStackQL);

      
      
      bstat.claim = false;
      bstat.plusmin = false;
      bstat.points = false;
      bstat.mintweth = false;
      bstat.recalc = false;
      bstat.loading = false;
      setBusyStat(bstat);

      

    } catch(err) {

      
      setDmodat({ show: true, nftid: "0", action: "error", errormsg: "doLoadBase: err: " + err });

      bstat.loading = false;
      setBusyStat(bstat);

    }


  }

  

  

  
  
  const calcApproxStackflation = (totalsf: ethers.BigNumber, epochsf: ethers.BigNumber,
    claimedsf: ethers.BigNumber, lastcalc: ethers.BigNumber) => {

    console.log("lastcalc: ", lastcalc.toString());



    if (epochdisp.epochidx != undefined
      && epochdisp.secsleft != undefined
      && epochdisp.epochsecs != undefined
      && epochdisp.lastepoch != undefined) {


      console.log("lastepoc: ", epochdisp.lastepoch.toString());

      console.log("epochidx, secsleft, epochsecs", epochdisp.epochidx.toString(), epochdisp.secsleft.toString()
        , epochdisp.epochsecs.toString());


      if (epochdisp.epochidx.gt(0)) {
        

        
        const tempFrac = 100000;

        const fractIntoNewEpoch = epochdisp.epochsecs.sub(epochdisp.secsleft).mul(tempFrac).div(epochdisp.epochsecs)
        console.log("approx: A:fractIntoNewEpoch",fractIntoNewEpoch.toString());


        
        if (lastcalc < epochdisp.lastepoch) {
          
          
          return (totalsf.sub(claimedsf));

        } else if (epochdisp.epochidx.gt(0)) {
          
          return totalsf.sub(epochsf).add(epochsf.mul(fractIntoNewEpoch).div(tempFrac)).sub(claimedsf);

        } else {
          return ethers.BigNumber.from(0);
        }
      } else {
        return ethers.BigNumber.from(0);
      }

    } else {
      return ethers.BigNumber.from(0);
    }

  }





  const createStackItem = (i: any, idx: number, frompos: number, curadr: string) => {

    
    
    

    

    
    

    
    
    
    
    
    

    return {

      ikey: idx + frompos,
      owner: i.owneradr,
      ownername: AccName(i.owneradr),
      
      isowner: ethers.utils.getAddress(i.owneradr) == ethers.utils.getAddress(curadr) ? true : false,
      nftid: i.nftid.toString(),
      isbase: i.isbase,
      isedit: false,

      
      
      
      answerst: i.useranswer == 0 ? 0 : i.useranswer == i.a ? 1 : 2,
      answerpts: i.useranswer == 0 ? 0 : i.useranswer == i.a ? 2 : 1,

      answeractual: i.useranswer,

      
      
      answersaved: i.useranswer > 0 ? true : false,

      wronganswercnt: i.wronganswercnt,
      okanswercnt: i.okanswercnt,

      r1answercnt: i.r1answercnt,
      r2answercnt: i.r2answercnt,
      r3answercnt: i.r3answercnt,

      stale: i.stale,
      ready: i.ready,

      splus: i.splusremain,
      smin: i.sminremain,

      stackpos: i.stackposition,
      stackposfactor: i.stackposfactor,
      stackposreward: i.stackposreward,
      stackposalltimereward: i.stackposalltimereward,

      
      bonusreward: i.bonusreward,
      bonusalltimereward: i.bonusalltimereward,

      totalstackflation: i.totalstackflation,
      claimedstackflation: i.claimedstackflation,
      lastepochstackflation: i.lastepochstackflation,

      stacklength: i.stacklength,
      stackcanadd: i.stackcanadd,

      laststackflationts: i.laststackflationts,

      
      
      approxavailstackflation: calcApproxStackflation(i.totalstackflation, i.lastepochstackflation,
        i.claimedstackflation, i.laststackflationts),

      
      basemintcredit: i.basemintcredit,
      basemintalltimecredit: i.basemintalltimecredit,

      mintcredit: i.mintcredit,
      mintalltimecredit: i.mintalltimecredit,
      

      isfirstviz: false,
      isfirstunviz: false,

      
      
      idat: { question: i.q, rep1: i.r1, rep2: i.r2, rep3: i.r3, a: i.a },

      uri: i.uri,

      
      
      desc: i.q,

      baseid: i.baseid.toString(),

      uiupdts: new Date().valueOf() - 5 * 60 * 1000,

      
      saleprice: i.saleprice,
      salets: i.salets,
      soldts: i.soldts,
      soldcount: i.soldcount,
      salepriceadj: ethers.BigNumber.from(0),
      salepriceset: false,
      salepriceerr: "",

      
      base_attackplus: i.base_attackplus,
      base_attackmin: i.base_attackmin,
      base_stackflation_total: i.base_stackflation_total,
      base_stackflation_lastepoch: i.base_stackflation_lastepoch,

      base_stackposalltimereward: i.base_stackposalltimereward,

      mintepidx: i.mintepidx,
      burnflagepidx: i.burnflagepidx

    };
  }


  
  
  
  

  

  

  

  
  
  
  
  
  
  

  
  
  


  
  
  
  

  
  
  
  
  
  
  
  
  

  
  

  

  
  
  


  
  
  
  

  

  

  

  
  
  
  

  

  

  

  

  
  
  
  
  
  
  

  
  

  
  
  
  
  
  



  


  
  

  
  
  
  
  
  
  


  
  

  
  

  
  



  
  
  
  
  
  
  


  
  
  
  
  
  
  
  
  


  
  
  


  


  

  

  
  


  

  

  

  const doChildrenLoadForBase = async (tId: string, desc: string, finishview: string, curadr: string) => {

    

    let olook = Object.assign({}, lookatRef.current);
    olook.view = "stacklist";
    setLookat(olook);

    let bstat = Object.assign({}, busystat);
    bstat.loading = true;
    setBusyStat(bstat);

    let newStack: StDt[] = [];

    try {

      const stackList = await fread.current.getDetailAr(ethers.BigNumber.from(tId), curadr, ethers.BigNumber.from(0));


      
      
      
      let basedescription = "";

      stackList.forEach((i: any, idx: number) => {

        const newI = createStackItem(i, idx, 0, curadr);

        newStack.push(newI);

        if (i.isbase) {

          basedescription = newI.idat.question;
        }

      }); 


      
      

      
      
      
      
      
      
      newStack.sort((a: any, b: any) => { return a.answersaved - b.answersaved || b.stackpos - a.stackpos });


      
      

      
      scoreAnswers(newStack);

      
      
      let olook = Object.assign({}, lookatRef.current);
      olook.view = finishview;
      olook.id = tId;
      olook.desc = basedescription;
      setLookat(olook);


      
      
      bstat.claim = false;
      bstat.plusmin = false;
      bstat.points = false;
      bstat.mintweth = false;
      bstat.recalc = false;
      bstat.loading = false;
      setBusyStat(bstat);


      
      
      updateUIpointsallowance();


    } catch (err) {


      

      extractAndShowErr(err as string, "error loading children for base");

      bstat.loading = false;
      setBusyStat(bstat);


    }

    

  }

  const doChildrenLoadForUser = async (user: string, frompos: number) => {

    

    
    
    
    
    let olook = Object.assign({}, lookatRef.current);
    olook.view = "nftlist";
    setLookat(olook);

    let bstat = Object.assign({}, busystat);
    bstat.loading = true;
    setBusyStat(bstat);

    let newStack: StDt[] = [];
    
    
    if (frompos > 0) {
      newStack = [...stackdat]
    }

    

    const maxload = process.env.REACT_APP_MYNFTMAXLOAD ? parseInt(process.env.REACT_APP_MYNFTMAXLOAD) : 10

    
    const stackList = await fread.current.getMyAr(user, ethers.BigNumber.from(frompos),maxload);

    stackList.forEach((i: any, idx: number) => {
      

      const newI = createStackItem(i, idx, frompos, lookatRef.current.curadr);
      newStack.push(newI);

    });

    newStack.sort((a: any, b: any) => { return a.isbase - b.isbase || b.stackpos - a.stackpos });

    

    setStackdat(newStack)

    

    
    
    
    
    if(frompos == 0) {
      
      olook.mynftallrecs = false
      olook.mynftlastidx = 0
    } 
    if(stackList.length < maxload) {
      
      olook.mynftallrecs = true
    } else {
      olook.mynftallrecs = false
      olook.mynftlastidx = olook.mynftlastidx + stackList.length
    }
    setLookat(olook)


    
    bstat.claim = false;
    bstat.plusmin = false;
    bstat.points = false;
    bstat.mintweth = false;
    bstat.recalc = false;
    bstat.loading = false;
    setBusyStat(bstat);

    

  }

  

  

  

  
  
  
  
  
  
  

  
  
  


  

  
  

  
  
  
  
  
  
  
  
  


  
  

  

  

  

  
  



  

  
  
  

  

  
  

  
  
  
  
  
  
  
  
  

  

  




  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  


  
  
  
  
  
  
  

  
  

  

  
  

  

  
  
  
  
  


  
  

  

  

  

  









  

  const handleUIstate = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();

    
    setLookat({ ...lookatRef.current, ansperc: !lookatRef.current.ansperc })

  }


  
  
  
  
  const handleAnswerBut = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,

    answer: string,
    nftid: string,
  ) => {
    e.preventDefault();

    

    

    let ustack = [...stackdat];

    
    
    
    

    ustack.forEach((el) => {

      

      if (el.nftid == nftid) {

        
        el.answeractual = answer

        if (answer == el.idat.a) {
          el.answerst = AnStatus.AnswerOK;

          
          el.answerpts = 2;
          

          
        } else {
          el.answerst = AnStatus.AnswerWrong;

          
          el.answerpts = 1;
        }

        el.uiupdts = new Date().valueOf();
      }
    });

    

    

    scoreAnswers(ustack);

    

    

  };

  

  
  
  
  
  const scoreAnswers = (ustax: StDt[] | undefined) => {

    

    let ustack = [];

    if (ustax !== undefined) {
      ustack = ustax;
    } else {
      ustack = [...stackdat];
    }

    
    let lowestStackposNotAnswered = ustack.length;
    let allanswered = true;
    let cumpoints = 0;
    let questionsownedinstack = 0
    let answercount = 0;
    let userhaveclaimables = false

    ustack.forEach(el => {

      

      if (el.answerst == AnStatus.AnswerNone) {
        allanswered = false;
        
        if (lowestStackposNotAnswered == 0 || el.stackpos < lowestStackposNotAnswered) {
          lowestStackposNotAnswered = el.stackpos;
        }


      
      

      
      
      }

      el.answered = el.answeractual

      
      
      if (el.answerpts != 0 && !el.answersaved) {
        cumpoints += el.answerpts;
      }


    }); 

    
    ustack.forEach(el => {

      
      if(el.owner == lookatRef.current.curadr) {
        questionsownedinstack++

        
        if(el.stackposreward.gt(0) || el.approxavailstackflation.gt(0)) {
          userhaveclaimables = true
        }
      }

      if(el.answerst != AnStatus.AnswerNone) {
        answercount++
      }

      if (el.stackpos == lowestStackposNotAnswered) {
        el.isfirstviz = true;
      }

      if (el.stackpos == (lowestStackposNotAnswered + 1)) {
        el.isfirstunviz = true;
        
      }

    }); 

    

    let opoints = Object.assign({}, pointsRef.current);
    opoints.claimable = cumpoints;
    opoints.allanswered = allanswered;
    opoints.lowestspnotanswer = lowestStackposNotAnswered;
    opoints.ownedquestions = questionsownedinstack
    opoints.answeredquestions = answercount
    opoints.userhaveclaimables = userhaveclaimables
    setPoints(opoints);

    

    setStackdat(ustack);

  };




  const updateUIpointsallowance = async (resetclaimablepoints?: boolean) => {

    let pointsAllowance = await fstackflation.current.pointsClaimAllowance(lookatRef.current.curadr);
    
    

    let opoints = Object.assign({}, pointsRef.current);
    if (resetclaimablepoints) {
      opoints.claimable = 0;
    }

    opoints.allow = pointsAllowance.pointsallownow.toNumber();
    opoints.hundredgauge = pointsAllowance.pointsallowperc.toNumber();
    opoints.pointsmaxpertime = pointsAllowance.pointsmaxpertime.toNumber();

    
    opoints.fullrechargetokens = pointsAllowance.fullrechargetokens
    const maxPerc: ethers.BigNumber = ethers.BigNumber.from(100)
    const maxRechargePerc: ethers.BigNumber = maxPerc.sub(pointsAllowance.pointsallowperc)
    const maxAllowedToken: ethers.BigNumber = pointsAllowance.fullrechargetokens.mul(maxRechargePerc).div(maxPerc)
    opoints.maxallowedrecharge = maxAllowedToken

    setPoints(opoints);

  }



  const doEpochUpdate = async () => {
    
    

    
    
    
    

    try {

      const epd: EpochDetail = await fstackflation.current.getEpoch();

      
      

      setEpochdisp(epd);

      
      

      
      
      

      
      


      if(process.env.REACT_APP_APPVERSION && epd._appversion != Number.parseInt(process.env.REACT_APP_APPVERSION)) {
        

        
        setLookat({ ...lookatRef.current, enableEpCheck: false })

        
        
        setDmodat({ show: true, nftid: "0", action: "error", errormsg: "App is out of date. Please refresh your browser." });

      } 
      
      
      


    } catch(err) {

      
      
    }

    
  }











  
  const handleAdminUpdates = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.preventDefault();

    let ainp = Object.assign({}, adminInp);
    ainp.tval = e.target.value;
    setAdminInp(ainp);

    

    

  };

  


  const handleCrypSysInfo = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    cmd: string
  ) => {
    e.preventDefault();
    

    

    let ainp = Object.assign({}, adminInp);
    

    if (cmd == "mintnew") {
      


    } else if (cmd == "hide") {

      ainp.dispdebugAacc = !ainp.dispdebugAacc;
      setAdminInp(ainp);

    } else if (cmd == "loadbase") {

      doLoadBase(true);

    } else if (cmd == "points") {
      

      claimPoints();

      


    } else if (cmd == "epoch") {
      


      doEpochUpdate();

    } else if (cmd == "autoepoch") {
      


      

      ainp.minemodeauto = !ainp.minemodeauto;
      ainp.autoepoch = !ainp.autoepoch;
      setAdminInp(ainp);

      



    } else if (cmd == "automine") {
      

      
      const jrpc = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_JRPC_URL);

      if (!jrpc) return;

      ainp.minemodeauto = !ainp.minemodeauto;
      setAdminInp(ainp);
      

      
      if (adminInpRef.current.minemodeauto) {
        
        await jrpc.send("evm_setAutomine", [false]);
        
        await jrpc.send("evm_setIntervalMining", [2000]);
      } else {
        
        await jrpc.send("evm_setAutomine", [true]);
        await jrpc.send("evm_setIntervalMining", [0]);

      }

    } else if (cmd == "testattack") {
      

      
      
      
      
      


    } else if (cmd == "devinfo") {
      

      
      let olook = Object.assign({}, lookat);
      olook.devinfo = !olook.devinfo;
      setLookat(olook);

    } else if (cmd == "setprice") {
      

      

      
      const ethprice = ethers.utils.parseEther(adminInpRef.current.tval);

      console.log("targ price is: ", ethprice.toString());

      if (!qnft) return;

      if (ethprice != undefined) {

        console.log("price is: ", (await fparaset.current.getNftBasePriceToken()).toString());

        (await fparaset.current.setNftPriceToken(ethprice)).wait();

        console.log("NOW price is: ", (await fparaset.current.getNftPriceToken()).toString());

      }



    }

  };

  

  const handleClaims = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    tid: string,
    cmd: string,
    cmdsrc: string
  ) => {
    e.preventDefault();
    
    
    setDmodat({ ...dmodatRef.current, show: false})
    

    if (cmd == "claimmintcredits") {

      let bstat = Object.assign({}, busystatRef.current);

      try {

        if (!checkOrSetBusyByN(busystatRef.current.claimcredits,tid)) {

          

          
          checkOrSetBusyByN(bstat.claimcredits,tid,true)
          setBusyStat(bstat);
          
          freshIdRef.current ++
          let fId = freshIdRef.current

          const claimTran = await fstackflation.current.claimMintCredits(tid)
          await claimTran.wait()

          

          checkOrSetBusyByN(bstat.claimcredits,tid,false)
          setBusyStat(bstat);

          if (cmdsrc == "stacklist" && freshIdRef.current == fId) {
            doChildrenLoadForBase(tid, lookatRef.current.desc, "stacklist", lookatRef.current.curadr);
          } else if (cmdsrc == "nftlist" && freshIdRef.current == fId) {
            doChildrenLoadForUser(lookatRef.current.curadr, 0);
          }


        } else {  }

      } catch (err) {
        

        checkOrSetBusyByN(bstat.claimcredits,tid,false)
        setBusyStat(bstat);

        extractAndShowErr(err as string, "Error claiming rewards.");

      }

    } else if (cmd == "stackflation") {

      let bstat = Object.assign({}, busystatRef.current);

      try {

        if (!checkOrSetBusyByN(busystatRef.current.claimsf,tid)) {

          
          
          checkOrSetBusyByN(bstat.claimsf,tid,true)
          setBusyStat(bstat);
          freshIdRef.current ++
          let fId = freshIdRef.current

          const claimSF = await fstackflation.current.claimStackflation(tid);
          await claimSF.wait();

          checkOrSetBusyByN(bstat.claimsf,tid,false)
          setBusyStat(bstat);

          console.log("stackflation: claimSF for tid: ", tid.toString());

          if (cmdsrc == "stacklist" && freshIdRef.current == fId) {
            doChildrenLoadForBase(tid, lookatRef.current.desc, "stacklist", lookatRef.current.curadr);
          } else if (cmdsrc == "nftlist" && freshIdRef.current == fId) {
            
            doChildrenLoadForUser(lookatRef.current.curadr, 0);
          }

        } else {  }

      } catch (err) {
        
        
        checkOrSetBusyByN(bstat.claimsf,tid,false)
        setBusyStat(bstat);

        extractAndShowErr(err as string, "Error claiming rewards.");
      }

    } else if (cmd == "stackmintreward") {

      let bstat = Object.assign({}, busystatRef.current);

      try {

        
        if(!checkOrSetBusyByN(busystatRef.current.claimmint,tid)) {

          
          
          

          checkOrSetBusyByN(bstat.claimmint,tid,true)

          setBusyStat(bstat);
          freshIdRef.current ++
          let fId = freshIdRef.current

          const claimSMR = await fmint.current.claimStackMintReward(tid);
          await claimSMR.wait();

          
          checkOrSetBusyByN(bstat.claimmint,tid,false)

          setBusyStat(bstat);

          console.log("stackmintreward: claimSMR for tid: ", tid.toString());

          if (cmdsrc == "stacklist" && freshIdRef.current == fId) {
            doChildrenLoadForBase(tid, lookatRef.current.desc, "stacklist", lookatRef.current.curadr);

          } else if (cmdsrc == "nftlist" && freshIdRef.current == fId) {
            
            doChildrenLoadForUser(lookatRef.current.curadr, 0);
          }

        } else {  }

      } catch (err) {
        
        
        checkOrSetBusyByN(bstat.claimmint,tid,false)
        setBusyStat(bstat);

        extractAndShowErr(err as string, "Error claiming rewards.");

      }

    } else if (cmd == "claimbonus") {

      let bstat = Object.assign({}, busystatRef.current);

      try {

        if(!checkOrSetBusyByN(busystatRef.current.claimbonus,tid)) {

          
          
          checkOrSetBusyByN(bstat.claimbonus,tid,true)

          setBusyStat(bstat);
          freshIdRef.current ++
          let fId = freshIdRef.current

          const claimBONUS = await fmint.current.claimBonusReward(tid);
          await claimBONUS.wait();

          checkOrSetBusyByN(bstat.claimbonus,tid,false)

          setBusyStat(bstat);

          console.log("stackmintreward: claimSMR for tid: ", tid.toString());

          if (cmdsrc == "stacklist" && freshIdRef.current == fId) {
            doChildrenLoadForBase(tid, lookatRef.current.desc, "stacklist", lookatRef.current.curadr);

          } else if (cmdsrc == "nftlist" && freshIdRef.current == fId) {
            
            doChildrenLoadForUser(lookatRef.current.curadr, 0);
          }

        } else {  }

      } catch (err) {
        
        
        checkOrSetBusyByN(bstat.claimbonus,tid,false)
        setBusyStat(bstat);

        extractAndShowErr(err as string, "Error claiming rewards.");

      }

    }


  };


  const handleNft = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    tid: string,
    desc: string,
    cmd: string
  ) => {
    e.preventDefault();
    

    

    if (cmd == "viewstack") {

      try {

        
        
        

        freshIdRef.current ++
        doChildrenLoadForBase(tid, desc, "stacklist", lookatRef.current.curadr);

      } catch (err) {
        
        
        
        
      }


    

      
    } else if (cmd == "burndirect") {

      try {
        
        
        freshIdRef.current ++
        let fId = freshIdRef.current

        
        
        const burntran = await qnft.current.burn(tid);
        await burntran.wait();

        
        

        if(freshIdRef.current == fId) doChildrenLoadForUser(lookatRef.current.curadr, 0);

      } catch (err) {
        
      }

    }

  };


  const handleBurn = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    tid: string,
    cmd: string
    
  ) => {
    
    let bstat = Object.assign({}, busystatRef.current);

    
    setDmodat({ ...dmodatRef.current, show: true, errormsg: "", valimsg: "" })

    

    if (cmd == "burnloadmodal") {

        freshIdRef.current ++

        
        stackdat.forEach((v) => {
          if(v.nftid == tid) {
            
            

            
            
            
            setDmodat({ ...dmodatRef.current, show: true, nftid: tid, action: "burnmodal", errormsg: "", 
              burndetail: {
                nftid: v.nftid,
                mintreward: v.stackposreward,
                question: v.idat.question

              } 
            });

            

          }
        });


    } else if (cmd == "burn") {


      if(!bstat.burning) {

        try {
          
          
          
          

          
          if(dmodatRef.current.burndetail && dmodatRef.current.burndetail.mintreward.gt(0)) {
            

            setDmodat({ ...dmodatRef.current, errormsg: "please claim all rewards before burning" });
            return false

          }

          bstat.burning = true;
          bstat.busytranburn = false;
          bstat.busytranapprove = true;
          setBusyStat(bstat);

          freshIdRef.current ++
          let fId = freshIdRef.current

          

          const approvedAdr = await qnft.current.getApproved(tid)

          if(approvedAdr != fdiamond.current.address) {
            

            const approveTran = await qnft.current.approve(fdiamond.current.address,tid)
            await approveTran.wait()

            
          }

          

          bstat.busytranburn = true;
          bstat.busytranapprove = false;
          setBusyStat(bstat);

          
          
          const burntran = await fplusmin.current.burnToken(tid);
          await burntran.wait();

          

          bstat.burning = false;
          bstat.busytranburn = false;
          setBusyStat(bstat);

          

          handleModalHide()

          if(freshIdRef.current == fId) doChildrenLoadForUser(lookatRef.current.curadr, 0);

        } catch (err) {
          

          bstat.burning = false;
          bstat.busytranapprove = false;
          bstat.busytranburn = false;
          setBusyStat(bstat);

          extractAndShowErr(err as string, "Error burning qNFT.");

        }

      } else { }

  

    } else if (cmd == "cancel") {

      
      setDmodat({ ...dmodatRef.current, show: false, errormsg: "" });

    }

  };


  const defErrStrAr = [
    ["user rejected","Error: User Rejected Transaction"]
  ]
  const extractAndShowErr = (err: any, nomatch: string, extractonly?: boolean) => {
    
    const re1 = /\\"message\\":\\"(.*?)\\"/g
    const re2 = /"message":.?"(.*?)"/g
    
    

    
    
    
    
    
    
    
    

    let extract = ""

    
    
    if(typeof err === "string") {

    
      let match1 = re1.exec(err);
      let match2 = re2.exec(err);
      

      if (match1 != undefined && match1.length > 1) {
        
        
        extract = match1[1]
      } else {
        if (match2 != undefined && match2.length > 1) {
          
          
          extract = match2[1]
        } else {
          
          
          extract = nomatch
        }
        
      }

    
    } else {
      
    
    
    
    
    
    
    

      
      if(err && err.code == -32603) {
        

        if(err.data && err.data.message) {
          
          extract = err.data.message
        
        
        } else if(err.message){
          
          
          let match2 = re2.exec(err.message);
          if (match2 != undefined && match2.length > 1) {
            

            
            
            if(match2[1].includes("JSON-RPC")) {
              
              extract = nomatch
            } else {
              
              extract = match2[1]
            }
            
          } else {
            
            
            extract = nomatch
          }
        } else {
          
          extract = nomatch
        }

      } else if(err && err.reason && err.reason != "") {
        
        
        extract = "Error: " + err.reason
      
      
      
      } else {
        

        

        
        
        
        
        
        
        

        
        
          
        
        
        
        

        
        extract = nomatch
        
      }
      

    }

    
    if(extractonly === undefined || !extractonly) {
      
      setDmodat({ ...dmodatRef.current, show: true, action: "error", errormsg: extract });
      return ""
    } else {
      
      return extract
    }

  }




  










  
  const topStackHandler = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    cmd: string,
    tid: string
  ) => {
    e.preventDefault();

    handletopStackHandler(cmd,tid);

  }

  

  

  const handletopStackHandler = async (
      cmd: string,
      tid: string
    ) => {
      


    let bstat = Object.assign({}, busystat);

    

    
    if (cmd == "buynft") {

      
      setDmodat({ ...dmodatRef.current, show: false, errormsg: "", valimsg: ""})

      let ustack = [...stackdat];

      if (ustack.length > 0) {

        console.log("ETHBAL IS:",accbal && accbal.etherval ? accbal.etherval.toString() : "na")

        
        const uedit = Object.assign({}, qmeta);
        setQmeta({ editdata: {...editFieldDefaultValues }, nftid: "new", isediting: true })

        
        bstat = Object.assign({}, busystatRef.current);
        bstat.mintweth = false;
        bstat.mintwethbase = false;
        bstat.mintcoupon = false;
        bstat.mintcouponbase = false;
        bstat.busytranapprove = false;
        bstat.busytranmint = false;
        setBusyStat(bstat)
      

        
        

        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        

        

        
        

        cnft.current.getSummary(lookatRef.current.curadr).then((res)=>{
          if(res.basecnt.gt(0)) {

          }
          setCouponlist(res.nfts)
        })



        
        
        if(epochdisp._maxstacklength && ustack[0].stacklength.lt(epochdisp._maxstacklength)) {

          
          setDmodat({ ...dmodatRef.current, show: true, nftid: ustack[0].nftid, action: "new", errormsg: "" });

        } else {

          
          setDmodat({ ...dmodatRef.current, show: true, nftid: ustack[0].nftid, action: "new", errormsg: "Warning: Currently at maximum stack length" });
        }

      } else {
        
      }


    } else if (cmd == "buybase") {

      
      setDmodat({ ...dmodatRef.current, show: false, errormsg: "", valimsg: ""})

      let ustack = [...stackdat];

      if (ustack.length > 0) {

        let uedit = Object.assign({}, qmeta);
        uedit.nftid = "base";
        uedit.isediting = true;
        uedit.editdata.question = "base type q";
        uedit.editdata.rep1 = "base r1";
        uedit.editdata.rep2 = "base r2";
        uedit.editdata.rep3 = "base r3";
        uedit.editdata.a = "2";
        setQmeta(uedit);

        
        setDmodat({ ...dmodatRef.current, show: true, nftid: ustack[0].nftid, action: "newbase", errormsg: "" });

      } else {
        
      }

    } else if (cmd == "recalc") {
      

      
      setDmodat({ ...dmodatRef.current, show: false, errormsg: "", valimsg: ""})

      try {

        if (!bstat.recalc) {

          bstat.recalc = true;
          setBusyStat(bstat);
          freshIdRef.current ++
          let fId = freshIdRef.current

          
          const stackpos = await fstackflation.current.adjustAndCalcStackflation(ethers.BigNumber.from(tid));
          await stackpos.wait();

          bstat.recalc = false;
          setBusyStat(bstat);

          if(freshIdRef.current == fId) doChildrenLoadForBase(lookatRef.current.id,  lookatRef.current.desc, "stacklist", lookatRef.current.curadr);

        } else {  }


      } catch (err) {
        
        
        bstat.recalc = false;
        setBusyStat(bstat);

        extractAndShowErr(err as string, "error recalculating stack.");
      }

    }


  };


  const handleFilter = async (
    evtype: string,
    searchstr: string,
    chkval: boolean
  ) => {
    
    

    if(evtype == "filter-changesearchterm") {

      let xsearch = Object.assign({}, filterbarRef.current);
      xsearch.search = searchstr;
      setFilterbar(xsearch);

    } else if(evtype == "filter-dosearch") {

      doLoadBaseQL(0,true)

      
      
      


    } else if(evtype == "filter-clearsearch") {

      let xsearch = Object.assign({}, filterbarRef.current);
      xsearch.search = "";
      setFilterbar(xsearch);

      doLoadBaseQL(0,true)

    } else if(evtype == "filter-affiliation-available") {
      

      let xsearch = Object.assign({}, filterbarRef.current);
      xsearch.affiliationavailable = chkval;
      setFilterbar(xsearch);

      
      doLoadBaseQL(0,true)


    } else if(evtype == "filter-claims-available") {
      let xsearch = Object.assign({}, filterbarRef.current);
      xsearch.claimsavail = chkval;
      setFilterbar(xsearch);

      
      doLoadBaseQL(0,true)

    } else if(evtype == "filter-affiliated") {

      let xsearch = Object.assign({}, filterbarRef.current);
      xsearch.affiliated = chkval;
      setFilterbar(xsearch);

      
      doLoadBaseQL(0,true)

    } else if(evtype == "filter-showattacks") {

      let xsearch = Object.assign({}, filterbarRef.current);
      xsearch.showattacks = chkval;

      
      setFilterbar(xsearch);

      
      doLoadBaseQL(0,true)

    } else if(evtype == "filter-usegraphql") {

      let xsearch = Object.assign({}, filterbarRef.current);
      xsearch.usegraphql = chkval;
      xsearch.isgraph = chkval;
      setFilterbar(xsearch);


      
      doLoadBaseQL(0,true)


    } else if(evtype == "filter-open-search-options") {


      
      setDmodat({ ...dmodatRef.current, show: true, action: "filter-open-search-options", errormsg: "" });


    } else if(evtype == "filter-open-search-refresh") {

      
      doLoadBase(true)

    }
    
    
  };


  
  const handleModalFieldUpdates = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    target: string,
    field: keyof StDtItm
  ) => {


    

    
    
    

    let uedit = Object.assign({}, qmeta);
    uedit.editdata[field] = target;
    
    setQmeta(uedit);

    

  };

  
  const PmCalc = (tokenchange?: number, pmeditinput?: PMeditpacket) => {

    let upmedit = Object.assign({}, pmedit);

    if(pmeditinput != undefined) {
      upmedit = pmeditinput;
    }
    

    if(tokenchange != undefined) {
      upmedit.tokenspend += tokenchange;
    }
    
    upmedit.tokenweight = upmedit.tokenspend * upmedit.splusminreducefactor;

    const cursmin_delayed = upmedit.cursmin - (upmedit.secsleft * upmedit.decayratepersec);
    const cursplus_delayed = upmedit.cursplus - (upmedit.secsleft * upmedit.decayratepersec);

    upmedit.projsmin = cursmin_delayed
    upmedit.projsplus = cursplus_delayed

    if(upmedit.tokenspend > 0) {
      upmedit.projsplus += Math.abs(upmedit.tokenweight);
    
    } else if (upmedit.tokenspend < 0) {
      upmedit.projsmin +=  Math.abs(upmedit.tokenweight);
      
    }

    if(upmedit.projsmin < 0) {
      upmedit.projsmin = 0;
    }
    if(upmedit.projsplus < 0) {
      upmedit.projsplus = 0;
    }

    setPmedit(upmedit)

  }

  
  
  const handlePmModalFieldUpdates = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    updcmd: string,
    
    tokenchange?: number
  ) => {

    if(updcmd == "minplusclick" && tokenchange != undefined) {

      e.preventDefault();

      PmCalc(tokenchange);

    } else if(updcmd == "approvecheck") {
      

      

      

      let upmedit = Object.assign({}, pmedit);
      upmedit.approvemax = !upmedit.approvemax
      setPmedit(upmedit)

    }
    
    

  };

  
  
  
  
  
  
  

  

  

  

  


  
  
        
  

  

  

  
  
    

  

  const handleSaleModalOnChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    
    
    
  ) => {

    

    const newsaleitm = Object.assign({}, saleitm)

    try {
      const {value, name} = e.target
      

      if(name == "salepradj") {
        
        
        
        
        
        
        
        newsaleitm.salepriceerr = ""
        newsaleitm.salepriceadj =  ethers.BigNumber.from(value)
        

      }
    } catch(e) {

      newsaleitm.salepriceerr = "invalid sale price"
    
    } finally {

      setSaleitm(newsaleitm);

    }
  }





  
  const handleModalHide = async (
  ) => {

    setDmodat({ ...dmodatRef.current, show:false, errormsg: "", valimsg: ""})

    
    
    let bstat = Object.assign({}, busystatRef.current);
    
    
    bstat.mintweth = false;
    bstat.mintwethbase = false;
    bstat.mintcoupon = false;
    bstat.mintcouponbase = false;
    bstat.busytranapprove = false;
    bstat.busytranmint = false;

    
    bstat.busytranattack = false;
    bstat.plusmin = false;

    bstat.salebuy = false;
    bstat.saleset5 = false;

    bstat.busytranburn = false;

    setBusyStat(bstat);

  };



  const handleSplus = async (
    
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    tid: string,
    cmd: string,
    qty: number
    
  ) => {
    
    let bstat = Object.assign({}, busystatRef.current);

    
    
    setDmodat({ ...dmodatRef.current, show: true, errormsg: "" });


    if (cmd == "attackloadmodal") {

        freshIdRef.current ++

        
        stackdat.forEach((v) => {
          if(v.nftid == tid) {
            
            

            let upmedit = Object.assign({}, pmedit);
            upmedit.tokenspend = 0;
            upmedit.tokenweight = 0;

            upmedit.approvemax = false

            upmedit.nftid = v.nftid

            upmedit.tokenavail = accbal && accbal.tokenval ? parseInt(ethers.utils.formatEther(accbal.tokenval)) : 0

            upmedit.question = v.idat.question;
            upmedit.secsleft = epochdisp.secsleft ? epochdisp.secsleft.toNumber() : 0;
            upmedit.cursmin = v.smin.toNumber();
            upmedit.cursplus = v.splus.toNumber();
            upmedit.decayratepersec = epochdisp.plusmindecaypersec ? epochdisp.plusmindecaypersec.toNumber() : 0;
            upmedit.splusminreducefactor = epochdisp.splusminreducefactor ? ethers.utils.parseEther("1").div(epochdisp.splusminreducefactor).toNumber() : 0;

            upmedit.projsmin = 0
            upmedit.projsplus = 0

            const tt = accbal && accbal.tokenval ? parseInt(ethers.utils.formatEther(accbal.tokenval)) : 0
            

            setPmedit(upmedit)
          }
        });

        
        setDmodat({ ...dmodatRef.current, show: true, nftid: tid, action: "plusminedit", errormsg: "" });

        


    } else if (cmd == "refresh") {

      

      bstat.plusminrefresh = true;
      setBusyStat(bstat);

      const splusmin = await fplusmin.current.getCurrentPlusMin(tid);
      
      


      let upmedit = Object.assign({}, pmedit);
      
      
      
      upmedit.secsleft = epochdisp.secsleft ? epochdisp.secsleft.toNumber() : 0;
      upmedit.cursmin = splusmin[1].toNumber();
      upmedit.cursplus = splusmin[0].toNumber();
      
      
      
      

      


      PmCalc(0,upmedit);

      bstat.plusminrefresh = false;
      setBusyStat(bstat);


    } else if (cmd == "cancel") {

      
      setDmodat({ ...dmodatRef.current, show: false, errormsg: "" });

      

    } else if (cmd == "plus") {

      try {

        if (!bstat.plusmin) {
          

          bstat.plusmin = true;
          bstat.busytranapprove = true;
          setBusyStat(bstat);

          freshIdRef.current ++
          let fId = freshIdRef.current

          const minPlusMin = await fplusmin.current.getPlusMinMinimum();

          let cstAllow = await skktoken.current.allowance(currentaddress,diamondAbi.address);

          
          
          

          
          if(minPlusMin.mul(qty).gt(cstAllow)) {
            

            let useqty = qty
            if(pmedit.approvemax) {
              useqty = 500
            } 

            const incAllow = await skktoken.current.approve(diamondAbi.address,minPlusMin.mul(useqty));
            
            

            await incAllow.wait();

            

            bstat = Object.assign({}, busystatRef.current)
            bstat.busytranapprove = false;
            setBusyStat(bstat);

            
            
          
          } else {
            

            bstat = Object.assign({}, busystatRef.current)
            bstat.busytranapprove = false;
            setBusyStat(bstat);

          }

          bstat = Object.assign({}, busystatRef.current)

          
          if(!bstat.busytranapprove){
          
            bstat.busytranattack = true;
            setBusyStat(bstat);

            const doPlusMin = await fplusmin.current.increaseSplus(tid, minPlusMin.mul(qty));
            await doPlusMin.wait();

            bstat = Object.assign({}, busystatRef.current)
            bstat.busytranattack = false;
            bstat.plusmin = false;
            setBusyStat(bstat);

            
            setDmodat({ ...dmodatRef.current, show: false, errormsg: "" });

            if(freshIdRef.current == fId) doChildrenLoadForBase(tid, lookatRef.current.desc, "stacklist", lookatRef.current.curadr);

          
          } else {

            setDmodat({ show: true, nftid: dmodatRef.current.nftid, action: dmodatRef.current.action, errormsg: "approval failed" });

            bstat = Object.assign({}, busystatRef.current)
            bstat.busytranapprove = false;
            bstat.busytranattack = false;
            bstat.plusmin = false;
            setBusyStat(bstat);

          }

        } else {  }

      } catch (err) {
        
        

        setDmodat({ ...dmodatRef.current, show: true, errormsg: extractAndShowErr(err,"error attacking. please try again",true) })

        bstat = Object.assign({}, busystatRef.current)
        bstat.plusmin = false;
        bstat.busytranapprove = false;
        bstat.busytranattack = false;
        setBusyStat(bstat);
        
      }



    } else if (cmd == "min") {

      try {

        if (!bstat.plusmin) {

          bstat.plusmin = true;
          bstat.busytranapprove = true;
          setBusyStat(bstat);

          freshIdRef.current ++
          let fId = freshIdRef.current

          const minPlusMin = await fplusmin.current.getPlusMinMinimum();
          
          let cstAllow = await skktoken.current.allowance(currentaddress,diamondAbi.address);

          

          if(minPlusMin.mul(qty).gt(cstAllow)) {
            
            
            let useqty = qty
            if(pmedit.approvemax) {
              useqty = 500
            } 
            
            const incAllow = await skktoken.current.approve(diamondAbi.address,minPlusMin.mul(useqty));

            await incAllow.wait();

            
            

            bstat = Object.assign({}, busystatRef.current)
            bstat.busytranapprove = false;
            setBusyStat(bstat);

          } else {
            

            bstat = Object.assign({}, busystatRef.current)
            bstat.busytranapprove = false;
            setBusyStat(bstat);

          }

          bstat = Object.assign({}, busystatRef.current)

          
          if(!bstat.busytranapprove){

            bstat.busytranattack = true;
            setBusyStat(bstat);

            const doPlusMin = await fplusmin.current.increaseSmin(tid, minPlusMin.mul(qty));
            await doPlusMin.wait();

            bstat = Object.assign({}, busystatRef.current)
            bstat.busytranattack = false;
            bstat.plusmin = false;
            setBusyStat(bstat);

            
            setDmodat({ ...dmodatRef.current, show: false, errormsg: "" });

            if(freshIdRef.current == fId) doChildrenLoadForBase(tid, lookatRef.current.desc, "stacklist", lookatRef.current.curadr);


          
          } else {

            setDmodat({ show: true, nftid: dmodatRef.current.nftid, action: dmodatRef.current.action, errormsg: "approval failed" });

            bstat = Object.assign({}, busystatRef.current)
            bstat.busytranapprove = false;
            bstat.busytranattack = false;
            bstat.plusmin = false;
            setBusyStat(bstat);

          }

        } else {  }

      } catch (err) {
        
        setDmodat({ ...dmodatRef.current, show: true, errormsg: extractAndShowErr(err,"error attacking. please try again",true) })

        bstat = Object.assign({}, busystatRef.current)
        bstat.plusmin = false;
        bstat.busytranapprove = false;
        bstat.busytranattack = false;
        setBusyStat(bstat);
        
      }


    } else if (cmd == "refresh") {

      
      
      
      
      

      const splusmin = await fplusmin.current.getCurrentPlusMin(tid);
      

      


      let ustack = [...stackdat];

      ustack.forEach(el => {
        if (el.nftid == tid) {
          el.splus = splusmin[0];
          el.smin = splusmin[1];
        }
      });

      setStackdat(ustack);

    } else if (cmd == "vote") {
      

      let olook = Object.assign({}, lookat);
      olook.isvotemode = !olook.isvotemode;
      olook.voteid = tid;
      setLookat(olook);


    }

  };




  const handleSaleStuff = async (
    
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    tid: string,
    cmd: string,
    si ?: StDt
  ) => {
    
    let bstat = Object.assign({}, busystatRef.current);

    
    
    setDmodat({ ...dmodatRef.current, show: true, errormsg: "" });

    if (cmd == "salemodal") {

      stackdat.forEach((v) => {
        if(v.nftid == tid) {
          
          

          v.salepriceadj = ethers.BigNumber.from(0)
          v.salepriceset = false
          setSaleitm(v);

          
          setDmodat({ ...dmodatRef.current, show: true, nftid: tid, action: "salemodal", errormsg: "" });

          

        }
      });

    } else if (cmd == "salebuy") {

      try {
        
        if (!bstat.salebuy && saleitm != undefined) {

          bstat.salebuy = true;
          setBusyStat(bstat);
          freshIdRef.current ++
          let fId = freshIdRef.current

          

          
          const buyTran = await fsale.current.buySale(saleitm.nftid,{value: saleitm.saleprice});

          await buyTran.wait();

          bstat.salebuy = false;
          setBusyStat(bstat);

          
          setDmodat({ ...dmodatRef.current, show: false, errormsg: "" });

          if(freshIdRef.current == fId) doChildrenLoadForBase(tid, lookatRef.current.desc, "stacklist", lookatRef.current.curadr);
          

        } else {  }

      } catch (err: any) {
        
        
        
        extractAndShowErr(err as string, "error on buy");

        bstat.salebuy = false;
        setBusyStat(bstat);

        
      }

    } else if (cmd == "saleadjset") {
      
      if(si) {
        
        const newsaleitm = Object.assign({}, si)
        
        if(si.salepriceadj.gt(0)) {
          newsaleitm.salepriceset = true
          newsaleitm.salepriceerr = ""
        } else {
          newsaleitm.salepriceerr = "Sale Price must be greater than 0"
        }
        
        setSaleitm(newsaleitm);
        
      }

    } else if (cmd == "saleadjedit") {
      
      if(si) {
        const newsaleitm = Object.assign({}, si)
        newsaleitm.salepriceset = false
        setSaleitm(newsaleitm);
        
      }
      


    } else if (cmd == "saleset5") {

      
      try {
        
        if (!bstat.saleset5 && saleitm != undefined) {

          

          bstat.saleset5 = true;
          setBusyStat(bstat);

          freshIdRef.current ++
          let fId = freshIdRef.current

          const approveTran = await qnft.current.approve(fdiamond.current.address,ethers.BigNumber.from(saleitm.nftid));
          await approveTran.wait();
          
          
          
          const setSaleTran = await fsale.current.setSale(ethers.BigNumber.from(saleitm.nftid),saleitm.salepriceadj);
          await setSaleTran.wait();
          

          bstat.saleset5 = false;
          setBusyStat(bstat);

          
          setDmodat({ ...dmodatRef.current, show: false, errormsg: "" });

          if(freshIdRef.current == fId) doChildrenLoadForBase(tid, lookatRef.current.desc, "stacklist", lookatRef.current.curadr);

        } else {  }

      } catch (err) {
        
        extractAndShowErr(err as string, "error on saleset5");

        bstat.saleset5 = false;
        setBusyStat(bstat);

        
      }


    } else if (cmd == "cancelsell") {

      
      try {
        
        if (!bstat.cancelsell && saleitm != undefined) {

          

          bstat.cancelsell = true;
          setBusyStat(bstat);

          freshIdRef.current ++
          let fId = freshIdRef.current

          const approveTran = await qnft.current.approve(ethers.utils.getAddress(ethers.constants.AddressZero),ethers.BigNumber.from(saleitm.nftid));
          await approveTran.wait();
          
          
          
          const setSaleTran = await fsale.current.cancelSale(ethers.BigNumber.from(saleitm.nftid));
          await setSaleTran.wait();
          

          bstat.cancelsell = false;
          setBusyStat(bstat);

          
          setDmodat({ ...dmodatRef.current, show: false, errormsg: "" });

          if(freshIdRef.current == fId) doChildrenLoadForBase(tid, lookatRef.current.desc, "stacklist", lookatRef.current.curadr);

        } else {  }

      } catch (err) {
        
        extractAndShowErr(err as string, "error on cancelsell");

        bstat.cancelsell = false;
        setBusyStat(bstat);

        
      }



    



    }

  };


  
  
  
  const handleEditButton = async (
    
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    nftid: string
  ) => {
    e.preventDefault();
    

    let ustack = [...stackdat];
    let uedit = Object.assign({}, qmeta);

    
    let curEdit = ustack.find(element => element.nftid === nftid);
    if (curEdit !== undefined) {

      freshIdRef.current ++

      
      
      
      
      

      
      uedit.nftid = nftid;
      
      uedit.editdata.question = curEdit.idat.question;
      uedit.editdata.rep1 = curEdit.idat.rep1;
      uedit.editdata.rep2 = curEdit.idat.rep2;
      uedit.editdata.rep3 = curEdit.idat.rep3;
      uedit.editdata.a = curEdit.idat.a;

      

      setQmeta(uedit);

      
      setDmodat({ ...dmodatRef.current, show: true, nftid: nftid, action: "edit", errormsg: "" });

      

    }

    

    

    

  };




  const doInputModalValidation = ()=>{

    if(qmeta.editdata.question == editFieldDefaultValues.question
      || qmeta.editdata.rep1 == editFieldDefaultValues.rep1
      || qmeta.editdata.rep2 == editFieldDefaultValues.rep2
      || qmeta.editdata.rep3 == editFieldDefaultValues.rep3
      )
    {

        setDmodat({ ...dmodatRef.current, show: true, valimsg: "Unable to mint with default field values" });
        return false

    } else if(qmeta.editdata.question == ""
        || qmeta.editdata.rep1 == ""
        || qmeta.editdata.rep2 == ""
        || qmeta.editdata.rep3 == ""
        )
      {
  
          setDmodat({ ...dmodatRef.current, show: true, valimsg: "Unable to save with empty field values" });
          return false
    }

    return true

  }

  

  const handleModalButton = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    d: string,
    cmd: string,
    coupid?: ethers.BigNumber
  ) => {
    e.preventDefault();
    

    
    setDmodat({ ...dmodatRef.current, show: true, errormsg: "", valimsg: ""})

    let bstat = Object.assign({}, busystatRef.current);

    if (cmd == "cancel") {
      
      

      freshIdRef.current ++

      handleModalHide()


    } else if (cmd == "mintlinksave" || cmd == "mintlinkcouponsave") {

      

      
      
      
      setDmodat({ ...dmodatRef.current, show: true, errormsg: "", valimsg: "" })

      
      if(doInputModalValidation()) {

        freshIdRef.current ++
        let fId = freshIdRef.current

        try {

          
          if (!bstat.mintweth || !bstat.mintcoupon) {

            if(cmd == "mintlinksave") {

              
              
              
              bstat = Object.assign({}, busystatRef.current);
              bstat.mintweth = true;
              bstat.mintwethbase = false;
              bstat.mintcoupon = false;
              bstat.mintcouponbase = false;
              bstat.busytranapprove = false;
              bstat.busytranmint = true;
              setBusyStat(bstat);

              const mintprice = await fparaset.current.getNftPrice();
              
              
              const tokenid = ethers.BigNumber.from(d);
              let updO = {
                q: qmeta.editdata.question,
                r1: qmeta.editdata.rep1,
                r2: qmeta.editdata.rep2,
                r3: qmeta.editdata.rep3,
                a: qmeta.editdata.a,
              }

              

              
              const updRet = await fmint.current.mintlink(lookatRef.current.curadr, tokenid, 
                              qmeta.editdata.question,
                              qmeta.editdata.rep1,
                              qmeta.editdata.rep2,
                              qmeta.editdata.rep3,
                              qmeta.editdata.a,
              { value: mintprice });

              console.log("mintlink: next wait()")

              await updRet.wait();

              bstat = Object.assign({}, busystatRef.current)
              bstat.busytranmint = false;
              bstat.mintweth = false;
              setBusyStat(bstat);

              


                
            } else if (cmd == "mintlinkcouponsave" && coupid) {

              

              const parenttokenid = ethers.BigNumber.from(d);
              
              

              
              
              
              if(true) {

                

                
                
                
                bstat = Object.assign({}, busystatRef.current);
                
                bstat.mintweth = false;
                bstat.mintwethbase = false;
                
                bstat.mintcoupon = true;
                bstat.mintcouponbase = false;
                
                bstat.busytranapprove = true;
                bstat.busytranmint = false;
                setBusyStat(bstat);

                

                
                const approvedAdr = await cnft.current.getApproved(coupid)

                if(approvedAdr != fdiamond.current.address) {

                  

                  
                  const approveTran = await cnft.current.approve(fdiamond.current.address,coupid)
                  await approveTran.wait()

                  

                }

                
                
                bstat = Object.assign({}, busystatRef.current);
                bstat.busytranapprove = false;
                bstat.busytranmint = true;
                setBusyStat(bstat);


                

                const mintRet = await fmint.current.mintlinkwithdft(lookatRef.current.curadr,parenttokenid,
                                qmeta.editdata.question,
                                qmeta.editdata.rep1,
                                qmeta.editdata.rep2,
                                qmeta.editdata.rep3,
                                
                                qmeta.editdata.a,coupid
                )

                await mintRet.wait()

                

                
                bstat = Object.assign({}, busystatRef.current);
                bstat.busytranmint = false;
                bstat.mintcoupon = false;
                setBusyStat(bstat);

              } else {}

            }

            
            
            
            setDmodat({ ...dmodatRef.current, show: false, errormsg: "" });


            
            
            
            
            
            
            
            
            

            
            
            
            

            
            

            
            



            
            
            
            

            if(freshIdRef.current == fId) doChildrenLoadForBase(d, lookatRef.current.desc, "stacklist", lookatRef.current.curadr);

            

          } else {  }



        } catch (err) {
          

          
          bstat = Object.assign({}, busystatRef.current)
          bstat.busytranapprove = false;
          bstat.busytranmint = false;
          setBusyStat(bstat);
          
          
          

          
          setDmodat({...dmodatRef.current, show: true, errormsg: extractAndShowErr(err as string, "Error minting qNFT.",true), valimsg:"" });

          

        }

      } 

    } else if (cmd == "basesave" || cmd == "mintlinkbasecouponsave") {

      

      
      
      setDmodat({ ...dmodatRef.current, show: true, errormsg: "", valimsg: "" })

      
      if(doInputModalValidation()) {

        freshIdRef.current ++
        let fId = freshIdRef.current

        try {

          if (!bstat.mintweth || !bstat.mintwethbase) {

            if(cmd == "basesave") {

              
              
              
              bstat = Object.assign({}, busystatRef.current);
              bstat.mintweth = false;
              bstat.mintwethbase = true;
              bstat.mintcoupon = false;
              bstat.mintcouponbase = false;
              bstat.busytranapprove = false;
              bstat.busytranmint = true;
              setBusyStat(bstat);

              const mintbaseprice = await fparaset.current.getNftBasePrice();
              
              
              const tokenid = ethers.BigNumber.from(d);
              let updO = {
                q: qmeta.editdata.question,
                r1: qmeta.editdata.rep1,
                r2: qmeta.editdata.rep2,
                r3: qmeta.editdata.rep3,
                a: qmeta.editdata.a,
              }

              

              const updRet = await fmint.current.mintbase(lookatRef.current.curadr, 
                qmeta.editdata.question,
                qmeta.editdata.rep1,
                qmeta.editdata.rep2,
                qmeta.editdata.rep3,
                qmeta.editdata.a,
              { value: mintbaseprice });

              await updRet.wait();

              bstat = Object.assign({}, busystatRef.current)
              bstat.busytranmint = false;
              bstat.mintwethbase = false;
              setBusyStat(bstat);




            } else if(cmd == "mintlinkbasecouponsave") {

              

              const couponidar = await cnft.current.getFirstCanMintBase(lookatRef.current.curadr);

              
              if(couponidar[0]) {

                

                
                
                
                bstat = Object.assign({}, busystatRef.current);
                bstat.mintweth = false;
                bstat.mintwethbase = false;
                bstat.mintcoupon = false;
                bstat.mintcouponbase = true;
                bstat.busytranapprove = true;
                bstat.busytranmint = false;
                setBusyStat(bstat);

                

                const approvedAdr = await cnft.current.getApproved(couponidar[1])

                if(approvedAdr != fdiamond.current.address) {

                  

                  const approveTran = await cnft.current.approve(fdiamond.current.address,couponidar[1])
                  await approveTran.wait()

                  

                }

                
                
                bstat = Object.assign({}, busystatRef.current);
                bstat.busytranapprove = false;
                bstat.busytranmint = true;
                setBusyStat(bstat);

                

                const mintRet = await fmint.current.mintbasewithdft(lookatRef.current.curadr,
                                qmeta.editdata.question,
                                qmeta.editdata.rep1,
                                qmeta.editdata.rep2,
                                qmeta.editdata.rep3,
                                qmeta.editdata.a,couponidar[1]
                )

                await mintRet.wait()

                

                
                bstat = Object.assign({}, busystatRef.current);
                bstat.busytranmint = false;
                bstat.mintcouponbase = false;
                setBusyStat(bstat);

              } else {}

            }

            
            
            setDmodat({ ...dmodatRef.current, show: false, errormsg: "" });

            if(freshIdRef.current == fId) doLoadBase(true)
            

          } else {  }



        } catch (err) {
          
          
          
          bstat = Object.assign({}, busystatRef.current)
          bstat.busytranapprove = false;
          bstat.busytranmint = false;
          setBusyStat(bstat);

          
          
          setDmodat({...dmodatRef.current, show: true, errormsg: "Error minting qNFT.", valimsg:"" })
          
        
        }

      } 


    } else if (cmd == "editsave") {
      

      if(doInputModalValidation()) {

        try {

          

          if (!bstat.mintweth) {

            bstat.mintweth = true;
            setBusyStat(bstat);

            freshIdRef.current ++
            let fId = freshIdRef.current

            

            const tokenid = ethers.BigNumber.from(d);
            
            
            
            
            
            
            

            const updRet = await fmint.current.setQuestions(tokenid, qmeta.editdata.question,
              qmeta.editdata.rep1,
              qmeta.editdata.rep2,
              qmeta.editdata.rep3,
              qmeta.editdata.a
            );
            
            
            await updRet.wait();

            
            

            bstat.mintweth = false;
            setBusyStat(bstat);

            
            setDmodat({ ...dmodatRef.current, show: false, errormsg: "" });

            
            
            
            
            
            
            
            
            
            

            
            if(freshIdRef.current == fId) doChildrenLoadForUser(lookatRef.current.curadr, 0);

            

          } else {  }

        } catch (err) {
          
          
          bstat.mintweth = false;
          setBusyStat(bstat);

          
          setDmodat({...dmodatRef.current, show: true, errormsg: "Error editing qNFT.", valimsg:"" });
        }

      } 

    } 

  }; 




  const handleNavigate = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    cmd: string
  ) => {
    e.preventDefault();

    

    if (cmd == "base") {
      setDmodat({ ...dmodatRef.current, show: false, errormsg: ""})
      freshIdRef.current ++
      doLoadBase(false);


    } else if (cmd == "stack") {
      setDmodat({ ...dmodatRef.current, show: false, errormsg: ""})
      freshIdRef.current ++
      doChildrenLoadForBase(lookatRef.current.id, lookatRef.current.desc, "stacklist", lookatRef.current.curadr);

    } else if (cmd == "mynfts") {
      

      setDmodat({ ...dmodatRef.current, show: false, errormsg: ""})
      freshIdRef.current ++
      doChildrenLoadForUser(lookatRef.current.curadr, 0);


    } else if (cmd == "addnft") {
      
      
      setDmodat({ ...dmodatRef.current, show: false, errormsg: ""})
      handletopStackHandler("buynft", stackdat[0].nftid)
    
    } else if (cmd == "unhidehelp") {
      
      
      setDmodat({ ...dmodatRef.current, show: false, errormsg: ""})
      
      
      

      setLookat({ ...lookatRef.current, showhelp: true})
    


      
    } else if (cmd == "buydftmodal") {

      freshIdRef.current ++

      setDmodat({ ...dmodatRef.current, show: true, errormsg: "", valimsg: "", action: "buydftmodal" })

    
    } else if (cmd == "showaccount") {

      freshIdRef.current ++

      setDmodat({ ...dmodatRef.current, show: true, errormsg: "", valimsg: "", action: "showaccount" })

      updateUIpointsallowance()

      setAccmodalstate({...accmodalstateRef.current, showme:true, buywhat: "skk", errormsg:""
                                ,traninitiated: false, tranapproved: false, tranminted: false, campid: 0})

      
    }

  }
  const handleBuyNftModalButton = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    cmd: string
  ) => {
    e.preventDefault();
    

    
    setDmodat({ ...dmodatRef.current, show: true, errormsg: "" });

    let bstat = Object.assign({}, busystatRef.current);
    bstat.busytranmint = true;
    setBusyStat(bstat);


    try {

      

      const mintprice = await fparaset.current.getNftPrice();
      

      const buyRet = await fcoup.current.BuyRegularCoupon(lookatRef.current.curadr,{ value: mintprice })

      await buyRet.wait()

      
      bstat = Object.assign({}, busystatRef.current)
      bstat.busytranmint = false;
      setBusyStat(bstat);

      setDmodat({ ...dmodatRef.current, show: false, errormsg: "" });

      refreshAccountComp(currentaddress)

      updateUIpointsallowance()

      

    } catch (err) {
      

      setDmodat({ ...dmodatRef.current, show: true, errormsg: extractAndShowErr(err as string, "Error buying coupon",true) });

      

      
      bstat = Object.assign({}, busystatRef.current)
      bstat.busytranmint = false;
      setBusyStat(bstat);

    }


  }





  const handleTabNavigate = async (
    
    cmd: string | null,
    sev: React.SyntheticEvent<unknown, Event>
  ) => {
    sev.preventDefault();

    

    
    
    
    
    
    
    

    if (cmd == "base") {
      setDmodat({ ...dmodatRef.current, show: false, errormsg: ""})
      freshIdRef.current ++
      doLoadBase(false);


    } else if (cmd == "stack") {
      setDmodat({ ...dmodatRef.current, show: false, errormsg: ""})
      freshIdRef.current ++
      doChildrenLoadForBase(lookatRef.current.id, lookatRef.current.desc, "stacklist", lookatRef.current.curadr);

    } else if (cmd == "mynfts") {
      

      setDmodat({ ...dmodatRef.current, show: false, errormsg: ""})
      freshIdRef.current ++
      doChildrenLoadForUser(lookatRef.current.curadr, 0);

    } else if (cmd == "addnft") {
      

      setDmodat({ ...dmodatRef.current, show: false, errormsg: ""})
      
      handletopStackHandler("buynft", stackdat[0].nftid)
    
    } else if (cmd == "unhidehelp") {
      
      
      
      
      

      setLookat({ ...lookatRef.current, showhelp: true})
    }


    

  }


  const handleAccountModal = async (
    
    cmd: string,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    tokens: ethers.BigNumber
  ) => {
    
    if(e) e.preventDefault();



    if (cmd == "recharge") {
      
        
        
        

        
          
        try {

          setAccmodalstate({...accmodalstateRef.current, errormsg:"" ,traninitiated: true, tranapproved: false, tranminted: false})
          
          let cstAllow = await skktoken.current.allowance(currentaddress,diamondAbi.address);
      
          if(tokens.gt(cstAllow)) {          
            await (await skktoken.current.approve(diamondAbi.address,tokens)).wait()
          }

          setAccmodalstate({...accmodalstateRef.current, errormsg:"", tranapproved: true, tranminted: false})

          const rechargeTran = await fstackflation.current.rechargePoints(lookatRef.current.curadr,tokens)
          await rechargeTran.wait()

          setAccmodalstate({...accmodalstateRef.current, errormsg:"", traninitiated: false  ,tranapproved: true, tranminted: true})

          

          updateUIpointsallowance();
          refreshAccountComp(currentaddress);

        } catch(err) {

          setAccmodalstate({...accmodalstateRef.current,  traninitiated: false, errormsg: extractAndShowErr(err as string, "Error recharging points", true)})

        }


    } else if (cmd == "refreshmaxallowed") {

      
      setBusyStat({ ...busystatRef.current, accsumrefresh: true})

      updateUIpointsallowance()
      await refreshAccountComp(currentaddress);

      setBusyStat({ ...busystatRef.current, accsumrefresh: false})


    } else if (cmd == "mynfts") {
      

      setAccmodalstate({...accmodalstateRef.current, showme:false})

      setDmodat({ ...dmodatRef.current, show: false, errormsg: ""})
      freshIdRef.current ++
      doChildrenLoadForUser(lookatRef.current.curadr, 0);


    } else if (cmd == "hidemodal") {

      setDmodat({ ...dmodatRef.current, show: false, errormsg: "", valimsg: "", action: "showaccount" })

      setAccmodalstate({...accmodalstateRef.current, showme:false, buywhat: "skk", errormsg:""
                                ,traninitiated: false, tranapproved: false, tranminted: false, campid: 0})

    }

  }






  const loadMoreMyNFT  = async () => {
    setDmodat({ ...dmodatRef.current, show: false, errormsg: ""})
    freshIdRef.current ++
    doChildrenLoadForUser(lookatRef.current.curadr, lookatRef.current.mynftlastidx);
  }
  const loadMoreBase  = async () => {
    setDmodat({ ...dmodatRef.current, show: false, errormsg: ""})
    freshIdRef.current ++

    if(filterbarRef.current.isgraph) {
      doLoadBaseQL(lookatRef.current.baselastidx,true)
    } else {
      doLoadBaseFromChain(lookatRef.current.baselastidx);
    }
    
  }
  


  function helpIsRegistered(){

    
    if( (accbal && accbal.nftbncount && ethers.BigNumber.from(accbal.nftbncount).gt(0) )
        || accbal && accbal.cnftcnt && accbal.cnftcnt.gt(0) ) {
          return true
    } else {
      return false
    }
  }


  
  
  
  
  
  
  
  
  
  
  
  
  
  

  
  function showModalOrNot() {

    
    
    
    
    

    
    
  
  
  
  
  
  

  
  
  
  
  
  
  

    
    

    

    if (dmodatRef.current.show) {

      
      if(dmodatRef.current.action == "plusminedit") {

          return <PmModal editpacket={pmedit}  splush={handleSplus} updateh={handlePmModalFieldUpdates}
                  nftid={dmodatRef.current.nftid} action={dmodatRef.current.action} hideh={handleModalHide} 
                  bstat={busystat} errormsg={dmodatRef.current.errormsg} />; 


      } else if(dmodatRef.current.action == "salemodal" && saleitm != undefined) {
        
        
          return <SaleModal saleitm={saleitm}  saleh={handleSaleStuff} updateh={handleSaleModalOnChange}
                  nftid={dmodatRef.current.nftid} action={dmodatRef.current.action} hideh={handleModalHide} bstat={busystat} />; 
        

      } else if(dmodatRef.current.action == "buydftmodal") {

          return <BuyDftModal accbal={accbal} 
                              
                              
                              action={dmodatRef.current.action} 
                              modalh={handleBuyNftModalButton}
                              hideh={handleModalHide} bstat={busystat}
                              errormsg={dmodatRef.current.errormsg} />

      } else if(dmodatRef.current.action == "showaccount" && accbal) {

          return <AccountModal errormsg=""
                              modalh={handleAccountModal}
                              mstate={accmodalstateRef.current}
                              uapoints={pointsRef.current}
                              uacc={accbal} 
                              bstat={busystatRef.current}/>

      } else if(dmodatRef.current.action == "showaccount" && !accbal) {
          


      } else if(dmodatRef.current.action == "burnmodal") {

        if(dmodatRef.current.burndetail) {

        
            return <BurnModal burndetails={dmodatRef.current.burndetail} 
                            action={dmodatRef.current.action} 
                            burnh={handleBurn}
                            errormsg={dmodatRef.current.errormsg}
                            hideh={handleModalHide} bstat={busystat} />

        } else {
          
          return ""
        }
      

      
      } else if(dmodatRef.current.action == "filter-open-search-options"){


          return <CBaseFilterOptionsBS 
                    filteritm={filterbarRef.current} filterh={handleFilter} hideh={handleModalHide} bstat={null}
                  />

      } else if(dmodatRef.current.action == "new" || dmodatRef.current.action == "edit"){

          
          
          
          
          

          return <CModal qdata={qmeta.editdata} modalh={handleModalButton} updateh={handleModalFieldUpdates}
                  nftid={dmodatRef.current.nftid} action={dmodatRef.current.action} hideh={handleModalHide} bstat={busystat} 
                  nftdesc={lookatRef.current.desc} abal={accbal}
                  errormsg={dmodatRef.current.errormsg} valimsg={dmodatRef.current.valimsg} couplist={couponlist}/>;

      } else {

        return "";

      }
    } else {
          return "";
    }
  }
  
  
  
  
  function showError() {

    if (dmodatRef.current.show && dmodatRef.current.action == "error") {

      return <Alert variant="danger" dismissible onClose={() => setDmodat({ show: false, nftid: "0", action: "error", errormsg: "" })} >
        {dmodatRef.current.errormsg}
      </Alert>


    } else {
      return "";
    }
  }




  function getWholeAppClass(addcls?: string) {
    let sc = lookat.devinfo ? "csapp showdevinfo" : "csapp hidedevinfo";
    sc += lookat.isvotemode ? " showvotemode" : "";
    sc += addcls ? " " + addcls : ""
    return sc;
  }


  function getStackListWrapperClass() {

    

    let gc = "cstack px-2";
    

    gc += lookat.view == "baselist" ? " ad-baselist" : "";
    gc += lookat.view == "nftlist" ? " ad-nftlist" : "";
    gc += lookat.view == "stacklist" ? " ad-stacklist" : "";

    
    if ( (accbal && accbal.nftbncount && ethers.BigNumber.from(accbal.nftbncount).gt(0))
        || (accbal && accbal.cnftcnt && accbal.cnftcnt.gt(0))) {
            gc += " isnftowner";
    } else if (lookatRef.current.curadr == "") {
            gc += " notloggedin";
    } else {
            gc += " notnftowner";
    }

    if (points.allanswered) {
      gc += " allanswered";
    }

    return gc;
  }


  function getStackItemClass(itm: StDt, itmtype: string) {

    let sc = lookat.devinfo ? "showdevinfo stackitm" : "hidedevinfo stackitm";

    sc += itm.isbase ? " nftisbase" : "";

    sc += itm.isowner ? " ismine" : "";

    if (itmtype != "nft") {

      if (itm.answerst == AnStatus.AnswerOK) {
        sc += " ansok";
      } else if (itm.answerst == AnStatus.AnswerWrong) {
        sc += " answrong";

        
      } else if (itm.isfirstviz) {
        sc += " ansfirstviz";

        
        
        
        
        

      } else if (itm.answerst == AnStatus.AnswerNone) {
        sc += " ansnone";
      }

      sc += lookat.vmode == "detail" ? " showlev2" : " showlev1";

    }

    return sc;
  }

  



  
  function createTopOfStackBut(btext: string) {

    
    if (busystatRef.current.recalc) {

      return <Button
        variant="primary"
        size="sm"
        onClick={(e) => topStackHandler(e, "recalc", stackdat[0].nftid)}
        disabled
      >
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        <span className="visually-hidden">Loading...</span>
        {btext}
      </Button>

      
    } else {

      return <Button
        variant="primary"
        size="sm"
        onClick={(e) => topStackHandler(e, "recalc", stackdat[0].nftid)}
      >
        {btext}
      </Button>
    }

  }



  function hideHelp() {
    
    
    
    setLookat({ ...lookatRef.current, showhelp: false})
  }

  
  function topOfStack(checkfor: string) {

    
    
    if (checkfor == "recalc") {

      if (stackdat.length > 0) {

        
        if (points.allanswered && stackdat[0].stale && lookat.view == "stacklist" 
              && ( (accbal && accbal.nftbncount && ethers.BigNumber.from(accbal.nftbncount).gt(0))
                  || (accbal && accbal.cnftcnt && accbal.cnftcnt.gt(0)) )
              && points.claimable == 0) {

          return <Container key={checkfor}><Alert variant="primary" >
            <Row className="xx">
              <Col md="auto">help to recalculate this stack and earn {epochdisp.recalcreward ? fToken(epochdisp.recalcreward,1) : "..calculating.."} SKK.</Col>
              <Col className="ms-auto"/>
              <Col md="auto">{createTopOfStackBut("refresh recalc")}</Col>
            </Row>
          </Alert></Container>;

        }

      }
    

    
    } else if (checkfor == "basefilter" && lookat.view == "baselist" && lookat.curadr != "") {

      return <Container fluid="xs" key={checkfor}><CBaseFilterBS 
                filteritm={filterbarRef.current} filterh={handleFilter} hideh={null} bstat={null}
              /></Container>;



    
    } else if (checkfor == "helpbox" && lookat.view == "stacklist") {

      if (stackdat.length > 0) {

        
        

        let pnota = 0;
        if (points.lowestspnotanswer != undefined) {
          pnota = points.lowestspnotanswer;
        }

        let ra = [];

        if(lookatRef.current.showhelp) {

          
          
          
          
          if(helpIsRegistered()) {

            
            if (points.claimable > 0) {

              
              
              
              
              
              

              ra[0] = <CHelpBox keystring={checkfor} hideh={hideHelp} 
                        helptext="Step 4: Use the 'claim' button in the app bar to claim SKK tokens for points. Claiming tokens will save your answers on-chain." /> 

            } else if (points.ownedquestions > 0) {

              if (points.userhaveclaimables) {

                
                
                
                
                
                ra[0] = <CHelpBox keystring={checkfor} hideh={hideHelp} 
                  helptext="Step 7: Use the 'claims' tab to claim rewards from your nft questions." /> 
              
              } else {

                
                
                
                
                
                ra[0] = <CHelpBox keystring={checkfor} hideh={hideHelp} 
                  helptext="Step 6: Use the 'attack' button to increase or decrease the stackposition of a question. Lower stack positions get higher rewards." /> 

              }

            } else if (points.answeredquestions > 0) {

              
              
              
              
              
              ra[0] = <CHelpBox keystring={checkfor} hideh={hideHelp} 
                helptext="Step 5: Use the 'Add' button to mint a qNFT question. Your question will be added to this stack." /> 

            
            
            
            
            
            

            } else {

            
              
              
              
              
              
              ra[0] = <CHelpBox keystring={checkfor} hideh={hideHelp} 
                helptext="Step 3: Starting at the base (green box) answer as many questions as the game allows. Use the 'choose' button to select your answer. You get points for each answer." /> 
            }

            
            
            
          } else {

            
            
            
            
            
            ra[0] = <CHelpBox keystring={checkfor} hideh={hideHelp} 
              helptext="Step 2: Use the 'Mint cNFT' button to mint a coupon nft. Owning a cNFT allows you to answer questoins and earn SKK." /> 

            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            

          }
        
        }

        return ra;


      }

      
    } else if (checkfor == "helpbox" && lookat.view == "baselist") {

      if(lookatRef.current.showhelp) {
        
        
        
        
        
        return <CHelpBox keystring={checkfor} hideh={hideHelp} 
          helptext="Step 1: Select a stack of questions." /> 
      }
    }

  }


  function thisOrThat(itmar: StDt[]) {

    

    
    
    
    

    if (lookat.view == "nftlist") {
      return <>
        {itmar.map((itm) => {
          if (itm.isedit) { return "" } else {
            
            
            
            
            
            
            

            return <CitmStackListBS key={itm.ikey} itm={itm}
              qandacfg={{ showquestions: true, showpmbar: false, showchoose: true, showedit: true, showpoints: false, showusebase: false, showclaims: true }}
              lookat={lookat} answerh={handleAnswerBut} splush={handleSplus} saleh={handleSaleStuff} totalitems={stackdat.length} epoch={epochdisp}
              claimh={handleClaims} nfth={handleNft} edith={handleEditButton} bstat={busystat} disablechoose={true} 
              burnh={handleBurn} defitmviz={DefItm.DefMyNfts} isregistered={helpIsRegistered()} switchuistate={handleUIstate} />

          }
        })}
      </>

    } else if (lookat.view == "baselist") {

      
      
      
      
      
      
      
      
      

      
      return <Row className="justify-content-center">
        {qlbaselist.map((itm) => {
          
            return <CitmBaseListBSql key={itm.ikey} itm={itm}
              lookat={lookat} totalitems={stackdat.length} epoch={epochdisp}
              nfth={handleNft} bstat={busystat} gqlactive={filterbarRef.current.isgraph}
              filtershowattacks={filterbarRef.current.showattacks} 
              
              />
 
        })}
      </Row>

    } else if (lookat.view == "stacklist") {

      
      
      

      
      let cntUnanswered = itmar.reduce((prev,cur) => {
        return prev + (cur != undefined ? (cur.answerst == AnStatus.AnswerNone ? 1 : 0) : 0);
      },0)

      let cntTot = itmar.length;

      
      
      

      return <>
        {itmar.map((itm,iidx) => {
          if (itm.isedit) { return "" } else {
            
            
            let disablechoosebuts = false;
            let defShow: DefItm = DefItm.DefNoShow;

            
            if (itm.answerst != AnStatus.AnswerNone) {
              
              defShow =  DefItm.DefRewards;
              disablechoosebuts = true;

              

              
            } else if (itm.isfirstviz) {

              defShow = DefItm.DefQuestions;

              
              if ( (accbal && accbal.nftbncount && ethers.BigNumber.from(accbal.nftbncount).gt(0))
                  || (accbal && accbal.cnftcnt && accbal.cnftcnt.gt(0)) ) {

                if (!points.allanswered && points.claimable < points.allow) {

                  

                } else {
                  
                  
                  disablechoosebuts = true;
                }

                

                
              } else {

                if (!points.allanswered && points.claimable < points.pointsmaxpertime) {

                  

                } else {
                  
                  disablechoosebuts = true;
                }

                

              }

            } else if(iidx > (cntTot - (cntTot - cntUnanswered) - 5)) {
              defShow = DefItm.DefHintAt;

              

            } else {
              
            }


            if (defShow != DefItm.DefNoShow) {

              return <CitmStackListBS key={itm.ikey} itm={itm}
                qandacfg={{ showquestions: true, showpmbar: true, showchoose: true, showedit: false, showpoints: true, showusebase: false, showclaims: true }}
                lookat={lookat} answerh={handleAnswerBut} splush={handleSplus} saleh={handleSaleStuff} totalitems={stackdat.length} epoch={epochdisp}
                claimh={handleClaims} nfth={handleNft} edith={handleEditButton} bstat={busystat} disablechoose={disablechoosebuts} 
                burnh={handleBurn} defitmviz={defShow} isregistered={helpIsRegistered() } switchuistate={handleUIstate} />

            } else { return "" }
          }
        })}

      </>

    }


  }

  

  return (

    <Container fluid="lg" className="h-100">

      <div className={getWholeAppClass("h-100 d-flex flex-column")}>

        <div className="ad-topbar">
          
          {process.env.REACT_APP_ADMIN && adminInpRef.current.dispdebugAacc && 
            <BalanceBox abal={bboxaccounts} buth={handleBboxBut} /> 
          }
          
          <CrypSysInfo epoche={epochdisp} admininputval={adminInpRef.current.tval} adminupdatesh={handleAdminUpdates}
            adminavail={adminInpRef.current.adminavail}
            lookat={lookatRef.current} handlenav={handleNavigate} 
            dispdebugAacc={adminInpRef.current.dispdebugAacc} sysinfoh={handleCrypSysInfo} abal={accbal} accounth={handleAccountBox} 
          />

        </div>

        

        <AppBar points={points} abal={accbal} lookat={lookat}
          accounth={handleAccountBox} bstat={busystat}
          handlenav={handleNavigate} handletabnav={handleTabNavigate} arrowactive={accmodalstateRef.current.showme} />


        {showError()}

        <Container  className={getStackListWrapperClass()}>
          {topOfStack("recalc")}

          {topOfStack("helpbox")}

          {topOfStack("basefilter")} 

          {lookatRef.current.curadr == "" &&
            <>
              <div className="ad-hometalk m-5 text-center">
              <h3>Stack Attack is an Ask-to-Earn NFT game.</h3>
              </div>
              <div className="ad-hometalk m-5 text-center">
              <h3>Answer questions to earn points.</h3>
              </div>
              <div className="ad-hometalk m-5 text-center">
              <h3>Ask questions to earn SKK.</h3>
              </div>
              <div className="ad-hometalk m-5 text-center">
              <h3>Ask-to-Earn.</h3>
              </div>

              <img className="ad-apphomeimg" src={buynftback}></img>
            </>
          }

          {lookatRef.current.curadr != "" &&

            <>

              { busystatRef.current.loading &&
                <Container className="d-flex justify-content-center p-5">
                  <Spinner animation="border" />
                </Container>
              }

              { !busystatRef.current.loading && 

                <>

                  {thisOrThat(stackdat)}

                  
                      {lookatRef.current.view == "nftlist" && !lookatRef.current.mynftallrecs &&
                        <Container className="d-flex justify-content-center"><Button  variant="outline-secondary" size="sm" onClick={loadMoreMyNFT}>Load More</Button></Container>
                      }
                  
                    {}
                      <>
                        {lookatRef.current.view == "baselist" && !lookatRef.current.baseallrecs &&
                          <Container className="d-flex justify-content-center"><Button variant="outline-secondary" size="sm" onClick={loadMoreBase}>Load More</Button></Container>
                        }
                      </>
                    {}

                </>
              }

            </>

          }

        </Container>


        <div>
          {showModalOrNot()}
        </div>


        

        <div className="d-flex justify-content-center pb-3 mt-auto ad-AppFooter">
            <span>Copyright © 2023 Stack Attack. All rights reserved.</span>
        </div>



      </div>

    </Container>

  );


};


