import React, { useState } from "react";

import { Button, Spinner } from "react-bootstrap";

interface Props {
  butstring: string;
  spinstat: boolean;
  clickhandler: any;
  butclass?: string;
  butdisabled?: boolean;
  butsize?: string;
}


export const CSpinnerButton: React.FC<Props> = (props) => {


  return (

    <>
      { props.spinstat ? 

        <Button
          
          variant={props.butclass || "primary"} size={props.butsize == "lg" ? "lg" : props.butsize == "sm" ? "sm" : undefined}
          onClick={props.clickhandler}
          disabled
          className="text-nowrap"
        >
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <span className="visually-hidden">Loading...</span>
          {props.butstring}
        </Button>

      :

        <Button
          
          variant={props.butclass || "primary"} size={props.butsize == "lg" ? "lg" : props.butsize == "sm" ? "sm" : undefined}
          onClick={props.clickhandler}
          disabled={props.butdisabled != undefined ? props.butdisabled : false }
        >
          {props.butstring}
        </Button>

      }

    </>

  );

};
