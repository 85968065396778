import React from "react"

import { BusyStat, StDtItm, PMeditpacket, AccBal} from './CSInterface'

import {Button, Container, Row, Col, ProgressBar, Modal, Form, Spinner, Alert} from 'react-bootstrap'

import { ethers } from "ethers";
import { CSpinnerButton } from "./CSpinnerButton";
import { fToken } from "./CSCommon";



interface Props {
  action: string;
  errormsg?: string;
  modalh?: any;
  hideh: any;
  bstat: BusyStat;
  accbal?: AccBal;
}

export const BuyDftModal: React.FC<Props> = (props) => {

  
  return (
    <>
      <Modal show={props.action != "error"} onHide={props.hideh}>
        <Modal.Header closeButton>
          <Modal.Title>Mint cNFT Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <Container>
          
            <Row>
              <Col><p>Mint a cNFT coupon to get started. A cNFT is the equivalent of a game pass and allow you to claim SKK point rewards for each question you answer. Your cNFT is convertable to a qNFT when you create your first question.</p></Col>
            </Row>

            <Alert show variant="success">

              {}
              <p>Your balance is { fToken(props.accbal && props.accbal.etherval ? props.accbal.etherval : ethers.BigNumber.from("0") ,6)} FTM.
              </p>
                
              <CSpinnerButton spinstat={props.bstat.busytranmint ? props.bstat.busytranmint : false } 
                  butstring={"Mint " + fToken(props.accbal && props.accbal.nftbnprice ? props.accbal.nftbnprice : ethers.BigNumber.from(0),4) + " " + (props.accbal && props.accbal.ethername ? props.accbal.ethername : "")}
                  clickhandler={(e: any)=>props.modalh(e,"buydftxxx")} 
                  butclass="outline-success"/>

              {}

              
                  
            </Alert>
            
            { props.errormsg && props.errormsg != "" &&
            
              <Alert variant="danger" className="mt-1 mb-0">{props.errormsg}</Alert>
              
            }
            
            
          </Container>
        </Modal.Body>
        <Modal.Footer>

          <Container>
            <div className="d-flex justify-content-between">

              <div className="x">
                <Button variant="secondary" onClick={(e)=>props.hideh(e,"cancel")}>Close</Button>
              </div>
          
            </div>
          </Container>

        </Modal.Footer>
      </Modal>
    </>
  )

}
