import React, { useState } from "react"

import { ethers } from "ethers";

import { AccBal, BusyStat, PointsDetail, accmodalstate, useracc } from './CSInterface'
import { truncateEthAddress } from './CSCommon'

import { Button, Container, Row, Col, ProgressBar, Modal, Form, Spinner, Alert } from 'react-bootstrap'


import { CSpinnerButton } from "./CSpinnerButton";
import { fToken } from "./CSCommon";


import { MdCheck } from "react-icons/md";


interface Props {
    errormsg: string;
    modalh: any;
    mstate: accmodalstate;
    uapoints: PointsDetail;
    uacc: AccBal;
    bstat: BusyStat;
}

export const AccountModal: React.FC<Props> = (props) => {

    const [rechargeamount, setRechargeamount] = useState(ethers.BigNumber.from(0))

    const [localwarn, setLocalwarn] = useState("")

    const setRecharge = (perc: number) => {

        const reAmt = props.uapoints.maxallowedrecharge.mul(perc).div(100)

        
        

        if(props.uacc.tokenval && reAmt.gt(props.uacc.tokenval)) {
            
            setLocalwarn("Recharge amount in excess of skk balance")
        } else {
            setLocalwarn("")
        }

        setRechargeamount(reAmt)
    }

    const calcStyle = () => {

        
        let perc = 0;
        if (props.uapoints.hundredgauge != undefined) {
            perc = props.uapoints.hundredgauge;
        }


        
        
        if (perc > 100) { perc = 100; };

        
        return perc;

    }

    return (
        <>
            <Modal show={props.mstate.showme} onHide={() => props.modalh("hidemodal")}>
                <Modal.Header closeButton>
                    <Modal.Title>Account Summary</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Container>

                        <Row>
                            <Col><p>Account balance for {props.uacc.currentadr ? truncateEthAddress(props.uacc.currentadr) : "n/a"}</p></Col>
                        </Row>


                        

                        
                        <Row>
                            <Col>SKK</Col>
                            <Col>{fToken(props.uacc.tokenval, 2)}</Col>
                        </Row>
                        <Row>
                            <Col>FTM</Col>
                            <Col>{fToken(props.uacc.etherval, 2)}</Col>
                        </Row>
                        <Row>
                            <Col>cNFT (regular + boost)</Col>
                            <Col>{props.uacc.cnftcnt.sub(props.uacc.cnftbasecnt).toNumber()}</Col>
                        </Row>
                        <Row>
                            <Col>cNFT (base)</Col>
                            <Col>{props.uacc.cnftbasecnt.toNumber()}</Col>
                        </Row>
                        <Row>
                            <Col>qNFT</Col>
                            <Col>{props.uacc.nftbncount ? props.uacc.nftbncount.toString() : "0"}</Col>
                        </Row>

                        <div className="d-flex justify-content-around mt-2">
                            <div className="x">
                                <Button variant="secondary" onClick={(e) => props.modalh("mynfts", e)}>show my nfts</Button>
                            </div>
                        </div>

                        <hr />

                        <Row>
                            <Col>points alowance</Col>
                            <Col><ProgressBar onClick={(e) => props.modalh("refreshmaxallowed", e)}
                                className="xcol-6 xmt-3 xflex-grow-1"
                                now={calcStyle()}
                                label={`${calcStyle()}%`} />
                            </Col>
                        </Row>

                        {}
                        <Row>
                            <Col>max points claimable</Col>
                            <Col>{props.uapoints.allow}</Col>
                        </Row>

                        

                        {}


                        <Row>
                            <Col>max recharge SKK</Col>
                            <Col>{ethers.utils.formatEther(props.uapoints.maxallowedrecharge)}</Col>
                        </Row>

                        <Row>
                            <Col>recharge amount</Col>
                            <Col>{ethers.utils.formatEther(rechargeamount)}</Col>
                        </Row>


                        <div className="d-flex justify-content-around mt-2">
                            
                            <div className="x">
                                <Button variant="secondary" onClick={(e) => setRecharge(20)}>20%</Button>
                            </div>

                            <div className="x">
                                <Button variant="secondary" onClick={(e) => setRecharge(50)}>50%</Button>
                            </div>

                            <div className="x">
                                <Button variant="secondary" onClick={(e) => setRecharge(80)}>80%</Button>
                            </div>

                            <div className="x">
                                <Button variant="secondary" onClick={(e) => setRecharge(100)}>Max</Button>
                            </div>
                        </div>

                        <hr />

                        {}

                        {}

                        {localwarn != "" &&
                            <Alert variant="warning" className="mt-1 mb-0">{localwarn}</Alert>
                        }

                        {props.mstate.errormsg != "" ?
                            <Alert variant="danger" className="mt-1 mb-0">{props.mstate.errormsg}</Alert>
                            :
                            <div>
                                {props.mstate.traninitiated && !props.mstate.tranapproved && !props.mstate.tranminted &&
                                    <div>
                                        <Spinner animation="border" size="sm"></Spinner><span className="ms-1">approving</span>
                                    </div>
                                }
                                {props.mstate.traninitiated && props.mstate.tranapproved && !props.mstate.tranminted &&
                                    <div><div><MdCheck />approved</div>
                                        <Spinner animation="border" size="sm"></Spinner><span className="ms-1">recharging</span>
                                    </div>
                                }

                                {}
                                {props.mstate.tranapproved && props.mstate.tranminted &&
                                    <div>
                                        <div><MdCheck />approved</div>
                                        <div><MdCheck />completed</div>
                                    </div>
                                }
                            </div>
                        }


                    </Container>
                </Modal.Body>
                <Modal.Footer>

                    <Container>
                        <div className="d-flex justify-content-between">

                            <div className="x">
                                <Button variant="secondary" onClick={(e) => props.modalh("hidemodal", e)}>Close</Button>
                            </div>

                            <div className="x">
                                {}
                                <CSpinnerButton spinstat={props.bstat.accsumrefresh ? props.bstat.accsumrefresh : false}
                                    butstring={"Refresh"}
                                    clickhandler={(e: any) => {
                                        props.modalh("refreshmaxallowed", e)
                                        setRecharge(0)
                                    }}
                                    butclass="primary" butsize="md"
                                />
                            </div>

                            <div className="x">
                                <CSpinnerButton spinstat={props.mstate.traninitiated && props.errormsg == ""}
                                    butstring={"Recharge"}
                                    clickhandler={(e: any) => {
                                        props.modalh("recharge", e, ethers.BigNumber.from(rechargeamount))
                                        setRecharge(0)
                                    }}
                                    butclass="primary" butsize="md"
                                />
                            </div>
                        </div>
                    </Container>

                </Modal.Footer>
            </Modal>
        </>
    )

}
