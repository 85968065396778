
import React from "react";

import { FilterItm} from './CSInterface';

import {Button, InputGroup, Container, Row, Col, ProgressBar, Modal, Form, Spinner} from 'react-bootstrap';

import { MdSearch } from "@react-icons/all-files/md/MdSearch";
import { MdViewList } from "@react-icons/all-files/md/MdViewList";
import { MdRefresh } from "@react-icons/all-files/md/MdRefresh";

interface Props {
  filteritm: FilterItm;
  filterh: any;
  hideh: any;
  bstat: any;
}


export const CBaseFilterBS: React.FC<Props> = (props) => {

  

  return (
    <>
      
          <Form>

            <Row>
              <Col xs={6} className="xd-flex xflex-grow-1">

                <InputGroup>
                  <Form.Control
                    type="input"
                    placeholder="search"
                    disabled={!props.filteritm.isgraph ? true : false}
                    
                    value={props.filteritm.search}
                    onChange={(e) => {
                        e.preventDefault();
                        props.filterh("filter-changesearchterm", e.target.value,false)
                      }
                    }
                    onKeyDown={(e) => {
                      
                      
                      if(e.key == "Enter") {
                        e.preventDefault();
                        props.filterh("filter-dosearch", "enter",false)}
                      }
                    } 
                  />

                  <Button variant="outline-secondary"
                    onClick={(e) => {
                      props.filterh("filter-dosearch", "enter",false)}
                    }
                  >
                    <MdSearch />
                  </Button>
                  <Button variant="outline-secondary"
                    onClick={(e) => {
                      props.filterh("filter-clearsearch", "enter",false)}
                    }
                  >
                    clr
                  </Button>

                </InputGroup>


                
              </Col>

              {}

              <Col className="ms-auto" />
                
              <Col>
                <Button variant="outline-secondary" 
                    onClick={(e) => { 
                      props.filterh("filter-open-search-options", "na", false)}
                    }
                  ><MdViewList /></Button>
              </Col>

              <Col>
                { props.filteritm.isgraph &&
                  <Button className="text-nowrap" variant="outline-secondary" 
                      onClick={(e) => { 
                        props.filterh("filter-open-search-refresh", "na", false)}
                      }
                    >
                      <MdRefresh className="me-1"/>gql
                  </Button>
                }
                { !props.filteritm.isgraph &&
                  <Button className="text-nowrap" variant="outline-secondary" 
                      onClick={(e) => { 
                        props.filterh("filter-open-search-refresh", "na", false)}
                      }
                    >
                      <MdRefresh className="me-1"/>chain
                  </Button>
                }
              </Col>

            </Row>
            

          </Form>

          
    </>
  );
};
