
import React from "react";

import { ethers } from 'ethers';

import { StDtItm, PMeditpacket, StDt} from './CSInterface';


import { Alert, InputGroup, Button, Container, Row, Col, ProgressBar, Modal, Form, Spinner, Stack} from 'react-bootstrap';

interface Props {
  saleitm: StDt;

  action: string;
  errormsg?: string;
  nftid?: string;
  qdata?: StDtItm;
  updateh?: any;
  
  saleh: any;
  hideh: any;
  bstat: any;
}



export const SaleModal: React.FC<Props> = (props) => {

  const setRVal = (rnum: string) => {
    if(props.qdata && props.qdata.a == rnum) {
      return 1;
    } else {
      return 0;
    }
  }

  
  

  
  function createSaveBut(boc: React.MouseEventHandler<HTMLButtonElement>, btext: string, bclass?: string) {

    
    if( (props.bstat.salebuy && btext == "Buy") || (props.bstat.saleset5 && btext == "Publish Sale") || (props.bstat.cancelsell && btext == "Cancel Sell")) {

      return <Button
              variant="primary"
              className={bclass}
              
              onClick={boc}
              disabled
             >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Loading...</span>
                {btext}
            </Button>

    
    } else {

      return <Button
                variant="primary"
                className={bclass}
                
                onClick={boc}
              >
              {btext}
            </Button>
    }

  }





  return (
    <>
      <Modal show={props.action != "error"} onHide={props.hideh}>
        <Modal.Header closeButton>
          <Modal.Title>Buy or Sell a NFT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <Container>
          
            <Row>
              <Col>question</Col>
              <Col>{props.saleitm.idat.question}</Col>
            </Row>
            <Row>
              <Col></Col>
            </Row>

            <Form>
         

            {!props.saleitm.salepriceset && props.saleitm.saleprice.eq(0) &&
            <>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="sale price"
                    aria-label="sale price"
                    aria-describedby="sale-price"
                    name="salepradj"
                    
                    onChange={(e) => props.updateh(e)}
                    
                  />
                  <Button variant="outline-secondary" id="button-addon2"
                          onClick={(e)=>props.saleh(e,props.nftid,"saleadjset",props.saleitm)} >
                    Set Price
                  </Button>
                </InputGroup>
                {props.saleitm.salepriceerr != "" &&
                  <Alert variant="danger">
                    <p>{props.saleitm.salepriceerr}</p>
                  </Alert>
                }
              </>
            }

            </Form>

          
            <Row>
              <Col>nftid</Col>
              <Col>{props.saleitm.nftid}</Col>
            </Row>

            <Row>
              <Col>saleprice</Col>
              <Col>{ethers.utils.formatEther(props.saleitm.saleprice)}</Col>
            </Row>

            <Row>
              <Col>salepriceadj</Col>
              <Col>{ethers.utils.formatEther(props.saleitm.salepriceadj)}</Col>
            </Row>

            <Row>
              <Col>salepriceset</Col>
              <Col>{props.saleitm.salepriceset ? "yes" : "no"}</Col>
            </Row>

            <Row>
              <Col>salets</Col>
              <Col>{props.saleitm.salets.toNumber()}</Col>
            </Row>

            <Row>
              <Col>soldts</Col>
              <Col>{props.saleitm.soldts.toNumber()}</Col>
            </Row>

            <Row>
              <Col>soldcount</Col>
              <Col>{props.saleitm.soldcount}</Col>
            </Row>
            
            
          </Container>
        </Modal.Body>
        <Modal.Footer>
          
          <Container>
            <div className="d-flex">
              <div className="me-auto x">
                <Button variant="secondary"  onClick={(e)=>props.saleh(e,props.nftid,"cancel")}>Close</Button>    
              </div>

              {props.saleitm.saleprice.gt(0) && !props.saleitm.isowner  &&
                <div className="mx-1">
                  { createSaveBut((e)=>props.saleh(e,props.nftid,"salebuy"),"Buy") }
                </div>
              }
              
              {props.saleitm.saleprice.gt(0) && props.saleitm.isowner  &&
                <div className="x">
                  { createSaveBut((e)=>props.saleh(e,props.nftid,"cancelsell"),"Cancel Sell") }
                </div>
              }

              {props.saleitm.saleprice.eq(0) && props.saleitm.salepriceset && props.saleitm.isowner &&
                
                <>
                  <div className="mx-1">
                    <Button variant="primary"
                      onClick={(e)=>props.saleh(e,props.nftid,"saleadjedit",props.saleitm)} >
                      Edit Price
                    </Button>
                  </div>

                  <div className="mx-1">
                    { createSaveBut((e)=>props.saleh(e,props.nftid,"saleset5"),"Publish Sale") }
                  </div>
                </>

              }

            </div>
          </Container>

        </Modal.Footer>
      </Modal>
    </>
  );
};
