







import React, {useState} from 'react';
import './App.css';


import './App.scss';






import { CrypStackD } from './components/CrypStackD';




import { Form, InputGroup, Button, Container, Row, Col, Spinner, Alert} from 'react-bootstrap';




import {AdAppType} from './components/CSInterface';

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { offsetLimitPagination } from '@apollo/client/utilities';

function App() {

  
  
  
  
  const [stdorsim, setStdorsim] = useState<AdAppType>({apptype: "regappD"});
  
  

  
  

  
  
  

  
  
  

  


  
  
  

  
  
  

  
  
  const aclient = new ApolloClient({
      uri: process.env.REACT_APP_GRAPHQL,
      cache: new InMemoryCache()
  })

  
  
  
  
  
  
  
  
  
  
  
  
  
  
  let myval: string = "";

  return (

      
        <div className="ad-AppWrapper"> 
         
         
          {stdorsim.apptype == 'na' &&
            <Container>
                <Row className="ad-stacktopitm p-4">
                  
                  {process.env.NODE_ENV == "development" &&
                    <>
                      <Col><Button variant="primary" size="sm" onClick={ (e)=>setStdorsim({apptype:"regappD"}) }>app</Button></Col>
                      <Col><Button variant="primary" size="sm" onClick={ (e) => setStdorsim({apptype:"simapp"}) }>simulation</Button></Col>
                    </>
                  }
                  
                </Row>

                  <Row>

                <InputGroup>
                  <Form.Control
                    type="input"
                    placeholder="go secret"
                    autoFocus
                    onChange={(e) => {
                        e.preventDefault();
                        myval = e.target.value
                      }
                    }
                    onKeyDown={(e) => {
                      
                      
                      if(e.key == "Enter") {
                        e.preventDefault();
                        

                        if(myval == "app5") {
                          setStdorsim({apptype:"regappD"})
                        }
                        
                        
                        

                      }
                    }} 
                  />

                  <Button variant="outline-secondary"
                    onClick={(e) => {
                      
                    }}
                  >Search</Button>
                  <Button variant="outline-secondary"
                    onClick={(e) => {
                      if(myval == "app5") {
                        setStdorsim({apptype:"regappD"})
                      } else if(myval == "sim5") {
                        setStdorsim({apptype:"simapp"})
                      }
                    }}
                  >go</Button>

                </InputGroup>

                </Row>

            </Container>
          }

          

          {}


          {stdorsim.apptype == 'regappD' &&
           
           <ApolloProvider client={aclient}>
            <CrypStackD myapptype={stdorsim}></CrypStackD>
           </ApolloProvider>

          }
          
 
          
        </div> 
      

  );
}

export default App;
