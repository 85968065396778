import React from "react";

import { StDt, AnStatus, EpochDetail, DefItm } from './CSInterface';

import { CitmTextExpandoBS} from './CitmTextExpandoBS'

import { ethers, BigNumber } from 'ethers';


import { Button, Container, Row, Col, Spinner, Navbar, Tab, Tabs, ProgressBar } from 'react-bootstrap';
import { debug } from "console";


import { MdClose } from "@react-icons/all-files/md/MdClose";
import { MdDone } from "@react-icons/all-files/md/MdDone";
import { MdPersonPin } from "@react-icons/all-files/md/MdPersonPin";
import { MdChangeHistory } from "@react-icons/all-files/md/MdChangeHistory";
import { RiDownloadCloud2Fill } from "react-icons/ri";
import { MdArrowDropDown } from "@react-icons/all-files/md/MdArrowDropDown";
import { MdArrowDropUp } from "@react-icons/all-files/md/MdArrowDropUp";
import { CSpinnerButton } from "./CSpinnerButton";
import { checkOrSetBusyByN, fFactor, fToken } from "./CSCommon";
import { printIntrospectionSchema } from "graphql";

interface Props {
  itm: StDt;
  qandacfg: any;
  answerh: any;

  splush: any;
  saleh: any;
  claimh: any;
  burnh: any;

  edith: any;
  nfth: any;

  
  defitmviz: DefItm,
  totalitems: number;
  lookat: any;

  bstat: any;

  epoch: EpochDetail;

  disablechoose: boolean;
  isregistered: boolean;

  switchuistate: any;
}


export const CitmStackListBS: React.FC<Props> = (props) => {



  function getScoreCheck(a?: AnStatus, addtothis?: string) {
    if (a == AnStatus.AnswerNone) {
      return addtothis + " ad-scorecheck";
    } else if (a == undefined) {
      return addtothis + " ad-scorecheck";
    } else if (a == AnStatus.AnswerWrong) {
      return addtothis + " ad-scorecheck scorewrong";
    } else if (a == AnStatus.AnswerOK) {
      return addtothis + " ad-scorecheck scoreok";
    }
  }




  

  
  
  


  
  

  

  
  

  

  
  
  
  

  

  

  
  
  

  
  
  

  


  

  
  

  function cApr (calc: string) {

    
    
    if(props.epoch.nftpricecst
      && props.epoch.nftbasepricecst 
      && props.epoch.epochsecs
      && props.epoch.secsleft) {
    

      try {
        
        
        
        
        const cost = props.epoch.nftpricecst;

        if(cost.gt(0)) {
        
          let income = BigNumber.from(0)

          
          if(calc == "totnftapr") {
            
            
            const tempf = 10000;

            
            let totepochs = props.epoch.epochidx ? props.epoch.epochidx.sub(props.itm.mintepidx) : BigNumber.from(0)
            
            if(totepochs.eq(0)) {
              totepochs = BigNumber.from(1)
            }

            
            const totsecs = props.epoch.epochsecs.mul(totepochs)

            
            
            income = props.itm.totalstackflation.add(props.itm.stackposalltimereward).add(props.itm.bonusalltimereward)

            
            
            
            
            
            
            

            income = income.mul(tempf).div(totsecs).mul(365*24*60*60)
          
            const p1 = income.div(cost);
            
            
            const p2 = p1.mul(100);
            const p3 = p2.toNumber() / tempf;
            const p4 = p3.toFixed(2).toString();

            


            return p4;

          
          } else if(calc == "sfnftapr") {
            

            
            const tempf = 10000;

            
            income = props.itm.lastepochstackflation.mul(tempf).div(props.epoch.epochsecs).mul(365*24*60*60)

            
            const p1 = income.div(cost);
            
            
            const p2 = p1.mul(100);
            const p3 = p2.toNumber() / tempf;
            const p4 = p3.toFixed(2).toString();

            

            

            
            
            

            
            
            
            

            

            return props.itm.stale ? "---" : (props.itm.ready ? "--" : p4)


          } else if(calc == "mintnftapr") {
            const tempf = 10000;
            
            let totepochs = props.epoch.epochidx ? props.epoch.epochidx.sub(props.itm.mintepidx) : BigNumber.from(0)
            if(totepochs.eq(0)) {
              totepochs = BigNumber.from(1)
            }
            const totsecs = props.epoch.epochsecs.mul(totepochs)
            income = props.itm.stackposalltimereward
            income = income.mul(tempf).div(totsecs).mul(365*24*60*60)
            const p1 = income.div(cost);
            const p2 = p1.mul(100);
            const p3 = p2.toNumber() / tempf;
            const p4 = p3.toFixed(2).toString();
            return p4;

          } else if(calc == "bonusnftapr") {
            const tempf = 10000;
            
            let totepochs = props.epoch.epochidx ? props.epoch.epochidx.sub(props.itm.mintepidx) : BigNumber.from(0)
            if(totepochs.eq(0)) {
              totepochs = BigNumber.from(1)
            }
            const totsecs = props.epoch.epochsecs.mul(totepochs)
            income = props.itm.bonusalltimereward
            income = income.mul(tempf).div(totsecs).mul(365*24*60*60)
            const p1 = income.div(cost);
            const p2 = p1.mul(100);
            const p3 = p2.toNumber() / tempf;
            const p4 = p3.toFixed(2).toString();
            return p4;
            
          }

        } else {
          
        }

      } catch (e) {
        
      }

      

      
    }

    return "na";
  }

  function getEpochAge() {

    if(props.epoch.epochidx && props.itm.mintepidx) {
      return props.epoch.epochidx.gte(props.itm.mintepidx) ? props.epoch.epochidx.sub(props.itm.mintepidx).toString() : "0"
    } else {
      return "0"
    }
  }
  

  function dispAnsPerc(abn: ethers.BigNumber) {

    const tot = props.itm.r1answercnt.add(props.itm.r2answercnt).add(props.itm.r3answercnt)

    if(tot.gt(0)) {
      return abn.mul(100).div(tot).toString()
    } else {
      return "0"
    }
    

  }




  function dispIfPos(v: ethers.BigNumber, d?: number) {
    if (v.gt(0)) {
      return ethers.utils.formatEther(v);
    } else {
      return '';
    }

  };

  function netSplusMin() {

    const plus = props.itm.splus.toNumber();
    const min = props.itm.smin.toNumber();

    return plus - min;

  }


  function calcNettAttack() {

    const maxPlusMin = 100000;

    if(netSplusMin() > 0) {
      return Math.abs(netSplusMin() * 100 / maxPlusMin);

    } else {

      return Math.abs(netSplusMin() * 100/ maxPlusMin);
    }

  }

  function calcStyle(dir: string) {
    const arbmax = 50000;
    const net = netSplusMin();

    let perc = 0;

    if (dir == "min") {
      if (net < 0) {
        perc = (net / (-arbmax)) * 100;
      }

    } else if (dir = "plus") {
      if (net > 0) {
        perc = (net / (arbmax)) * 100;
      }
    }

    if (perc > 100) { perc = 100; };

    return { height: perc + '%' };

    

  }



  
  
  

  
  

  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  

  
  

  
  
  
  
  
  
  

  



  
  

  
  

  
  
  
  
  
  
  
  
  
  
  
  
  
  
  

  
  

  
  
  
  
  
  
  

  


  function createChooseBut(answerstr: string) {

    
    if (props.disablechoose) {

      return <Button variant="primary" size="sm" disabled onClick={(e) => props.answerh(e, answerstr, props.itm.nftid)}>choose</Button>

      
    } else {

      return <Button variant="primary" size="sm" onClick={(e) => props.answerh(e, answerstr, props.itm.nftid)}>choose</Button>
    }

  }



  function pmbarinner(isvotemode: any, voteid: string, myid: string) {

    return <Row className="ad-pmbar-inner h-100">

      {isvotemode && voteid == myid
        ? <Col md={1} className="ad-pmbar-left g-0 d-flex flex-column">
          <div className="ad-pmbarup d-flex flex-column flex-grow-1 justify-content-end">
            <div style={calcStyle("min")}></div>
          </div>
          <div className="ad-pmbardwn d-flex flex-column flex-grow-1 justify-content-start">
            <div style={calcStyle("plus")}></div>
          </div>
        </Col>
        : <Col className="ad-pmbar-left g-0 d-flex flex-column">
          <div className="ad-pmbarup d-flex flex-column flex-grow-1 justify-content-end">
            <div style={calcStyle("min")}></div>
          </div>
          <div className="ad-pmbardwn d-flex flex-column flex-grow-1 justify-content-start">
            <div style={calcStyle("plus")}></div>
          </div>
        </Col>
      }

      {isvotemode && voteid == myid &&
        <Col className="ad-pmbar-right g-0 d-flex flex-column justify-content-evenly">
          <Row md="auto" className="ad-pmbar-r-top g-0 justify-content-start align-items-center ">
            {}
            <Col className="mx-1">{fFactor(props.itm.smin)}</Col>
          </Row>
          <Row md="auto" className="ad-pmbar-r-mid g-0 justify-content-start align-items-center ">
            <Col className="mx-1">{netSplusMin()}</Col>
            {}
            <Col className="mx-1">sp {props.itm.stackpos} of {props.itm.stacklength.toNumber()}</Col>
            <Col className="mx-1">{fFactor(props.itm.stackposfactor, 25)}</Col>
          </Row>
          <Row md="auto" className="ad-pmbar-r-bot g-0 justify-content-start align-items-center ">
            {}
            <Col className="mx-1">{fFactor(props.itm.splus)}</Col>
          </Row>
        </Col>
      }

    </Row>

  }

  
  

  
  
  


  


  const getLocalStackClass = (curcls: string, itm: StDt) => {

    let sc = curcls;

    sc += itm.isbase ? " ad-nftisbase" : "";

    sc += itm.isowner ? " ad-ismine" : "";

    sc += props.defitmviz == DefItm.DefHintAt ? " ad-ishint" : "";

    return sc;

  }

  const getAccordianActiveKey = () => {
    
    return "2";

    if (props.defitmviz == DefItm.DefQuestions) {
      return "0";
    } else {
      return "99";
    }
  }


  const fuiupdate = ( itm: StDt) => {
    
    let sc = "ad-icon-inner ";

    if(itm.isowner) {

      sc += "ad-answer-owner";

    } else if(itm.answerst == AnStatus.AnswerOK) {

      if( itm.uiupdts > new Date().valueOf() - (2 * 1000)) {
        sc += "ad-answer-ok ad-answer-okts"
      } else {
        sc += "ad-answer-ok"
      }

    } else if(itm.answerst == AnStatus.AnswerWrong) {

      if( itm.uiupdts > new Date().valueOf() - (2 * 1000)) {
        sc += "ad-answer-err ad-answer-errts"
      } else {
        sc += "ad-answer-err"
      }

    }

    return sc;
    

  }

  

  return (

    <div>

      <Container className={getLocalStackClass("my-2 p-1 ad-stackitm", props.itm)}>

        {props.defitmviz == DefItm.DefHintAt &&
          <Row className="mt-2 mx-1">

            <Col className="mx-1 py-1 ad-header-grey">{props.itm.idat.question}</Col>

          </Row>
        }

        {props.defitmviz == DefItm.DefQuestions &&
          <Row className="mt-2 mx-1">

            {}

            <Col className="mx-1 py-1 ad-header-grey">{props.itm.idat.question}</Col>


          </Row>
        }

        {}


          {}


        { (props.defitmviz == DefItm.DefRewards || props.defitmviz == DefItm.DefMyNfts) &&
          <Row className="mt-2 mx-1">
            
              {}

              
              <div className="ad-header-grey-top mx-1 p-1 d-flex">
                <CitmTextExpandoBS etext={props.itm.idat.question} maxnooverlfow={70} />
              </div>

              <div className="ad-header-grey-bot ad-icon-base mx-1 p-1 ">

                <div className="d-flex align-items-center">
                  
                  <>
                    {props.itm.isowner &&
                      <>
                      <MdPersonPin className={fuiupdate(props.itm)} />
                      <div className="mx-2">is owner</div>
                      </>
                    }
                    {!props.itm.isowner && props.itm.answerst && props.itm.answerst == AnStatus.AnswerWrong &&
                      <>
                      <MdClose className={fuiupdate(props.itm)} /><div className="mx-2">answer</div>
                      </>
                    }
                    {!props.itm.isowner && props.itm.answerst && props.itm.answerst == AnStatus.AnswerOK &&
                      <>
                      <MdDone className={fuiupdate(props.itm)} /><div className="mx-2">answer</div>
                      </>
                    }
                    
                    <div className="mx-2">id: {props.itm.nftid}</div>
                    {}
                    <div className="mx-2">pos: {props.itm.stackpos} / {props.itm.stacklength.toNumber()}</div>

                    <div className="flex-grow-1"></div>

                    {}


                      {}

                      {props.itm.saleprice.gt(0) && !props.itm.isowner  &&
                          <Button className="mx-1" variant="primary" size="sm" onClick={(e) => props.saleh(e, props.itm.nftid, "salemodal",0)}>buy for {ethers.utils.formatEther(props.itm.saleprice)}</Button>
                        
                      }

                      {}
                      {}

                      {props.defitmviz == DefItm.DefMyNfts && props.itm.isowner  &&
                        <Button className="mx-1" variant="primary" size="sm" onClick={(e)=>props.nfth(e,props.itm.baseid,props.itm.desc,"viewstack")}>select stack</Button>
                      }

                      {props.defitmviz == DefItm.DefMyNfts && props.itm.isowner  && props.itm.burnflagepidx && props.itm.burnflagepidx.gt(0) &&
                        <Button className="mx-1" variant="primary" size="sm" onClick={(e)=>props.burnh(e,props.itm.nftid,"burnloadmodal")}>BURN</Button>
                      }

                      {}

                    {}


              

                  </>
                
                </div>

              </div>
          
          </Row>
        }




        {props.defitmviz == DefItm.DefHintAt &&
          <Row className="mt-1 mb-2 mx-4">not available</Row>
        }




        {(props.defitmviz == DefItm.DefQuestions || props.defitmviz == DefItm.DefMyNfts) &&
          <Row className="mt-1 mb-2 mx-1">


            <Col xs={12} md={props.defitmviz == DefItm.DefQuestions ? 12 : 6} className="xxleftqs ">

              <Row>


                <Col >
                <Row className="ms-1 me-2">
                    <Col className="mt-1 py-1 ad-reply-1 d-flex justify-content-between align-items-center ">
                      {}
                      <div>{props.itm.idat.rep1}</div>
                      {!props.disablechoose && props.isregistered &&
                        <>
                          <div className="col-1"/>
                          <div  className="xalign-items-center">{createChooseBut("1")}</div>
                        </>
                      }
                    </Col>
                  </Row>
                  
                  <Row className="ms-1 me-2">
                    <Col className="mt-1 py-1 ad-reply-2 d-flex justify-content-between align-items-center ">
                      {}
                      <div>{props.itm.idat.rep2}</div>
                      {!props.disablechoose && props.isregistered &&
                        <>
                          <div className="col-1"/>
                          <div  className="xalign-items-center">{createChooseBut("2")}</div>
                        </>
                      }
                    </Col>
                  </Row>
                  <Row className="ms-1 me-2">
                    <Col className="mt-1 py-1 ad-reply-3 d-flex justify-content-between align-items-center ">
                      {}
                      <div>{props.itm.idat.rep3}</div>
                      {!props.disablechoose && props.isregistered &&
                        <>
                          <div className="col-1"/>
                          <div  className="xalign-items-center">{createChooseBut("3")}</div>
                        </>
                      }
                    </Col>
                  </Row>
                </Col>

              </Row>
            </Col>


            {props.defitmviz == DefItm.DefMyNfts &&

              <Col xs={12} md={6} className="xxrightqs ">

                <Row>

                    <Col>
                      {}
                        <Row className="xpy-1 xad-reply-1">
                          <Col >mint reward</Col>
                          <Col>{fToken(props.itm.stackposreward,1)}</Col>
                          {props.itm.isowner &&
                            
                            <Col xs={3} className="mt-1">
                              <CSpinnerButton spinstat={checkOrSetBusyByN(props.bstat.claimmint,props.itm.nftid)} butstring="claim" clickhandler={(e: any)=>props.claimh(e, props.itm.nftid, "stackmintreward", props.lookat.view)} />
                            </Col>
                          }
                        </Row>
                      {}

                      {}
                        <Row className="xpy-1 xad-reply-1">
                          <Col >stackflation</Col>

                          {}

                          { props.itm.stale || props.itm.ready ?
                          <>
                            <Col>--</Col>
                            {props.itm.isowner &&
                              <Col xs={3} className="mt-1">
                                <CSpinnerButton spinstat={false} butstring="claim" butdisabled={true} clickhandler={(e: any)=>{console.log("do nothing")}}/>
                              </Col>
                            }
                          </>
                          :
                          <>
                            <Col>{fToken(props.itm.approxavailstackflation,1)}</Col>
                              {props.itm.isowner &&
                                <Col xs={3} className="mt-1">
                                  <CSpinnerButton spinstat={checkOrSetBusyByN(props.bstat.claimsf,props.itm.nftid)} butstring="claim" clickhandler={(e: any)=>props.claimh(e, props.itm.nftid, "stackflation", props.lookat.view)} />
                                </Col>
                              }
                            </>
                          }

                        </Row>
                      {}

                        <Row className="xpy-1 xad-reply-1">
                          <Col >bonus</Col>
                          <Col>{fToken(props.itm.bonusreward,1)}</Col>
                          {props.itm.isowner &&
                            <Col xs={3} className="mt-1">
                              <CSpinnerButton spinstat={checkOrSetBusyByN(props.bstat.claimbonus,props.itm.nftid)} butstring="claim" clickhandler={(e: any)=>props.claimh(e, props.itm.nftid, "claimbonus", props.lookat.view)} />
                            </Col>
                          }
                        </Row>


                      {}
                        <Row className="xpy-1 xad-reply-1">
                          <Col >coupon b/r</Col>
                          <Col>{props.itm.basemintcredit} / {props.itm.mintcredit}</Col>
                          {props.itm.isowner &&
                            
                            <Col xs={3} className="mt-1">
                              <CSpinnerButton spinstat={checkOrSetBusyByN(props.bstat.claimcredits,props.itm.nftid)} butstring="claim" clickhandler={(e: any)=>props.claimh(e, props.itm.nftid, "claimmintcredits", props.lookat.view)} />
                            </Col>
                          }
                        </Row>
                      {}
                  
                    </Col>

                </Row>
              </Col>
            
            }


          </Row>
        }




        {props.defitmviz == DefItm.DefRewards &&
          <Row className="mt-1 mb-2 mx-1">

            <Col xs={12} md={6} className="xxleftqs ">

              <Row>

                <Col >
                  
                  {}

                  <Row className="ms-1 xpy-1 ">
                    <Col>generation</Col>
                    <Col>{props.itm.uri}</Col>
                  </Row>

                          {}

                          {}

                  <Row className="ms-1 xpy-1 ">
                    <Col>stackflation</Col>
                    <Col>{cApr("sfnftapr")}</Col>
                  </Row>

                  <Row className="ms-1 xpy-1 ">
                    <Col>total APR</Col>
                    <Col>{cApr("totnftapr")}</Col>
                  </Row>

                  <Row className="ms-1 xpy-1 ">
                    <Col>stack factor</Col>
                    <Col>{fFactor(props.itm.stackposfactor, 25)}</Col>
                  </Row>

                  
                  { 1 == 1 &&
                  <>
                    <Row className="ms-1 xpy-1 ">
                      <Col>attack p/m</Col>
                      <Col>{fFactor(props.itm.splus)} / {fFactor(props.itm.smin)}</Col>
                    </Row>

                    { props.itm.isbase ?
                      <>
                        <Row className="ms-1 mt-1 ad-attack-section py-2">
                          <Col>
                            Base question cannot be attacked
                          </Col>
                        </Row>
                      </>
                      :  
                      <>
                        <Row className="ms-1 mt-1 ad-attack-section py-2">
                          <Col>
                            <Row className="justify-content-evenly align-items-center">
                              <Col xs={5}>nett</Col>
                              <Col xs={7}>
                                <ProgressBar variant={netSplusMin() > 0 ? "success" : "danger"} className="xcol-6 xmt-3" now={calcNettAttack()} label={`${calcNettAttack().toFixed(0)}%`}/>
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <Row className="justify-content-evenly align-items-center">
                              <Col xs={5}>{netSplusMin()}</Col>
                              <Col xs={5}>
                                <Button variant="primary" size="sm" disabled={props.itm.isbase} onClick={(e) => props.splush(e, props.itm.nftid, "attackloadmodal",0)}>attack</Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </>
                    }
                  </>
                  }

                  {}

                
                  
                </Col>



              </Row>
            </Col>






            <Col className="xxrightclaims xad-tab-comp col-xs-12 col-md-6 xmt-1">

              <Tabs defaultActiveKey={getAccordianActiveKey()} fill className="mb-3">
                <Tab eventKey="0" title="claims">
                  

                    {props.defitmviz == DefItm.DefRewards && 
                      <Row className="xpy-1 xad-reply-1">
                        <Col >mint reward</Col>
                        <Col>{fToken(props.itm.stackposreward,1)}</Col>
                        {props.itm.isowner &&
                          
                          <Col xs={3} className="mt-1">
                            <CSpinnerButton spinstat={checkOrSetBusyByN(props.bstat.claimmint,props.itm.nftid)} butstring="claim" clickhandler={(e: any)=>props.claimh(e, props.itm.nftid, "stackmintreward", props.lookat.view)} />
                          </Col>
                        }
                      </Row>
                    }

                    {props.defitmviz == DefItm.DefRewards && 
                      <Row className="xpy-1 xad-reply-1">
                        <Col >stackflation</Col>
                        
                        {}

                        { props.itm.stale || props.itm.ready ?
                        <>
                          <Col>--</Col>
                          {props.itm.isowner &&
                            <Col xs={3} className="mt-1">
                              <CSpinnerButton spinstat={false} butstring="claim" butdisabled={true} clickhandler={(e: any)=>{console.log("do nothing")}}/>
                            </Col>
                          }
                        </>
                        :
                        <>
                          <Col>{fToken(props.itm.approxavailstackflation,1)}</Col>
                            {props.itm.isowner &&
                              
                              <Col xs={3} className="mt-1">
                                <CSpinnerButton spinstat={checkOrSetBusyByN(props.bstat.claimsf,props.itm.nftid)} butstring="claim" clickhandler={(e: any)=>props.claimh(e, props.itm.nftid, "stackflation", props.lookat.view)} />
                              </Col>
                            }
                          </>
                        }


                      </Row>
                    }

                    {props.defitmviz == DefItm.DefRewards && 
                      <Row className="xpy-1 xad-reply-1">
                        <Col >bonus</Col>
                        <Col>{fToken(props.itm.bonusreward,1)}</Col>
                        {props.itm.isowner &&
                          <Col xs={3} className="mt-1">
                            <CSpinnerButton spinstat={checkOrSetBusyByN(props.bstat.claimbonus,props.itm.nftid)} butstring="claim" clickhandler={(e: any)=>props.claimh(e, props.itm.nftid, "claimbonus", props.lookat.view)} />
                          </Col>
                        }
                      </Row>
                    }


                    {props.defitmviz == DefItm.DefRewards && 
                      <Row className="xpy-1 xad-reply-1">
                        <Col >coupon b/r</Col>
                        <Col>{props.itm.basemintcredit} / {props.itm.mintcredit}</Col>
                        {props.itm.isowner &&
                          
                          <Col xs={3} className="mt-1">
                            <CSpinnerButton spinstat={checkOrSetBusyByN(props.bstat.claimcredits,props.itm.nftid)} butstring="claim" clickhandler={(e: any)=>props.claimh(e, props.itm.nftid, "claimmintcredits", props.lookat.view)} />
                          </Col>
                        }
                      </Row>
                    }

                    {}

                  
                </Tab>



                <Tab eventKey="1" title="stats">
                  
                    <Row className="xpy-1">
                      <Col xs={8}>all time mint reward</Col>
                      <Col>{fToken(props.itm.stackposalltimereward,1)}</Col>

                    </Row>

                    <Row className="xpy-1">
                      <Col xs={8}>total stackflation</Col>
                      <Col>{fToken(props.itm.totalstackflation,1)}</Col>

                    </Row>

                    <Row className="xpy-1">
                      <Col xs={8}>total bonus</Col>
                      <Col>{fToken(props.itm.bonusalltimereward,1)}</Col>

                    </Row>

                    <Row className="xpy-1">
                      <Col xs={8}>epoch age</Col>
                      <Col>{getEpochAge()}</Col>

                    </Row>

                    <Row className="xpy-1">
                      <Col xs={8}>ok answers</Col>
                      <Col>{fFactor(props.itm.okanswercnt)}</Col>

                    </Row>

                    {}


                  </Tab>



                <Tab eventKey="2" title="answers">
                  

                        {}

                    <Row className="ms-1 xpy-1 mb-1 xad-reply-1">
                      <Col className="col-9 me-1 pe-0 p-1 ad-reply-1 d-flex">
                        <CitmTextExpandoBS etext={props.itm.idat.a == "1" ? "*"+props.itm.idat.rep1 : props.itm.idat.rep1} maxnooverlfow={25} />
                      </Col>
                      {}

                      <Col className="py-1 ad-reply-1 text-center" onClick={props.switchuistate}>
                          {props.lookat.ansperc ?
                            <div>{dispAnsPerc(props.itm.r1answercnt)} %</div>
                            : 
                            <div>{fFactor(props.itm.r1answercnt)}</div>
                          }
                      </Col>

                    </Row>

                    <Row className="ms-1 xpy-1 mb-1 xad-reply-2">
                      <Col className="col-9 me-1 pe-0 p-1 ad-reply-2 d-flex">
                        <CitmTextExpandoBS etext={props.itm.idat.a == "2" ? "*"+props.itm.idat.rep2 : props.itm.idat.rep2} maxnooverlfow={25} />
                      </Col>
                      {}
                      <Col className="py-1 ad-reply-2 text-center" onClick={props.switchuistate}>
                          {props.lookat.ansperc ?
                            <div>{dispAnsPerc(props.itm.r2answercnt)} %</div>
                            : 
                            <div>{fFactor(props.itm.r2answercnt)}</div>
                          }
                      </Col>
                    </Row>

                    <Row className="ms-1 xpy-1 mb-1 xad-reply-3">
                      <Col className="col-9 me-1 pe-0 p-1 ad-reply-3 d-flex">
                        <CitmTextExpandoBS etext={props.itm.idat.a == "3" ? "*"+props.itm.idat.rep3 : props.itm.idat.rep3} maxnooverlfow={25} />
                      </Col>
                      {}
                      <Col className="py-1 ad-reply-3 text-center" onClick={props.switchuistate}>
                          {props.lookat.ansperc ?
                            <div>{dispAnsPerc(props.itm.r3answercnt)} %</div>
                            : 
                            <div>{fFactor(props.itm.r3answercnt)}</div>
                          }
                      </Col>
                    </Row>


                  </Tab>

              </Tabs>

              

            </Col>





          </Row>
        }





        {props.lookat.devinfo &&
          <Row>

            <Col className="ad-devinfo">

              <Row className="xx">

                <Col className="xx">
                  <Row>
                    <Col>answered</Col>
                    <Col>{props.itm.answered}</Col>
                  </Row>
                  <Row>
                    <Col>answerst</Col>
                    <Col>{props.itm.answerst}</Col>
                  </Row>
                  <Row>
                    <Col>real answer is</Col>
                    <Col>{props.itm.idat.a}</Col>
                  </Row>
                  <Row>
                    <Col>ownername</Col>
                    <Col>{props.itm.ownername}</Col>
                  </Row>
                  <Row>
                    <Col>uri</Col>
                    <Col>{props.itm.uri}</Col>
                  </Row>
                  <Row>
                    <Col>nft type</Col>
                    <Col>{props.itm.isbase ? "BASE" : "child"}</Col>
                  </Row>
                  <Row>
                    <Col>basemintcredit</Col>
                    {}
                    <Col>{props.itm.basemintcredit}</Col>
                  </Row>
                  <Row>
                    <Col>basemint alltime</Col>
                    <Col>{props.itm.basemintalltimecredit}</Col>
                  </Row>
                  <Row>
                    <Col>ok answer tot</Col>
                    <Col>{fFactor(props.itm.okanswercnt)}</Col>
                  </Row>
                  <Row>
                    <Col>wrong answer tot</Col>
                    <Col>{fFactor(props.itm.wronganswercnt)}</Col>
                  </Row>

                  <Row>
                    <Col>total answer 1</Col>
                    <Col>{fFactor(props.itm.r1answercnt)}</Col>
                  </Row>
                  <Row>
                    <Col>total answer 2</Col>
                    <Col>{fFactor(props.itm.r2answercnt)}</Col>
                  </Row>
                  <Row>
                    <Col>total answer 3</Col>
                    <Col>{fFactor(props.itm.r3answercnt)}</Col>
                  </Row>

                  <Row>
                    <Col>mint APR</Col>
                    <Col>{cApr("mintnftapr")}</Col>
                  </Row>

                  <Row>
                    <Col>stackflation APR</Col>
                    <Col>{cApr("sfnftapr")}</Col>
                  </Row>

                  <Row>
                    <Col>bonus APR</Col>
                    <Col>{cApr("bonusnftapr")}</Col>
                  </Row>

                  <Row>
                    <Col>total APR</Col>
                    <Col>{cApr("totnftapr")}</Col>
                  </Row>
                </Col>

                <Col className="xx">


                  <Row>
                    <Col>owner adr</Col>
                    <Col>{props.itm.owner}</Col>
                  </Row>
                  <Row>
                    <Col>stackpos</Col>
                    <Col>{props.itm.stackpos}</Col>
                  </Row>
                  <Row>
                    <Col>stacklength</Col>
                    <Col>{fFactor(props.itm.stacklength)}</Col>
                  </Row>
                  <Row>
                    <Col>stackcanadd</Col>
                    <Col>{props.itm.stackcanadd ? "OK" : "ERR"}</Col>
                  </Row>
                  <Row>
                    <Col>stackposreward</Col>
                    <Col>{fToken(props.itm.stackposreward)}</Col>
                  </Row>
                  <Row>
                    <Col>stackposalltimereward</Col>
                    <Col>{fToken(props.itm.stackposalltimereward)}</Col>
                  </Row>

                  <Row>
                    <Col>sf approxavailstackflation</Col>
                    <Col>{fToken(props.itm.approxavailstackflation)}</Col>
                  </Row>
                  <Row>
                    <Col>sf totalstackflation</Col>
                    <Col>{fToken(props.itm.totalstackflation)}</Col>
                  </Row>
                  <Row>
                    <Col>sf lastepochstackflation</Col>
                    <Col>{fToken(props.itm.lastepochstackflation)}</Col>
                  </Row>
                  <Row>
                    <Col>sf claimed</Col>
                    <Col>{fToken(props.itm.claimedstackflation)}</Col>
                  </Row>
                  <Row>
                    <Col>sf approx</Col>
                    <Col>{fToken(props.itm.approxavailstackflation)}</Col>
                  </Row>

                  <Row>
                    <Col>bonusreward</Col>
                    <Col>{fToken(props.itm.bonusreward)}</Col>
                  </Row>
                  <Row>
                    <Col>bonusalltimereward</Col>
                    <Col>{fToken(props.itm.bonusalltimereward)}</Col>
                  </Row>

                </Col>

              </Row>

            </Col>

          </Row>
        }


      </Container>



    </div>

  );

};
