
import React from "react";

import { AccBal, BusyStat, StDtItm} from './CSInterface';



import {Alert, Button, Container, Row, Col, ProgressBar, Modal, Form, Spinner} from 'react-bootstrap';
import { ethers } from "ethers";

import { MdCheck } from "@react-icons/all-files/md/MdCheck";
import { fToken } from "./CSCommon";
import { NftItemStructOutput } from "../hardhat/typechain/contracts/Cnft";

interface Props {
  action: string;
  errormsg?: string;
  valimsg?: string;
  nftid?: string;
  nftdesc ?: string;
  qdata?: StDtItm;

  
  
  
  
  
  

  abal?: AccBal;

  couplist: NftItemStructOutput [];

  updateh?: any;
  modalh: any;
  hideh: any;
  bstat: BusyStat;
}




export const CModal: React.FC<Props> = (props) => {


  

  const setRVal = (rnum: string) => {

    

    if(props.qdata && props.qdata.a == rnum) {
      return 1;
    } else {
      return 0;
    }
  }


  
    function createSaveBut(btext: string,  mev: React.MouseEventHandler<HTMLButtonElement>) {

    
    if(props.bstat.mintweth) {

      return <Button
              variant="primary"
              
              
              onClick={mev}
              disabled
             >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Loading...</span>
                {btext}
            </Button>

    
    } else {

      return <Button
                variant="primary"
                
                
                onClick={mev}
              >
              {btext}
            </Button>
    }

  }



  function createMintBut(btext: string, bdisable: boolean, mev: React.MouseEventHandler<HTMLButtonElement>, bvariant: string, showspin: boolean) {

    
    
    if(showspin) {

      return <Button
              variant={bvariant}
              disabled={true} 
              onClick={mev}
            >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Loading...</span>
                {btext}
            </Button>

    
    } else {

      return <Button
                variant={bvariant}
                disabled={bdisable} 
                onClick={mev}
              >
              {btext}
            </Button>
    }

  }


  return (
    <div>
      <Modal show={props.action != "error"} onHide={props.hideh}>
        <Modal.Header closeButton>
          {props.action == "edit" &&
            <Modal.Title>Edit Question and Replies</Modal.Title>
          }
          {props.action == "new" &&
            <Modal.Title>Mint qNFT</Modal.Title>
          }
          {props.action == "newbase" &&
            <Modal.Title>Add BASE qNFT</Modal.Title>
          }
          
        
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Question</Form.Label>
              <Form.Control
                type="input"
                placeholder="question"
                autoFocus
                value={props.qdata ? props.qdata.question : ''}
                onChange={(e) => props.updateh(e,e.target.value,"question")}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Replies</Form.Label>
              <Form.Control type="text" placeholder="reply1"
                  value={props.qdata ? props.qdata.rep1 : ''}
                  onChange={(e) => props.updateh(e,e.target.value,"rep1")} />
              <br/>
              <Form.Control type="text" placeholder="reply2"
                  value={props.qdata ? props.qdata.rep2 : ''}
                  onChange={(e) => props.updateh(e,e.target.value,"rep2")} />
              <br/>
              <Form.Control type="text" placeholder="reply3"
                  value={props.qdata ? props.qdata.rep3 : ''}
                  onChange={(e) => props.updateh(e,e.target.value,"rep3")} />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Select Preferred Answer</Form.Label>
              <br />
              <Form.Check inline name="ans" label="reply1" type="radio" id="repradio-1" value={setRVal("1")} checked={props.qdata && props.qdata.a == '1' ? true : false} onChange={(e) => props.updateh(e,"1","a")} />
              <Form.Check inline name="ans" label="reply2" type="radio" id="repradio-2" value={setRVal("2")} checked={props.qdata && props.qdata.a == '2' ? true : false} onChange={(e) => props.updateh(e,"2","a")} />
              <Form.Check inline name="ans" label="reply3" type="radio" id="repradio-3" value={setRVal("3")} checked={props.qdata && props.qdata.a == '3' ? true : false} onChange={(e) => props.updateh(e,"3","a")} />

            </Form.Group>

            { props.valimsg && props.valimsg != "" &&
              <Alert variant="danger" className="mt-1 mb-0">{props.valimsg}</Alert>
            }

          </Form>
        </Modal.Body>
        <Modal.Footer>

          {props.action == "edit"
            ? createSaveBut("Save Changes",(e)=>props.modalh(e,props.nftid,"editsave"))
            
            :
            <Container>
              <Alert show variant="success" className="ad-buy-regular">

                {props.action == "new" && 
                  <div>
                    <p>Mint a new question using FTM. The new qNFT will be added to the stack: "{props.nftdesc}". Your balance is { fToken(props.abal && props.abal.etherval ? props.abal.etherval : ethers.BigNumber.from("0") ,2)} FTM.</p>
                      {createMintBut("Mint with " + fToken((props.abal && props.abal.nftbnprice) ? props.abal.nftbnprice : ethers.BigNumber.from(0),1) + " FTM" ,false
                        , (e)=>props.modalh(e,props.nftid,"mintlinksave"),"outline-primary" 
                        , props.bstat.mintweth && props.bstat.busytranmint ? true : false )}
                    

                    { props.bstat.mintweth && props.errormsg && props.errormsg != "" ?
                        <Alert variant="danger" className="mt-1 mb-0">{props.errormsg}</Alert>
                        :
                        <div>
                          { props.bstat.mintweth && !props.bstat.busytranmint && props.bstat.busytranapprove &&
                            <div>
                              <Spinner animation="border" size="sm"></Spinner><span className="ms-1">approving</span>
                            </div>
                          }
                          { props.bstat.mintweth && props.bstat.busytranmint && !props.bstat.busytranapprove &&
                            <div><div><MdCheck />approved</div>
                              <Spinner animation="border" size="sm"></Spinner><span className="ms-1">minting</span>
                            </div>
                          }
                          { props.bstat.mintweth && !props.bstat.busytranmint && !props.bstat.busytranapprove &&
                            <div>
                              <div><MdCheck />approved</div>
                              <div><MdCheck />completed</div>
                            </div>
                          }
                        </div>
                      }
                    
                    <hr />

                    <p>Mint a new question using a cNFT coupon. The new qNFT will be added to the stack: "{props.nftdesc}". Your cNFT balance is {props.abal && props.abal.cnftregularcnt ? props.abal.cnftregularcnt.toNumber() : 0} coupons (regular + boost).</p>
                      
                      <div className="d-flex justify-content-between">
                      {

                        

                        Object.entries(props.couplist.reduce(
                          (iacc,icur)=>{
                            
                            

                            if(icur.isbase) { 
                              iacc.lbaseid = icur.nftid
                            } else if(icur.uri.substring(0,3) == "b10") {
                              iacc.lb10id = icur.nftid
                            } else if(icur.uri.substring(0,3) == "b20") {
                              iacc.lb20id = icur.nftid
                            } else if(icur.uri.substring(0,3) == "b30") {
                              iacc.lb30id = icur.nftid
                            } else {
                              iacc.lregid = icur.nftid
                            }
                            return iacc
                          }
                          ,{  lbaseid: ethers.BigNumber.from(0), 
                              lregid: ethers.BigNumber.from(0), 
                              lb10id: ethers.BigNumber.from(0),
                              lb20id: ethers.BigNumber.from(0),
                              lb30id: ethers.BigNumber.from(0) 
                          }
                        )).map((k)=>{
                          
                          if(k[0] == "lb10id" && k[1].gt(0)) {
                          
                            
                            return createMintBut("use cNFT (boost10)",k[1].gt(0) ? false : true
                            , (e)=>props.modalh(e,props.nftid,"mintlinkcouponsave",k[1]) ,"outline-primary" 
                            , props.bstat.mintcoupon && props.bstat.busytranmint ? true : false )
                          
                          } else if(k[0] == "lb20id" && k[1].gt(0)) {
                          
                            return createMintBut("use cNFT (boost20)",k[1].gt(0) ? false : true
                            , (e)=>props.modalh(e,props.nftid,"mintlinkcouponsave",k[1]) ,"outline-primary" 
                            , props.bstat.mintcoupon && props.bstat.busytranmint ? true : false )

                          } else if(k[0] == "lb30id" && k[1].gt(0)) {
                          
                            return createMintBut("use cNFT (boost30)",k[1].gt(0) ? false : true
                            , (e)=>props.modalh(e,props.nftid,"mintlinkcouponsave",k[1]) ,"outline-primary" 
                            , props.bstat.mintcoupon && props.bstat.busytranmint ? true : false )

                          
                          } else if(k[0] == "lregid") {
                            return createMintBut("use cNFT (regular)",k[1].gt(0) ? false : true
                            , (e)=>props.modalh(e,props.nftid,"mintlinkcouponsave",k[1]) ,"outline-primary" 
                            , props.bstat.mintcoupon && props.bstat.busytranmint ? true : false )

                          }
                        })

                      }
                      </div>
                      
                      { props.bstat.mintcoupon && props.errormsg && props.errormsg != "" ?
                        <Alert variant="danger" className="mt-1 mb-0">{props.errormsg}</Alert>
                        :
                        <div>
                          { props.bstat.mintcoupon && !props.bstat.busytranmint && props.bstat.busytranapprove &&
                            <div>
                              <Spinner animation="border" size="sm"></Spinner><span className="ms-1">approving</span>
                            </div>
                          }
                          { props.bstat.mintcoupon && props.bstat.busytranmint && !props.bstat.busytranapprove &&
                            <div><div><MdCheck />approved</div>
                              <Spinner animation="border" size="sm"></Spinner><span className="ms-1">applying coupon</span>
                            </div>
                          }
                          { props.bstat.mintcoupon && !props.bstat.busytranmint && !props.bstat.busytranapprove &&
                            <div>
                              <div><MdCheck />approved</div>
                              <div><MdCheck />completed</div>
                            </div>
                          }
                        </div>
                      }
                  </div>
                }
              </Alert>
              <Alert show variant="warning" className="ad-buy-base">
                {}
                <p>Mint a new BASE question using a cNFT base coupon. Your cNFT balance is {props.abal && props.abal.cnftbasecnt ? props.abal.cnftbasecnt.toNumber() : 0} base coupons.</p>
                  {createMintBut("use cNFT (base)",props.abal && props.abal.cnftbasecnt.gt(0) ? false : true
                    , (e)=>props.modalh(e,props.nftid,"mintlinkbasecouponsave") ,"outline-success" , props.bstat.mintcouponbase && props.bstat.busytranmint ? true : false )}
                  
                  { props.bstat.mintcouponbase && props.errormsg && props.errormsg != "" ?
                    <Alert variant="danger" className="mt-1 mb-0">{props.errormsg}</Alert>
                    :
                    <div>
                      { props.bstat.mintcouponbase && !props.bstat.busytranmint && props.bstat.busytranapprove &&
                        <div>
                          <Spinner animation="border" size="sm"></Spinner><span className="ms-1">approving</span>
                        </div>
                      }
                      { props.bstat.mintcouponbase && props.bstat.busytranmint && !props.bstat.busytranapprove &&
                        <div><div><MdCheck />approved</div>
                          <Spinner animation="border" size="sm"></Spinner><span className="ms-1">applying coupon</span>
                        </div>
                      }
                      { props.bstat.mintcouponbase && !props.bstat.busytranmint && !props.bstat.busytranapprove &&
                        <div>
                          <div><MdCheck />approved</div>
                          <div><MdCheck />completed</div>
                        </div>
                      }
                    </div>
                  }
                  
              </Alert>
            </Container>
          }

        </Modal.Footer>
      </Modal>
    </div>
  );
};
