
import React from "react";

import { FilterItm} from './CSInterface';

import { Offcanvas, Button, InputGroup, Container, Row, Col, ProgressBar, Modal, Form, Spinner} from 'react-bootstrap';

interface Props {
  filteritm: FilterItm;
  filterh: any;
  hideh: any;
  bstat: any;
}

export const CBaseFilterOptionsBS: React.FC<Props> = (props) => {

  
  return (
    <>
      
      <Offcanvas show={true} onHide={props.hideh} placement="top">
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Filter Options</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Form>

                      

                      <Form.Check
                        disabled={props.filteritm.search != "" || !props.filteritm.isgraph ? true : false}
                        type="switch"
                        label="stacks where qNFT owned"
                        id="chk-filter-affiliated"
                        checked={props.filteritm.affiliated}
                        onChange={(e) => {
                            props.filterh("filter-affiliated","na", e.target.checked)
                          }
                        }
                      />

                      <Form.Check
                        disabled={props.filteritm.search != "" || !props.filteritm.isgraph ? true : false}
                        type="switch"
                        label="stacks where qNFT can be added"
                        id="chk-filter-affiliation-available"
                        checked={props.filteritm.affiliationavailable}
                        onChange={(e) => {
                            props.filterh("filter-affiliation-available","na", e.target.checked)
                          }
                        }
                      />


                      <Form.Check
                        disabled={props.filteritm.search != "" || !props.filteritm.isgraph ? true : false}
                        type="switch"
                        label="stacks where points can be claimed"
                        id="chk-filter-claims-available"
                        checked={props.filteritm.claimsavail}
                        onChange={(e) => {
                            props.filterh("filter-claims-available","na", e.target.checked)
                          }
                        }
                      />


                      <Form.Check
                        disabled={props.filteritm.search != "" || !props.filteritm.isgraph ? true : false}
                        type="switch"
                        label="show attack detail"
                        id="chk-filter-show-attacks"
                        checked={props.filteritm.showattacks}
                        onChange={(e) => {
                            props.filterh("filter-showattacks","na", e.target.checked)
                          }
                        }
                      />

                      <Form.Check
                        
                        type="switch"
                        label="use graphql"
                        id="chk-filter-use-graphql"
                        checked={props.filteritm.usegraphql}
                        onChange={(e) => {
                            props.filterh("filter-usegraphql","na", e.target.checked)
                          }
                        }
                      />


                      
                    </Form>
                  </Offcanvas.Body>
                </Offcanvas>

          
    </>
  );
};
