
import React from "react";

import { BusyStat, StDtItm, PMeditpacket, BurnDetails} from './CSInterface';



import { ethers, BigNumber } from 'ethers';


import {Button, Container, Row, Col, ProgressBar, Modal, Form, Spinner, Alert} from 'react-bootstrap';

import { MdCheck } from "@react-icons/all-files/md/MdCheck";
import { CSpinnerButton } from "./CSpinnerButton";
import { fFactor, fToken } from "./CSCommon";


interface Props {
  burndetails: BurnDetails;

  action: string;
  errormsg: string;

  burnh: any;
  hideh: any;
  bstat: BusyStat;
}



export const BurnModal: React.FC<Props> = (props) => {

  return (
    <>
      <Modal show={props.action != "error"} onHide={props.hideh}>
        <Modal.Header closeButton>
          <Modal.Title>Burn qNFt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <Container>
          
            <Row>
              <Col><p>This qNFT has been removed from the stack after losing an attack. This qNFT is convertible to a cNFT by burning it. Then the cNFT can be used to mint a new qNFT</p></Col>
            </Row>

            <hr />
            
            <Row>
              <Col>nftid</Col>
              <Col>{props.burndetails.nftid}</Col>
            </Row>

            <Row>
              <Col>question</Col>
              <Col>{props.burndetails.question}</Col>
            </Row>

            <Row>
              <Col>stackposreward</Col>
              <Col>{fToken(props.burndetails.mintreward)}</Col>
            </Row>
            
            
            
            
            { props.bstat.burning && !props.bstat.busytranburn && props.bstat.busytranapprove &&
              <>
                <Spinner animation="border" size="sm"></Spinner><span className="ms-1">approving</span>
              </>
            }
            
            { props.bstat.burning && props.bstat.busytranburn && !props.bstat.busytranapprove &&
              <>
                <div><MdCheck />approved</div>
                <Spinner animation="border" size="sm"></Spinner><span className="ms-1">burning</span>
              </>
            }

            { props.bstat.burning && !props.bstat.busytranburn && !props.bstat.busytranapprove &&
              <>
                <div><MdCheck />approved</div>
                <div><MdCheck />burned</div>
              </>
            }

            { props.errormsg && props.errormsg != "" &&
            
              <Alert variant="danger">{props.errormsg}</Alert>
            }
            
          </Container>
        </Modal.Body>
        <Modal.Footer>

          <Container>
            <div className="d-flex justify-content-between">

              <div className="x">
                <Button variant="secondary" onClick={(e)=>props.burnh(e,props.burndetails.nftid,"cancel")}>Close</Button>
              </div>
          
              <div className="d-flex">
                
                <div className="x">
                  <CSpinnerButton clickhandler={(e: any)=>props.burnh(e,props.burndetails.nftid,"burn")} 
                      butstring="Burn" spinstat={props.bstat.burning ? props.bstat.burning : false} />
                </div>
              </div>

            
            </div>
          </Container>

        </Modal.Footer>
      </Modal>
    </>
  );
};
