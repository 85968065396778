
import { BigNumber, ethers } from 'ethers';






export interface AdAppType {
  apptype: string;
  
  
  
  
}

export enum DefItm {
  DefMyNfts,
  DefQuestions,
  DefRewards,
  DefHintAt,
  DefNoShow
}

export interface StDtItm {
  question: string;
  rep1: string;
  rep2: string;
  rep3: string;
  a: string;
}

export interface BaseItm {
  ikey: number;
  
  
  
  owneradr: string;
  
  nftid: string;

  mintepidx: ethers.BigNumber;
  
  
  
  

  stacklength: ethers.BigNumber;
  

  

  desc?: string;

  attacks: AttackItm [];

  attackvalsum: ethers.BigNumber;
  attacktot: number;

  baseanswercount: number;

  base_attackplus: ethers.BigNumber;
  base_attackmin: ethers.BigNumber;
  base_stackflation_total: ethers.BigNumber;
  base_stackflation_lastepoch: ethers.BigNumber;
  base_stackposalltimereward: ethers.BigNumber;
  
}

export interface NftPricelist {
  _nftprice: BigNumber;
  _nftpricetoken: BigNumber;
  _nftbaseprice: BigNumber;
  _nftbasepricetoken: BigNumber;
}

export interface AttackItm {
  ikey: number;
  attackadr: string;
  attackval: ethers.BigNumber;
  ismin: boolean;
  isplus: boolean;
  attackts: string;
  attacktsb: number;
}

export interface FilterItm {
  search: string;
  affiliationavailable: boolean;
  affiliated: boolean;
  claimsavail: boolean;
  showattacks: boolean;
  usegraphql: boolean;
  isgraph: boolean;
}

export interface BurnDetails {
  nftid: string;
  question: string;
  mintreward: BigNumber;
  
  
}

export interface ModalControlFields {
  show: boolean;
  nftid: string;
  action: string;
  errormsg: string;
  valimsg?: string;
  burndetail?: BurnDetails;
}


export interface StDt {
  ikey: number;
  owner: string;
  ownername: string;
  isowner: boolean;
  nftid: string;
  isedit: boolean;
  isbase: boolean;
  
  
  
  answered?: string;
  answerst?: AnStatus;

  answeractual ?: string;

  answerpts: number;
  answersaved: boolean;

  
  basemintcredit: number;
  basemintalltimecredit: number;

  mintcredit: number;
  mintalltimecredit: number;
  
  
  wronganswercnt: ethers.BigNumber;
  okanswercnt: ethers.BigNumber;

  r1answercnt: ethers.BigNumber;
  r2answercnt: ethers.BigNumber;
  r3answercnt: ethers.BigNumber;



  stackpos: number;
  stale: boolean;
  ready: boolean;
  stackposfactor: ethers.BigNumber;
  stackposreward: ethers.BigNumber;
  stackposalltimereward: ethers.BigNumber;
  
  
  
  totalstackflation: ethers.BigNumber;
  claimedstackflation: ethers.BigNumber;
  lastepochstackflation: ethers.BigNumber;
  approxavailstackflation: ethers.BigNumber;

  
  bonusreward: ethers.BigNumber;
  bonusalltimereward: ethers.BigNumber;

  stacklength: ethers.BigNumber;
  stackcanadd: boolean;

  laststackflationts: ethers.BigNumber;

  isfirstviz: boolean;
  isfirstunviz: boolean;
  splus: ethers.BigNumber;
  smin: ethers.BigNumber;
  idat: StDtItm;

  uri: string;

  mintepidx: ethers.BigNumber;
  burnflagepidx: ethers.BigNumber;

  desc?: string;
  baseid: string;

  uiupdts: number;

  saleprice: ethers.BigNumber;
  salets: ethers.BigNumber;
  soldts: ethers.BigNumber;
  soldcount: number;
  salepriceadj: ethers.BigNumber;
  salepriceset: boolean;
  salepriceerr: string;

  base_attackplus: ethers.BigNumber;
  base_attackmin: ethers.BigNumber;
  base_stackflation_total: ethers.BigNumber;
  base_stackflation_lastepoch: ethers.BigNumber;

  base_stackposalltimereward: ethers.BigNumber;


}


































export interface QmDt {
  isediting: boolean;
  nftid: string;
  editdata: StDtItm;
}


export interface PMeditpacket {
  question: string;
  nftid: string;
  secsleft: number;
  tokenavail: number;
  tokenspend: number;
  splusminreducefactor: number;
  tokenweight: number;
  cursplus: number;
  projsplus: number;
  decayratepersec: number;
  cursmin: number;
  projsmin: number;
  approvemax: boolean; 
}




export interface AccBal {
  tokenname?: string;
  tokenvals?: string;
  tokenval?: ethers.BigNumber;
  nft?: string;
  nftcount?: string;
  nftbncount?: ethers.BigNumberish;
  nftbnprice?: ethers.BigNumber;
  etherval?: ethers.BigNumber;
  currentadr ?: string;
  signeridx ?: number;
  ownername ?: string;
  ethername ?: string;
  cnftbasecnt: ethers.BigNumber;
  cnftregularcnt: ethers.BigNumber;
  cnftcnt: ethers.BigNumber;
}


export interface BusyByN {
  nftid: string;
  busy: boolean;
}

export interface BusyStat {
  claim?: boolean;

  
  claimmint: BusyByN [];


  claimsf: BusyByN [];
  claimcredits: BusyByN [];
  claimbonus: BusyByN [];

  points?: boolean;
  
  recalc?: boolean;
  loading?: boolean;
  plusminrefresh?: boolean;
  accsumrefresh?: boolean;
  saleset5?: boolean;
  salebuy?: boolean;
  cancelsell?: boolean;
  plusmin?: boolean;
  burning?: boolean;
  
  mintweth?: boolean;
  mintwethbase?: boolean;
  mintcoupon?: boolean;
  mintcouponbase?: boolean;

  busytranattack?: boolean;
  
  busytranapprove?: boolean;
  busytranmint?: boolean;

  busytranburn?: boolean;
  
  
}


export interface NftIds {
  key: number;
  nftid: number;
}

export enum AnStatus {
  AnswerNone,
  AnswerOK,
  AnswerWrong
}

export interface EpochDetail {
  pointsclaimed?: ethers.BigNumber;
  stackmintreward?: ethers.BigNumber;
  plusminspend?: ethers.BigNumber;
  stackflation?: ethers.BigNumber;
  xpointsclaimed?: ethers.BigNumber;
  xstackmintreward?: ethers.BigNumber;
  xplusminspend?: ethers.BigNumber;
  xstackflation?: ethers.BigNumber;
  epochidx?: ethers.BigNumber;
  
  
  adjsecs?: ethers.BigNumber;
  secsleft?: ethers.BigNumber;
  epochsecs?: ethers.BigNumber;
  lastepoch?: ethers.BigNumber;
  lastepochlocalcalc?: ethers.BigNumber;
  blockts?: ethers.BigNumber;
  firstepoch?: ethers.BigNumber;

  nftprice ?: ethers.BigNumber;
  nftpricecst ?: ethers.BigNumber;
  nftbaseprice ?: ethers.BigNumber;
  nftbasepricecst ?: ethers.BigNumber;

  recalcreward ?: ethers.BigNumber;

  plusmindecaypersec ?: ethers.BigNumber;
  splusminreducefactor ?: ethers.BigNumber;

  _maxstacklength?: ethers.BigNumber;
  _appversion ?: number;

}











export interface PointsDetail {
  claimable: number;
  allow: number;
  hundredgauge: number;
  allanswered: boolean;
  lowestspnotanswer: number;
  timetofull: number;
  pointsmaxpertime: number;
  fullrechargetokens: ethers.BigNumber;
  maxallowedrecharge: ethers.BigNumber;
  ownedquestions: number;
  answeredquestions: number;
  userhaveclaimables: boolean;
}


export interface SimConfig {
  availusers: string[];
  activeusertypes: number[];
}

export interface SimAction {
  aidx: number;
  name: string;
  
}

export interface ActionSumItm {
  aidx: number;
  atot: number;
  isok: number;
  iserr: number;
  okts: number;
  errts: number;
}

export interface SimSum {
  name: string;
  idx: number;
  usertype: number;
  tot: number;
  qnftcnt: number;
  skkbal: ethers.BigNumberish;
  ethbal: ethers.BigNumberish;
  actionsum: ActionSumItm[];
}

export interface TestAcc {
  a: string;
  n: string;
  sidx: number;
  simulate: boolean;
}

export interface NewUserType {
  tidx: number;
  actprobs: number[];
}

export interface BaseList {
  nftid: ethers.BigNumber;
  question: string;
  stacklength: ethers.BigNumber;
  okanswercnt: ethers.BigNumber;
  wronganswercnt: ethers.BigNumber;
  stale: boolean;
  ready: boolean;
  sim?: boolean;
}


export interface AdminData {
  targadr?: string;
  mintkilladr?: string;
  plusminkilladr?: string;
  stackflationkilladr?: string;
  parasetkilladr?: string;
  diamondmintadr?: string;
}


export interface ParaSet {
  _generaladmin?: string;
  M_EMISSION_RATE?: ethers.BigNumber;
  SF_EMISSION_RATE?: ethers.BigNumber;
  MAX_LEN?: number;
  _maxstacklength?: ethers.BigNumber;
  _maxetherpertimeunit?: ethers.BigNumber;
  _lengthoftimeunitinsecs?: ethers.BigNumber;
  _epochsecs?: ethers.BigNumber;
  _adjsecs?: ethers.BigNumber;
  minPlusMin?: ethers.BigNumber;
  decayratepersec?: ethers.BigNumber;
  splusminreducefactor?: ethers.BigNumber;
  _walletprotocol?: string;
  _rewardAdr?: string;
  _recalcreward?: ethers.BigNumber;

  _RoleToken_MINTER_0?: string;
  _RoleToken_MINTER_Cnt?: ethers.BigNumber;
  _RoleNft_MINTER_0?: string;
  _RoleNft_MINTER_Cnt?: ethers.BigNumber;

  _nftprice ?: ethers.BigNumber;
  _nftpricetoken ?: ethers.BigNumber;
  _nftbaseprice ?: ethers.BigNumber;
  _nftbasepricetoken ?: ethers.BigNumber;

  _allow_cnt_basemintcredit ?: number;
  _allow_cnt_mintcredit ?: number;

  _minepochsbeforeburn?: ethers.BigNumber;

  _appversion?: number;
}


export interface KillSet {
  mintkilladr?: string;
  plusminkilladr?: string;
  stackflationkilladr?: string;
  parasetkilladr?: string;

  salekilladr?: string;
  readkilladr?: string;
  coupkilladr?: string;

  diamondkilladr?: string;
}


export interface AdminAccBal {
  accadr : string;
  friendlyname ?: string;
  ethbal ?: ethers.BigNumber;
  tokenname ?: string;
  tokenbal ?: ethers.BigNumber;
  nftname ?: string;
  nftbal ?: ethers.BigNumber;
}

export interface accmodalstate {
  showme: boolean;
  buywhat: string;
  campid?: ethers.BigNumber;
  errormsg: string;
  traninitiated: boolean;
  tranapproved: boolean;
  tranminted: boolean;
}

export interface useracc {
  skkbal: ethers.BigNumber;
  cnftcnt: ethers.BigNumber;
  qnftcnt: ethers.BigNumber;
  ftmbal: ethers.BigNumber;
  nftprice: ethers.BigNumber;
}