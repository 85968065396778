
import React from "react";


import { EpochDetail, AccBal} from './CSInterface';

import { ethers } from 'ethers';

import { Button, Container, Row, Col, Nav, NavLink } from 'react-bootstrap';

import { MdLiveHelp } from "@react-icons/all-files/md/MdLiveHelp";

import stackattacklogo1 from '../assets/stackattacklogo1.png'
import { fToken, truncateEthAddress, xFactor } from "./CSCommon";

interface Props {
  epoche: EpochDetail;
  abal?: AccBal;
  errordisp?: string;
  dispdebugAacc: boolean;
  adminavail: boolean;
  admininputval?: string;
  adminupdatesh: any;
  sysinfoh: any;
  accounth: any;
  handlenav: any;
  lookat: any;
}



export const CrypSysInfo: React.FC<Props> = (props) => {

  
  function fSecsToText (secs?:ethers.BigNumber,d?:number,dt?:string)  {
    if(secs != undefined) {
      
        const isec = secs.toNumber()
        const hr = Math.floor(isec/(60*60))
        const min = Math.floor((isec-hr*60*60)/(60))
        const sec = isec - hr*60*60 - min*60

        if(d && dt) {
          return isec/d + " " + dt
        } else {
          return hr + " h " + min + " m " + sec + " s" 
        }

        
    } else {
      return "";
    }
};

  
  

  const calcTotReward = () => {

    
    
    
    
    

    
    
    
    
    
    if(props.epoche.pointsclaimed && props.epoche.stackmintreward && props.epoche.plusminspend && props.epoche.stackflation){
      return props.epoche.pointsclaimed.add(props.epoche.stackmintreward).add(props.epoche.plusminspend).add(props.epoche.stackflation)
    } else {
      return ethers.BigNumber.from(0)
    }

  }

  



  return (
    <Container fluid className="xflex-grow-1">
      
      {props.dispdebugAacc && props.adminavail && 
        <div className="csisec csiadmin">
          
          <div className="csibuts">
            
            <Button onClick={(e) => props.sysinfoh(e,"devinfo")}>devinfo</Button>
            <Button onClick={(e) => props.accounth(e,"refreshepoch")}>Refresh Epoch</Button>
            <Button onClick={(e) => props.accounth(e,"vmode")}>vmode</Button>
            
            
            <Button onClick={(e) => props.sysinfoh(e,"autoepoch")}>auto epoch</Button>
            <Button onClick={(e) => props.sysinfoh(e,"automine")}>auto mine</Button>
            <Button onClick={(e) => props.accounth(e,"login")}>Login</Button>
            <Button onClick={(e) => props.accounth(e,"logout")}>Logout</Button>
          </div>


          <div className="csihorz csiadr">
            <div>{props.abal ? props.abal.currentadr : ""}</div>
            <div>{props.abal ? props.abal.ownername : ""}</div>
          </div>

          <div className="csierr">
            <p>{props.errordisp}</p>
          </div>

          <div className="csibuts d-flex">
            <Col className="col-5 xme-2">
              <Row className="xtext-end">
                <Col >---</Col>
                <Col >curr</Col>
                <Col >prevx</Col>
              </Row>
              <Row className="xtext-end">
                <Col >pointsclaim</Col>
                <Col >{fToken(props.epoche.pointsclaimed)}</Col>
                <Col >{fToken(props.epoche.xpointsclaimed)}</Col>
              </Row>
              <Row className="xtext-end">
                <Col >mintreward</Col>
                <Col >{fToken(props.epoche.stackmintreward)}</Col>
                <Col >{fToken(props.epoche.xstackmintreward)}</Col>
              </Row>
              <Row className="xtext-end">
                <Col >plusmin</Col>
                <Col >{fToken(props.epoche.plusminspend)}</Col>
                <Col >{fToken(props.epoche.xplusminspend)}</Col>
              </Row>
              <Row className="xtext-end">
                <Col >stackflation</Col>
                <Col >{fToken(props.epoche.stackflation)}</Col>
                <Col >{fToken(props.epoche.xstackflation)}</Col>
              </Row>
            </Col>

            <Col className="col-5 xme-2">
              <Row className="xx">
                <Col xs="7" className="x">last epochid: {xFactor(props.epoche.epochidx)}</Col>
                <Col>{fSecsToText(props.epoche.epochsecs,60*60,"h")}</Col>
              </Row>
            </Col>
          </div>


        </div>
      }

      
      {}

      <Row className="xad-csiappinfo">
        <Col xs={3} className="ad-homeimg d-flex align-items-center">
          <a href={process.env.REACT_APP_APPLINK}><img src={stackattacklogo1} /></a>
        </Col>
        
        <Col>
          <Row className="xad-csiappinfo ad-csiapptop d-flex justify-content-end py-1">
          
            {}

            {props.adminavail && props.dispdebugAacc &&
              <Col xs="auto"><Button variant="info" onClick={(e)=>props.sysinfoh(e,"hide")} >hebug</Button></Col>
            }
            {props.adminavail && !props.dispdebugAacc &&
              <Col xs="auto"><Button variant="info" size="sm" onClick={(e)=>props.sysinfoh(e,"hide")} >debug</Button></Col>
            }
            {}
            

            {}

            {props.abal && props.abal.currentadr != undefined &&
              <>
                <Col xs="auto">{props.abal.ownername != "na" ? props.abal.ownername : ""}</Col>
                <Col xs="auto">{truncateEthAddress(props.abal.currentadr)}</Col>
              </>
            }
            {(!props.abal || (props.abal && props.abal.currentadr == undefined )) &&
              <Col xs="auto">
                <Button variant="info" size="sm" onClick={(e) => props.accounth(e,"login")}>Connect</Button>
              </Col>
            }

            {}

            {}

            {}
            
          </Row>

          <div className="text-center justify-content-center mt-1 mb-2 g-0 d-flex">

            <div className="">
              <div className="ad-csiepoch-round xjustify-content-between d-flex px-2 me-2 py-1">
                

                { props.abal && props.epoche.secsleft &&
                    <div className="ps-2 pe-4">{fSecsToText(props.epoche.secsleft)}</div>
                }
                { props.abal && !props.epoche.secsleft &&
                  <div className="px-2">... standby ...</div>
                }
                { !props.abal && 
                  <div className="px-2">... --- ...</div>
                }
                {}
                
                
                <div className="vr ms-2"/>
                <div className="ps-1 align-items-center d-flex">ends</div>
              </div>
              
            </div>

            <div className="">
              <div className="ad-csiepoch-round d-flex px-2 me-2 py-1">
                
                  { props.abal && props.epoche.secsleft &&
                    <div className="ps-2 pe-4">{fToken(calcTotReward(),1)} {props.abal ? props.abal.tokenname : ""}</div>
                  }
                  { props.abal && !props.epoche.secsleft &&
                    <div className="px-2">... calculating ...</div>
                  }
                  { !props.abal && 
                    <div className="px-2">... --- ...</div>
                  }

                  {}
                
                <div className="vr ms-2"/>
                <div className="ps-1 align-items-center d-flex">next</div>
              </div>
            </div>

            <Col className="ms-auto"></Col>

            {props.lookat.showhelp == false && props.lookat.curadr != "" &&
              <>
                {}
                <Col xs="auto">
                  <Button variant="secondary" size="sm" onClick={(e)=>props.handlenav(e,"unhidehelp")}><MdLiveHelp className="me-1"/>help</Button>
                </Col>
              </>
            }

          </div>

          {}

        </Col>

      </Row>


    </Container>
  );
};
