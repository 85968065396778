
import { ethers } from "ethers";
import React from "react";

import { AccBal } from './CSInterface'
import { fFactor } from "./CSCommon";

interface Props {
  abal: AccBal [];
  
  
  buth: any;

}

































export const BalanceBox: React.FC<Props> = (props) => {

  

  
  function calcMakeAccount (acb: AccBal[]) {

    return <>
              { acb.map((v,i)=>{

                return <div className="bbmain" key={i}>

                  <div className="csnnoop">
                    <div>{v.ownername} {v.signeridx}</div>
                    <div>{v.currentadr != undefined ? v.currentadr.substring(0,10) + "..." : ""}</div>
                  </div>
                  <div className="cshorz skktokens">
                    <div className="csleft">
                      <div>{v.tokenvals}</div>
                      <div>{v.nftcount}</div>
                      <div>{fFactor(v.etherval,8)}</div>
                      <div>{v.cnftbasecnt.toNumber()}</div>
                      <div>{v.cnftregularcnt.toNumber()}</div>
                      <div>{v.cnftcnt.toNumber()}</div>
                    </div>
                    <div className="csright">
                      <div>{v.tokenname}</div>
                      <div>{v.nft}</div>
                      <div>{v.ethername}</div>
                      <div>base</div>
                      <div>reg</div>
                      <div>tot</div>
                    </div>
                  </div>
                  <button onClick={(e) => props.buth(e,v.currentadr,v.signeridx)}>Log In</button>
                  
                </div>

              })}
          </>

  }


  return (
    <div className="bbar">
      {}
      {calcMakeAccount(props.abal)}
    </div>
  );
};
