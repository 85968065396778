
import React from "react";

import { BusyStat, StDtItm, PMeditpacket} from './CSInterface';


import {Button, Container, Row, Col, ProgressBar, Modal, Form, Spinner, Alert} from 'react-bootstrap';

import { MdCheck } from "@react-icons/all-files/md/MdCheck";

interface Props {
  editpacket: PMeditpacket;

  action: string;
  errormsg?: string;
  nftid?: string;
  qdata?: StDtItm;
  updateh?: any;
  
  splush: any;
  hideh: any;
  bstat: BusyStat;
}



export const PmModal: React.FC<Props> = (props) => {

  const setRVal = (rnum: string) => {
    if(props.qdata && props.qdata.a == rnum) {
      return 1;
    } else {
      return 0;
    }
  }

  
  const saveBut = (e: any, btext: string) => {

    if(btext == "Attack") {

    
      if(props.editpacket.tokenspend > 0) {

        props.splush(e,props.nftid,"plus",Math.abs(props.editpacket.tokenspend));

      } else if(props.editpacket.tokenspend < 0) {

        props.splush(e,props.nftid,"min",Math.abs(props.editpacket.tokenspend));

      } 
    
    } else if (btext == "Refresh") {

      props.splush(e,props.nftid,"refresh",0);
    }

  }
  

  
  function createSaveBut(btext: string) {

    
    if( (props.bstat.plusmin && btext == "Attack") || (props.bstat.plusminrefresh && btext == "Refresh")) {

      return <Button
              variant="primary"
              
              onClick={(e)=> saveBut(e,btext)}
              disabled
             >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Loading...</span>
                {btext}
            </Button>

    
    } else {

      return <Button
                variant="primary"
                
                onClick={(e)=> saveBut(e,btext) }
              >
              {btext}
            </Button>
    }

  }


  
    
  
  
  
  
  
  

  
  
  
  
  
  
  


  return (
    <>
      <Modal show={props.action != "error"} onHide={props.hideh}>
        <Modal.Header closeButton>
          <Modal.Title>Attack the Stack</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <Container>
          
            {}
            <Row>
              <Col><p>Spend SKK tokens to adjust the stack position of a question. Plus attacks will increase the stack factor
                by lowering the stack position. A question with a lower stack position (higher stack factor) will 
                earn a higher share of mint and stackflation awardds allocated to the stack. Stack positions are adjusted once per epoch.</p></Col>
            </Row>


            <hr />
            
            <Row>
              <Col>question</Col>
              <Col>{props.editpacket.question}</Col>
            </Row>
            
            

          
            <Row>
              <Col>qNFT id</Col>
              <Col>{props.editpacket.nftid}</Col>
            </Row>
            
            <Row>
              <Col>SKK balance</Col>
              <Col>{props.editpacket.tokenavail}</Col>
            </Row>

            <Row>
              <Col>SKK spend</Col>
              <Col>{Math.abs(props.editpacket.tokenspend)}</Col>
            </Row>

            {}

            {}
                
            <hr className="mb-1"/>

            <Row>
              <Col className="mb-2">current values</Col>
            </Row>
            <Row>
              <Col>plus - min</Col>
              <Col>{props.editpacket.cursplus} - {props.editpacket.cursmin}</Col>
            </Row>
            <Row>
              <Col> = nett weight</Col>
              <Col>{props.editpacket.cursplus - props.editpacket.cursmin}</Col>
            </Row>

            <hr />
            
            <Row>
              <Col>weight adjustment</Col>
              <Col>{props.editpacket.tokenweight}</Col>
            </Row>

            <hr />

            <Row>
              <Col>decay rate per second</Col>
              <Col>{props.editpacket.decayratepersec}</Col>
            </Row>

            <Row>
              <Col>epoch seconds remain</Col>
              <Col>{props.editpacket.secsleft}</Col>
            </Row>

            <hr className="mb-1"/>
            
            <Row>
              <Col className="mb-2">projected at end of epoch</Col>
            </Row>
            <Row>
              <Col>plus - min</Col>
              <Col>{props.editpacket.projsplus} - {props.editpacket.projsmin}</Col>
            </Row>

            <Row>
              <Col> = nett weight</Col>
              <Col>{props.editpacket.projsplus - props.editpacket.projsmin}</Col>
            </Row>

            <hr />

            <Form>
              <Form.Check inline name="approveall" label="approve 500 tokens" type="switch" id="approveall-1" 
                      value={props.editpacket.approvemax ? 1 : 0} 
                      checked={props.editpacket.approvemax} 
                      onChange={(e) => props.updateh(e,"approvecheck")} />
            </Form>
            
              

              { props.bstat.plusmin && !props.bstat.busytranattack && props.bstat.busytranapprove &&
                <>
                  <Spinner animation="border" size="sm"></Spinner><span className="ms-1">approving</span>
                </>
              }
              
              { props.bstat.plusmin && props.bstat.busytranattack && !props.bstat.busytranapprove &&
                <>
                  <div><MdCheck />approved</div>
                  <Spinner animation="border" size="sm"></Spinner><span className="ms-1">attacking</span>
                </>
              }

              { props.bstat.plusmin && !props.bstat.busytranattack && !props.bstat.busytranapprove &&
                <>
                  <div><MdCheck />approved</div>
                  <div><MdCheck />attacked</div>
                </>
              }

              { props.errormsg && props.errormsg != "" &&
              
                <Alert variant="danger">{props.errormsg}</Alert>
              }
            
            {}
            {}
            
            
            
          </Container>
        </Modal.Body>
        <Modal.Footer>

          <Container>
            <div className="d-flex justify-content-between">

              <div className="x">
                <Button variant="secondary" onClick={(e)=>props.splush(e,props.nftid,"cancel")}>Close</Button>
              </div>
          
              <div className="d-flex">
                <div className="x">
                  <Button variant="secondary" onClick={(e)=>props.updateh(e,"minplusclick",-1)}>Min</Button>
                </div>
            
                <div className="mx-1">
                  {createSaveBut("Refresh")}
                </div>

                <div className="x">
                  <Button variant="secondary" onClick={(e)=>props.updateh(e,"minplusclick",1)}>Plus</Button>
                </div>
              </div>

              <div className="x">
                {createSaveBut("Attack")}
              </div>
            
            </div>
          </Container>

        </Modal.Footer>
      </Modal>
    </>
  );
};
